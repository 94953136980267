import { setEndDate, setStartDate } from "appstate/features/order/orderSlice";
import { format } from "date-fns";
import Modal from "layout/modal/Modal";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";

const DateModal = ({ execute }) => {
  const { startDate, endDate } = useSelector((state) => state.order);

  const [isDateShown, setIsDateShown] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const dispatch = useDispatch();

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "yyyy-MM-dd");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
    dispatch(
      setStartDate(formatDateDisplay(ranges.selection.startDate, "Start Date"))
    );
    dispatch(
      setEndDate(formatDateDisplay(ranges.selection.endDate, "End Date"))
    );
  };
  return (
    <div>
      <div
        onClick={() => setIsDateShown(!isDateShown)}
        className="cursor-pointer flex justify-center items-center px-4 py-2 text-paleSky text-[14px] font-medium border-2 rounded-xl border-gallery h-full"
      >
        <div className="flex flex-wrap gap-1">
          <p className="max-sm:text-[12px]">
            {startDate
              ? startDate?.split("-").reverse().join("-")
              : "Start Date"}
          </p>
          -
          <p className="max-sm:text-[12px]">
            {endDate ? endDate?.split("-").reverse().join("-") : "End Date"}
          </p>
        </div>
      </div>
      <Modal
        isShown={isDateShown}
        hide={() => setIsDateShown(false)}
        title="Select Date"
        divClass="lg:!w-fit"
      >
        <DateRangePicker
          onChange={(item) => {
            handleSelect(item);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          ranges={[selectedDateRange]}
          direction="vertical"
          scroll={{ enabled: true }}
          className="w-full"
        />
        <div className="flex justify-end gap-4 pb-4 mr-7">
          <button
            className="text-white text-[12px] bg-congressBlue px-4 py-2 rounded-xl"
            onClick={() => {
              execute();
              setIsDateShown(false);
            }}
          >
            Done
          </button>
          <button
            className="text-white text-[12px] bg-red-500 px-4 py-2 rounded-xl"
            onClick={() => setIsDateShown(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DateModal;
