import {
  addMultipleOrdersToInvoice,
  getInvoices,
  getOneInvoice,
} from "appstate/features/invoice/invoiceActions";
import { getFilterableOrders } from "appstate/features/order/orderActions";
import { format } from "date-fns";
import Modal from "layout/modal/Modal";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import { MultiSelect } from "react-multi-select-component";

const AddNewOrder = ({ hide, invoice }) => {
  const [amount, setAmount] = useState(0);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { isLoading } = useSelector((state) => state.invoice);

  const selected = selectedOrders?.map((item) => item.value);

  const [isDateShown, setIsDateShown] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "yyyy-MM-dd");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
    setStartDate(formatDateDisplay(ranges.selection.startDate, "Start Date"));
    setEndDate(formatDateDisplay(ranges.selection.endDate, "End Date"));
  };

  const dispatch = useDispatch();

  const ordersList = orders?.map((order) => ({
    label: `${order?.orderId}: by ${order?.recipient?.name} on ${format(
      new Date(order?.status?.date?.split("T")[0]?.split("-")?.join("/")),
      "EEEE, do MMMM yyyy"
    )}`,
    value: order?.orderId,
  }));

  useEffect(() => {
    dispatch(
      getFilterableOrders({
        page: 1,
        delivered: true,
        merchantId: invoice?.merchant?.merchantId,
        merchantOutletId: invoice?.merchantOutlet?.outletId,
      })
    ).then((data) => {
      setOrders(data?.payload?.data);
    });
  }, [
    dispatch,
    invoice?.merchant?.merchantId,
    invoice?.merchantOutlet?.outletId,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selected.length) {
      dispatch(
        addMultipleOrdersToInvoice({
          id: invoice?.invoiceId,
          orderIds: { orderIds: selected },
        })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          dispatch(getOneInvoice({ id: invoice?.invoiceId }));
          dispatch(getInvoices());
          toast.success("Order successfully added!");
          hide();
        }
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="relative max-sm:w-full">
          <div className="flex justify-end gap-3">
            <div
              onClick={() => {
                setIsDateShown(!isDateShown);
              }}
              className="max-sm:w-full cursor-pointer flex justify-start items-center px-4 py-2 text-paleSky text-[14px] font-medium border-2 rounded-lg border-gallery h-[35px]"
            >
              <div className="flex flex-wrap gap-1">
                <p className="max-sm:text-[12px]">
                  {startDate
                    ? startDate?.split("-").reverse().join("-")
                    : "Start Date"}
                </p>
                -
                <p className="max-sm:text-[12px]">
                  {endDate
                    ? endDate?.split("-").reverse().join("-")
                    : "End Date"}
                </p>
              </div>
            </div>
          </div>
          {isDateShown && (
            <Modal
              isShown={isDateShown}
              hide={() => setIsDateShown(false)}
              title="Select Date"
              className="flex flex-col items-center"
              divClass="md:!w-fit"
            >
              <DateRangePicker
                onChange={(item) => {
                  handleSelect(item);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={[selectedDateRange]}
                scroll={{ enabled: true }}
                direction="vertical"
              />
              <div className="w-full flex justify-end gap-4 py-4">
                <button
                  className="text-white text-[12px] bg-congressBlue px-4 py-2 rounded-xl"
                  onClick={() => {
                    dispatch(
                      getFilterableOrders({
                        page: 1,
                        delivered: true,
                        merchantId: invoice?.merchant?.merchantId,
                        merchantOutletId: invoice?.merchantOutlet?.outletId,
                        startDate,
                        endDate,
                      })
                    ).then((data) => {
                      setOrders(data?.payload?.data);
                    });
                    setIsDateShown(false);
                  }}
                >
                  Apply
                </button>
                <button
                  className="text-white text-[12px] bg-red-500 px-4 py-2 rounded-xl"
                  onClick={() => {
                    setIsDateShown(false);
                    dispatch(
                      getFilterableOrders({
                        page: 1,
                        delivered: true,
                        merchantId: invoice?.merchant?.merchantId,
                        merchantOutletId: invoice?.merchantOutlet?.outletId,
                      })
                    ).then((data) => {
                      setOrders(data?.payload?.data);
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </Modal>
          )}
        </div>
        <div className="pb-6">
          <label className="font-normal text-xs text-midGray mb-[5px]">
            Order ID
          </label>
          <MultiSelect
            name="orderIds"
            onChange={setSelectedOrders}
            options={ordersList}
            placeholder="Select orders"
            value={selectedOrders}
          />
        </div>
        <div className="min-h-[120px] py-2">
          <InputField
            label="Additional Fee"
            placeholder="Amount"
            name="amount"
            className="!h-[50px]"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px] !px-8" blue>
            {isLoading ? <ButtonAnimation /> : "Add Order"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewOrder;
