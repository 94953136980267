import { LHIconBackArror } from "assets/svgs";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(-1)}
      className="cursor-pointer flex items-center gap-2"
    >
      <LHIconBackArror />
      <p className="text-[18px] font-medium text-[#334155]">Back</p>
    </div>
  );
};

export default GoBack;
