import Button from "shared/Button";
import Card from "shared/Card";
import PageTitle from "shared/PageTitle";
import { useEffect, useState } from "react";
import All from "./invoiceTypes/All";

const InvoiceHeader = ({ title, setIsShown, invoices }) => {
  const getRoute = localStorage.getItem("route");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getRoute === "0"
      ? setActiveTab(0)
      : getRoute === "1"
      ? setActiveTab(1)
      : getRoute === "2"
      ? setActiveTab(2)
      : setActiveTab(0);
  }, [getRoute]);

  const invoiceTabs = [
    {
      title: `All (${invoices ? invoices?.length : 0})`,
      component: <All invoices={invoices} />,
    },
    {
      title: `Paid (${
        invoices?.filter((invoice) => invoice?.status?.status === "Fully Paid")
          ?.length
      })`,
      component: (
        <All
          invoices={invoices?.filter(
            (invoice) => invoice?.status?.status === "Fully Paid"
          )}
        />
      ),
    },
    {
      title: `Outstanding (${
        invoices?.filter((invoice) => invoice?.status?.status !== "Fully Paid")
          ?.length
      })`,
      component: (
        <All
          invoices={invoices?.filter(
            (invoice) => invoice?.status?.status !== "Fully Paid"
          )}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      <Card>
        <div className="flex flex-wrap gap-3 mb-4 justify-between">
          <PageTitle title={title} />
          <div className="flex justify-between">
            <div>
              <Button
                onClick={() => setIsShown(true)}
                className="!bg-pickledBlueWood !h-[45px] !text-white"
              >
                New Invoice
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-3">
          {invoiceTabs.map((invoice, idx) => (
            <p
              key={idx}
              className={`${
                activeTab === idx &&
                "border-b-2 border-b-congressBlue text-congressBlue rounded-none"
              } py-2 px-3 rounded-lg cursor-pointer max-[450px]:w-full`}
              onClick={() => setActiveTab(idx)}
            >
              {" "}
              {invoice.title}
            </p>
          ))}
        </div>
      </Card>
      <Card>{invoiceTabs[activeTab].component}</Card>
    </div>
  );
};

export default InvoiceHeader;
