import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Details from "./TeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "appstate/features/teams/teamActions";
import AddNewTeamModal from "./modal/addNewTeam";
import Header from "shared/Header";
import { getClients } from "appstate/features/client/clientActions";
import { getAdmins } from "appstate/features/admin/adminActions";

const TeamsContents = () => {
  const { teams, currentPage, isLoading } = useSelector((state) => state.team);

  const [isShown, setIsShown] = useState(false);
  const [admins, setAdmins] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdmins()).then((data) => {
      if (data?.payload?.status === 200) {
        setAdmins(data?.payload?.data);
      }
    });
    dispatch(getTeams({ page: 0 }));
    dispatch(getClients());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewTeamModal
        admins={admins}
        isShown={isShown}
        hide={() => setIsShown(false)}
      />
      <Header
        info1="All"
        info2="Active"
        info3="Inactive"
        info4="Available"
        title="Team"
        btnText="New Team"
        amount={`${teams?.length}`}
        activeAmount={`${teams?.length}`}
        inactiveAmount={"0"}
        deliveredAmount={"0"}
        onClick={() => setIsShown(true)}
      />
      <Details teams={teams} currentPage={currentPage} isLoading={isLoading} />
    </DashboardLayout>
  );
};

export default TeamsContents;
