import { LHIconDownload, LHIconMenuDots } from "assets/svgs";
import React, { useState } from "react";
import TableControl from "shared/TableControl";
import AddNewOrderModal from "../modal/addNewOrder";
import Loader from "shared/Loader";
import InputSelect from "shared/InputSelect";
import Table from "shared/Table";
import Card from "shared/Card";
import ChangeInvoiceStatusModal from "../modal/changeInvoiceStatus";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneInvoice,
  removeOrderFromInvoice,
} from "appstate/features/invoice/invoiceActions";

const InvoiceOverview = ({ singleInvoice, generatePDF }) => {
  const [showControls, setShowControls] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [isStatusModalShown, setStatusModalShown] = useState(false);

  const { isLoading } = useSelector((state) => state.invoice);

  const dispatch = useDispatch();

  const controls = [
    {
      text: "Remove order",
      action: () => {
        if (selectedRow) {
          dispatch(
            removeOrderFromInvoice({
              id: singleInvoice?.invoiceId,
              orderId: selectedRow?.orderId,
            })
          ).then(() => {
            setSelectedRow(null);
            dispatch(getOneInvoice({ id: singleInvoice?.invoiceId }));
            toast.success("Order deleted successfully!");
          });
        }
      },
    },
  ];

  const invoiceOrdersColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Order ID",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4">{d?.orderId}</p>
      ),
    },
    {
      Header: "Delivery Address",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4">{d.deliverAddress}</p>
      ),
    },
    {
      Header: "Pickup store",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4">{d.pickUpAddress}</p>
      ),
    },
    {
      Header: "Amount",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4">N{d.orderAmount}</p>
      ),
    },

    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <AddNewOrderModal
        isShown={isShown}
        hide={() => {
          setIsShown(false);
          setShowControls(false);
        }}
        invoice={singleInvoice}
      />
      <ChangeInvoiceStatusModal
        isShown={isStatusModalShown}
        hide={() => {
          setStatusModalShown(false);
          setShowControls(false);
        }}
        invoice={singleInvoice}
      />
      {false ? (
        <div className="h-[300px] w-full">
          <Loader />
        </div>
      ) : (
        <Card>
          <div className="flex flex-col">
            <div className="flex items-center flex-wrap gap-3 justify-between mb-6">
              <div className="relative">
                <p className="text-[20px] max-md:text-[14px] font-semibold">
                  Orders
                </p>
              </div>
              <div className="flex items-center flex-wrap gap-4">
                <div
                  onClick={generatePDF}
                  className="gap-2 h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center"
                >
                  <p className="text-xs">Download Invoice</p>
                  <LHIconDownload className="w-4 h-4" />
                </div>
                <div className="">
                  <InputSelect
                    admin
                    datas={[
                      {
                        name: "Add Order",
                        value: "Add Order",
                      },
                      {
                        name: "Remove Order",
                        value: "Remove Order",
                      },
                      {
                        name: "Change Status",
                        value: "Change Status",
                      },
                    ]}
                    placeholder="Actions"
                    name="actions"
                    divClass="border-2 border-gullGray rounded-lg"
                    className=" !bg-white"
                    onChange={(e) => {
                      if (e.target.value === "Add Order") {
                        setIsShown(true);
                      } else if (e.target.value === "Remove Order") {
                        if (selectedRows.length) {
                          dispatch(
                            removeOrderFromInvoice({
                              id: singleInvoice?.invoiceId,
                              orderId: selectedRows[0]?.orderId,
                            })
                          ).then(() => {
                            setSelectedRows([]);
                            dispatch(
                              getOneInvoice({ id: singleInvoice?.invoiceId })
                            );
                            toast.success("Order deleted successfully!");
                          });
                        } else {
                          toast.error("Please select orders to remove!");
                        }
                      } else if (e.target.value === "Change Status") {
                        setStatusModalShown(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <Table
                  columns={invoiceOrdersColumns}
                  data={singleInvoice?.orders}
                  type="You don’t have any invoice order. Add a new order to get started"
                />
              )}
            </div>
            <div className="flex justify-end">
              <div
                onClick={() => setIsShown(true)}
                className="flex gap-2 items-center cursor-pointer"
              >
                <p className="text-congressBlue text-[16px] max-md:text-[12px]">
                  Add new order
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4599_138159)">
                    <path
                      d="M9.60573 10.3946C9.60584 10.3947 9.6059 10.3949 9.6059 10.3951L9.6059 23.395C9.60594 23.6601 9.7113 23.9144 9.89879 24.1019C10.0863 24.2894 10.3406 24.3948 10.6058 24.3948L23.6066 24.3948C23.8718 24.3948 24.1261 24.2894 24.3136 24.1019C24.5011 23.9144 24.6064 23.6601 24.6065 23.395V10.3948C24.6064 10.1296 24.5011 9.87535 24.3136 9.68785C24.1261 9.50035 23.8718 9.395 23.6066 9.39496L10.6054 9.3946C10.3402 9.39464 10.0859 9.5 9.89844 9.6875C9.711 9.87494 9.60565 10.1291 9.60555 10.3942C9.60555 10.3944 9.60561 10.3945 9.60573 10.3946V10.3946Z"
                      stroke="#04457B"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M14.0312 16.9707H20.0312M17.0312 13.9707V19.9707"
                      stroke="#04457B"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4599_138159">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.0585938 16.9707) rotate(-45)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <p className="mb-4 text-[20px] max-md:text-[14px] font-semibold">
              Payment
            </p>
            <div className="flex justify-between flex-wrap gap-6 pb-4 border-b">
              <div className="flex flex-col gap-2">
                <p className="text-slateGray text-[18px] max-md:text-[15px]">
                  Item's Total ({singleInvoice?.totalOrder})
                </p>
                <p className="text-slateGray text-[18px] max-md:text-[15px]">
                  Additional fee
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-slateGray text-[18px] max-md:text-[15px]">
                  NGN {singleInvoice?.totalAmount}
                </p>
                <p className="text-slateGray text-[18px] max-md:text-[15px]">
                  NGN {singleInvoice?.additionalFee}
                </p>
              </div>
            </div>
            <div className="flex justify-end pt-4">
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-2">
                  <p className="text-pickledBlueWood text-[18px] max-md:text-[14px] font-semibold">
                    Sub total
                  </p>
                  <p className="text-pickledBlueWood text-[18px] max-md:text-[14px] font-semibold">
                    Tax (10%)
                  </p>
                  <p className="text-pickledBlueWood text-[18px] max-md:text-[14px] font-semibold">
                    Total
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-slateGray text-[18px] max-md:text-[14px]">
                    NGN{" "}
                    {singleInvoice?.totalAmount + singleInvoice?.additionalFee}
                  </p>
                  <p className="text-slateGray text-[18px] max-md:text-[14px]">
                    NGN{" "}
                    {0.1 *
                      (singleInvoice?.totalAmount +
                        singleInvoice?.additionalFee)}
                  </p>
                  <p className="text-pickledBlueWood text-[18px] max-md:text-[14px] font-semibold">
                    NGN{" "}
                    {singleInvoice?.totalAmount +
                      singleInvoice?.additionalFee +
                      0.1 *
                        (singleInvoice?.totalAmount +
                          singleInvoice?.additionalFee)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default InvoiceOverview;
