import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Tracking from "./Tracking";
import SingleOrderInfo from "./SingleOrderInfo";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOneOrder } from "appstate/features/order/orderActions";
import { toast } from "react-toastify";

const SingleOrderContent = () => {
  const { id } = useParams();
  const [currentOrder, setCurrentOrder] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOneOrder({ id })).then((data) => {
      if (data?.payload?.status !== 200) {
        toast.error(`Unable to get Order Details with id ${id}`);
        navigate("/admin/orders");
      } else {
        setCurrentOrder(data?.payload?.data?.data);
      }
    });
  }, [dispatch, id, navigate]);

  return (
    <DashboardLayout>
      <div className="flex max-[1200px]:flex-wrap gap-3 mb-6">
        <div className="w-full min-[1200px]:w-[70%]">
          <SingleOrderInfo
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
          />
        </div>
        <div className="w-full min-[1200px]:w-[30%]">
          <Tracking
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SingleOrderContent;
