import {
  createDeliveryOrder,
  getFilterableOrders,
} from "appstate/features/order/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Modal from "layout/modal/Modal";

const ConfirmOrder = ({ body, hide, setCurrentOrders, isShown }) => {
  const { isLoading } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const center = {
    lat: body?.destinationInformation?.locLatitude,
    lng: body?.destinationInformation?.locLongitude,
  };

  return (
    <Modal
      disableModalTitle={true}
      divClass="!py-0 !pb-0 lg:!mt-[10%]"
      isShown={isShown}
      hide={() => hide()}
    >
      <div>
        <div className="w-full h-[200px]">
          {!body?.destinationInformation?.locLatitude ||
          !body?.destinationInformation?.locLongitude ? (
            <div className="h-full flex justify-center items-center">
              <p>Invalid Longtitude/Latitude</p>
            </div>
          ) : (
            isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={center}
                zoom={16}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                <Marker position={center} />
              </GoogleMap>
            )
          )}
        </div>
        <div className="p-6">
          <div className="mb-6">
            <div className="flex justify-between">
              <p className="text-[20px] text-fiord font-semibold">
                {body?.merchant?.merchantName}
              </p>
              <p className="text-[20px] text-fiord font-semibold">
                NGN {body?.customerToPay?.orderCost}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[15px] text-fiord">
                Delivery Date: {body?.deliveryDate}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[15px] text-fiord">
                Delivery Time: {body?.deliveryTime}
              </p>
            </div>
          </div>
          <div>
            <p className="text-gullGray text-[12px] flex justify-start">
              {body?.twoWays ? "Pickup & delivery" : "Delivery only"}
            </p>
            <div className="w-full flex gap-4">
              <Button
                onClick={() =>
                  dispatch(createDeliveryOrder(body)).then((data) => {
                    if (data?.payload?.status === 201) {
                      toast.success("Order created successfully!");
                      hide();
                      dispatch(
                        getFilterableOrders({
                          page: 1,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      });
                    }
                  })
                }
                type="submit"
                className="!h-[45px] w-full !px-8"
                blue
              >
                {isLoading ? <ButtonAnimation /> : "Confirm Order"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmOrder;
