import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";
import Multiselect from "multiselect-react-dropdown";
import { getUserById } from "appstate/features/auth/authActions";
import { updateRiderTeam } from "appstate/features/fleet/fleetActions";

const UpdateRiderTeamContents = ({ hide, currentRider, setCurrentRider }) => {
  const { isLoading } = useSelector((state) => state.fleet);

  // console.log(currentRider);

  const dispatch = useDispatch();

  const { teams } = useSelector((state) => state.team);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const teamsList = teams.map((team) => ({
    id: team?._id,
    name: team?.name,
    value: {
      name: team?.name,
      id: team?._id,
    },
  }));
  const onTeamsSelect = (selectedList, selectedItem) => {
    setSelectedTeam(selectedItem.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTeam) {
      dispatch(
        updateRiderTeam({ id: currentRider?._id, body: selectedTeam })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success(data?.payload?.data?.message);
          dispatch(getUserById({ id: currentRider?._id })).then((data) => {
            if (data?.payload?.status === 200) {
              setCurrentRider(data?.payload?.data?.data);
            }
          });
          hide();
        }
      });
    } else {
      toast.error("Please select a team!");
      return;
    }
  };

  return (
    <div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="">
          <div>
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onTeamsSelect}
              options={teamsList}
              value={selectedTeam}
              placeholder="Select team"
              className="h-[50px] rounded-lg pl-2 bg-catSkillWhite text-[12px]"
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Edit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UpdateRiderTeamContents;
