import React, { useEffect } from "react";
import Details from "./Details";
import { useDispatch } from "react-redux";
import { getClients } from "appstate/features/client/clientActions";
import { getFleetType } from "appstate/features/fleet/fleetActions";

const ApprovedRatesContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
    dispatch(getFleetType());
  }, [dispatch]);

  return <Details />;
};

export default ApprovedRatesContent;
