import { LHIconTrash } from "assets/svgs";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import Table from "shared/Table";
import AddNewUserModal from "./modal/addnewuser";
import { useDispatch } from "react-redux";
import { getMerchantUsers } from "appstate/features/client/clientActions";
import Button from "shared/Button";

const AssignedUsers = ({ currentClient }) => {
  const [isShown, setIsShown] = useState(false);
  const [merchantUsers, setMerchantUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    currentClient &&
      dispatch(getMerchantUsers({ id: currentClient?._id })).then((data) => {
        if (data?.payload?.status === 200) {
          setMerchantUsers(data?.payload?.data?.data);
        }
      });
  }, [dispatch, currentClient, currentClient?._id]);

  const usersColumns = [
    {
      Header: "Name",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.user?.firstName + " " + d.user?.lastName}
        </p>
      ),
    },
    {
      Header: "Email",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.user?.email}</p>
      ),
    },
    {
      Header: "Role",
      accessor: (d) => <p className="flex justify-start ml-4">{d.role}</p>,
    },
    {
      Header: " ",
      accessor: (d) => <LHIconTrash className="cursor-pointer w-4 h-4" />,
    },
  ];

  return (
    <Card className="!h-fit">
      <AddNewUserModal
        currentClient={currentClient}
        setMerchantUsers={setMerchantUsers}
        isShown={isShown}
        hide={() => setIsShown(false)}
      />
      <div className="flex flex-wrap gap-2 justify-between items-center">
        <p className="text-[18px] font-medium">Created User(s)</p>
        <Button
          onClick={() => setIsShown(true)}
          className="!h-[40px]"
          type="button"
          blue
        >
          Create User
        </Button>
      </div>
      <div>
        <Table
          columns={usersColumns}
          data={merchantUsers}
          type="You don’t have any assigned user. Create a new user to get started"
        />
      </div>
    </Card>
  );
};

export default AssignedUsers;
