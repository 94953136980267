import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import * as Yup from "yup";

const Fee = ({ next, hide, body, setBody }) => {
  const { isLoading } = useSelector((state) => state.pricing);

  const formik = useFormik({
    initialValues: {
      multiplier: "",
      baseFee: "",
    },
    validationSchema: Yup.object({
      multiplier: Yup.number().required("This field is required"),
      baseFee: Yup.number().required("This field is required"),
    }),
    onSubmit: (values) => {
      const feeBody = {
        multiplier: values.multiplier,
        baseFee: values.baseFee,
      };
      setBody({ ...body, ...feeBody });
      next();
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-8 mb-4">
        <p>Locations</p>
        <div className="h-[1px] w-full bg-fruitSalad"></div>
        <p>Fee</p>
        <div className="h-[1px] w-full bg-catSkillWhite"></div>
        <p>Detail</p>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="">
            <InputField
              placeholder="Enter multiplier e.g 2"
              label="Multiplier for this location - Base fee will be multiplied by the multiplier"
              name="multiplier"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.multiplier}
            />
            {formik.touched.multiplier && formik.errors.multiplier ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.multiplier}
              </p>
            ) : null}
          </div>
          <div className="">
            <InputField
              placeholder="Enter base fee"
              label="Base fee - This will be default for all clients"
              name="baseFee"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.baseFee}
            />
            {formik.touched.baseFee && formik.errors.baseFee ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.baseFee}
              </p>
            ) : null}
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : "Next"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Fee;
