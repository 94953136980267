import { createSlice } from "@reduxjs/toolkit";

import {
  getFleets,
  assignRiderToFleet,
  getRiders,
  getFleetType,
  addFleet,
  addFleetType,
  getFleetByRiderMobile,
  registerRider,
  updateRiderTeam,
  getRidersByTeamId,
  assignRiderToClient,
  updateMultipleRidersTeam,
  getFleetByRegNumber,
  getFilterableRiders,
  approveRider,
  deactivateRider,
  blacklistRider,
  deactivateFleet,
  activateFleet,
} from "./fleetActions";

const initialState = {
  isLoading: false,
  fleets: [],
  fleetTypes: [],
  riders: [],
  currentPage: null,
  limit: null,
  total: null,
};

export const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFleets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fleets = action.payload?.data?.data?.fleet;
    });
    builder.addCase(getFleets.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFleetType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fleetTypes = action.payload?.data?.data;
    });
    builder.addCase(getFleetType.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getRiders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRiders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.riders = action.payload?.data?.riders;
    });
    builder.addCase(getRiders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFilterableRiders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFilterableRiders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.riders = action?.payload?.data;
      state.currentPage = action.payload?.currentPage;
      state.limit = action.payload?.limit;
      state.total = action.payload?.total;
    });
    builder.addCase(getFilterableRiders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getRidersByTeamId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRidersByTeamId.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getRidersByTeamId.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToFleet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignRiderToFleet.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToFleet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFleet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addFleet.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFleet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFleetType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addFleetType.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFleetType.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFleetByRiderMobile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetByRiderMobile.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFleetByRiderMobile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(registerRider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerRider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(registerRider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRiderTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateRiderTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRiderTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToClient.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignRiderToClient.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToClient.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleRidersTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMultipleRidersTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleRidersTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFleetByRegNumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFleetByRegNumber.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFleetByRegNumber.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveRider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveRider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveRider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deactivateRider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deactivateRider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deactivateRider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(blacklistRider.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(blacklistRider.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(blacklistRider.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deactivateFleet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deactivateFleet.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deactivateFleet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(activateFleet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(activateFleet.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(activateFleet.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default fleetSlice.reducer;
