import {
  editAdmin,
  getAdmins,
  registerAdmin,
} from "appstate/features/admin/adminActions";
import {
  LHIconEmail,
  LHIconPasswordNotVisible,
  LHIconPasswordVisible,
} from "assets/svgs";
import { useFormik } from "formik";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";
import { addAdminValidationSchema } from "validation/admin/addNewAdminSchema";

const AddNewAdminContents = ({ hide, isEditing, selectedRow, setAdmins }) => {
  const { isLoading } = useSelector((state) => state.admin);

  const [mobile, setMobile] = useState(isEditing ? selectedRow?.mobile : "");
  const [viewPassword, setViewPassword] = useState(true);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(true);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: isEditing ? selectedRow?.firstName : "",
      lastName: isEditing ? selectedRow?.lastName : "",
      email: isEditing ? selectedRow?.email : "",
      mobile,
      password: "",
      confirmPassword: "",
      role: isEditing ? selectedRow?.role : "",
    },
    validationSchema: addAdminValidationSchema,
    onSubmit: (values) => {
      const body = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        mobile: mobile,
        password: values.password,
        role: values.role,
      };
      isEditing
        ? dispatch(editAdmin({ ...body, adminId: selectedRow?._id })).then(
            (data) => {
              if (data?.payload?.status === 200) {
                dispatch(getAdmins()).then((data) => {
                  if (data?.payload?.status === 200) {
                    setAdmins(data?.payload?.data);
                  }
                });
                toast.success(`Admin ${body.firstname} edited successfully`);
                hide();
              }
            }
          )
        : dispatch(registerAdmin(body)).then((data) => {
            if (data?.payload?.status === 201) {
              dispatch(getAdmins()).then((data) => {
                if (data?.payload?.status === 200) {
                  setAdmins(data?.payload?.data);
                }
              });
              toast.success(`Admin ${body.firstname} created successfully`);
              hide();
            }
          });
    },
  });

  return (
    <div>
      <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
        <div className="flex gap-4 justify-between">
          <div className="w-1/2">
            <InputField
              placeholder="First Name"
              name="firstName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.firstName}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <InputField
              placeholder="Last Name"
              name="lastName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.lastName}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex gap-4 justify-between">
          <div className="w-1/2">
            <div className="relative">
              <InputField
                placeholder="Email address"
                type="email"
                name="email"
                className="!pl-10 !h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <LHIconEmail className="absolute bottom-3 left-2" />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.email}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-2">
              <PhoneInput
                placeholder="Mobile Number"
                country={"ng"}
                onlyCountries={["ng", "gh", "ke"]}
                value={mobile}
                onChange={setMobile}
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.mobile}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div className="w-1/2">
            <div className="relative">
              <InputField
                placeholder="Password"
                type={`${viewPassword ? "password" : "text"}`}
                name="password"
                required
                className="!h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {!viewPassword ? (
                <LHIconPasswordVisible
                  onClick={() => setViewPassword(true)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              ) : (
                <LHIconPasswordNotVisible
                  onClick={() => setViewPassword(false)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              )}
            </div>
            <div>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.password}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-1/2">
            <div className="relative">
              <InputField
                placeholder="Confirm Password"
                type={`${viewConfirmPassword ? "password" : "text"}`}
                name="confirmPassword"
                required
                className="!h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {!viewConfirmPassword ? (
                <LHIconPasswordVisible
                  onClick={() => setViewConfirmPassword(true)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              ) : (
                <LHIconPasswordNotVisible
                  onClick={() => setViewConfirmPassword(false)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              )}
            </div>
            <div>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.confirmPassword}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="pb-6">
          <InputSelect
            admin
            datas={[
              {
                name: "Admin",
                value: "admin",
              },
              {
                name: "Manager",
                value: "Manager",
              },
              {
                name: "User",
                value: "User",
              },
            ]}
            placeholder="Role"
            name="role"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
          />
          {formik.touched.role && formik.errors.role ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.role}
            </p>
          ) : null}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : isEditing ? "Edit" : "Register"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewAdminContents;
