import {
  assignRiderToFleet,
  getFilterableRiders,
  getFleets,
} from "appstate/features/fleet/fleetActions";
import { LHNigerianFlag } from "assets/images";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";
import { addRiderValidationSchema } from "validation/rider/addRiderSchema";

const AssignRider = ({ hide, selectedRow }) => {
  const { fleets, isLoading } = useSelector((state) => state.fleet);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fleetId: "",
    },
    validationSchema: addRiderValidationSchema,
    onSubmit: (values) => {
      const body = {
        fleetId: values.fleetId,
      };
      dispatch(
        assignRiderToFleet({
          fleetId: body.fleetId,
          riderInfo: {
            firstName: selectedRow?.user?.firstName,
            lastName: selectedRow?.user?.lastName,
            email: selectedRow?.user?.email,
            mobile: selectedRow?.user?.mobile,
            country: "Nigeria",
            authId: selectedRow?.user?.authId,
          },
        })
      ).then((data) => {
        if (data?.payload?.status === 201) {
          dispatch(getFilterableRiders({ page: 1 }));
          dispatch(getFleets());
          toast.success(`Rider assigned successfully!`);
          hide();
        }
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <p className="text-[14px]">Rider Information</p>
        <div className="flex pb-4 justify-between">
          <div className="w-[45%]">
            <InputField
              disabled
              placeholder="First Name"
              name="firstName"
              className="!h-[50px]"
              value={selectedRow?.user?.firstName}
            />
          </div>
          <div className="w-[45%]">
            <InputField
              disabled
              placeholder="Last Name"
              name="lastName"
              className="!h-[50px]"
              value={selectedRow?.user?.lastName}
            />
          </div>
        </div>
        <div className="flex pb-4 justify-between">
          <div className="w-[45%]">
            <InputField
              disabled
              placeholder="Rider Email"
              name="email"
              className="!h-[50px]"
              value={selectedRow?.user?.email}
            />
          </div>
          <div className="w-[45%]">
            <div className="flex items-center gap-2">
              <div className="bg-catSkillWhite w-[90px] px-2 h-[50px] rounded-lg flex gap-1 items-center">
                <img src={LHNigerianFlag} alt="select country" />
                <p className="text-slateGray text-[12px]">+234</p>
              </div>
              <div className="w-full">
                <InputField
                  disabled
                  placeholder="Phone Number"
                  name="mobile"
                  className="!h-[50px]"
                  value={selectedRow?.user?.mobile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-6">
          <InputSelect
            admin
            datas={fleets}
            label="Fleet"
            placeholder="Assign Fleet"
            name="fleetId"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fleetId}
          />
          {formik.touched.fleetId && formik.errors.fleetId ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.fleetId}
            </p>
          ) : null}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Assign"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AssignRider;
