import Modal from "layout/modal/Modal";
import React from "react";
import UpdateOrderAmountContent from "./UpdateAmount";

const UpdateOrderAmountModal = ({
  isShown,
  hide,
  id,
  currentOrder,
  setCurrentOrder,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Update Order Amount">
      <UpdateOrderAmountContent
        hide={hide}
        id={id}
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
      />
    </Modal>
  );
};

export default UpdateOrderAmountModal;
