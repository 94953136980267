import { LHColouredStrippedLogo } from "assets/images";
import { LHIconMessageNoti, LHIconNotificationRed } from "assets/svgs";
import React from "react";

const DashboardHeader = () => {
  return (
    <div className="w-full bg-white flex justify-end items-center p-3">
      <div className="flex justify-center items-center gap-4">
        <LHIconMessageNoti />
        <LHIconNotificationRed />
        <div className="w-[48px] h-[48px] rounded-full">
          <img src={LHColouredStrippedLogo} alt="Active User" />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
