import {
  getFilterableRiders,
  registerRider,
} from "appstate/features/fleet/fleetActions";
import {
  LHIconPasswordNotVisible,
  LHIconPasswordVisible,
  LHIconSingleDistance,
} from "assets/svgs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import { createRiderValidationSchema } from "validation/fleet/createRiderSchema";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import PhoneInput from "react-phone-input-2";
import InputSelect from "shared/InputSelect";

const AddNewRider = ({ hide, setCurrentRiders }) => {
  const { isLoading } = useSelector((state) => state.fleet);
  const [viewPassword, setViewPassword] = useState(true);
  const [locLongitude, setLocLongitude] = useState(null);
  const [locLatitude, setLocLatitude] = useState(null);
  const [locObject, setLocObject] = useState("");
  const [address, setAddress] = useState({});
  const [showPredictions, setShowPredictions] = useState(false);
  const [input, setInput] = useState("");
  const [mobile, setMobile] = useState("");
  const [vehicle, setVehicle] = useState("");

  const { fleetTypes } = useSelector((state) => state.fleet);

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: ["address"],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: address.place_id,
        },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, address, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      setLocLongitude(
        locObject?.geometry?.location?.lng((e) => {
          return e;
        })
      );
      setLocLatitude(
        locObject?.geometry?.location?.lat((e) => {
          return e;
        })
      );
    };
    getLngLat();
  }, [locObject]);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      vehicle: vehicle,
      password: "",
    },
    validationSchema: createRiderValidationSchema,
    onSubmit: (values) => {
      const body = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        mobile: mobile,
        city: values.city,
        vehicle: vehicle,
        password: values.password,
        type: "rider",
        locLatitude,
        locLongitude,
      };
      dispatch(registerRider(body)).then((data) => {
        if (data?.payload?.status === 201) {
          dispatch(
            getFilterableRiders({
              page: 1,
            })
          ).then((data) => {
            setCurrentRiders(data?.payload?.data);
          });
          toast.success(
            `Rider ${body.firstname} ${body.lastname} created successfully`
          );
          hide();
        }
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <p className="text-[14px]">Rider Information</p>
        <div className="flex pb-4 justify-between gap-2">
          <div className="w-1/2">
            <InputField
              placeholder="First Name"
              name="firstName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.firstName}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <InputField
              placeholder="Last Name"
              name="lastName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.lastName}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex pb-4 justify-between gap-2">
          <div className="w-1/2">
            <InputField
              placeholder="Rider Email"
              name="email"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.email}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-2">
              <PhoneInput
                placeholder="Mobile Number"
                country={"ng"}
                onlyCountries={["ng", "gh", "ke"]}
                value={mobile}
                onChange={setMobile}
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex pb-4 justify-between gap-2">
          <div className="w-1/2">
            <InputField
              placeholder="City"
              name="city"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            />
            {formik.touched.city && formik.errors.city ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.city}
              </p>
            ) : null}
          </div>
          <div className="relative w-1/2">
            <InputField
              className="!h-[50px]"
              placeholder="Password"
              type={`${viewPassword ? "password" : "text"}`}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {!viewPassword ? (
              <LHIconPasswordVisible
                onClick={() => setViewPassword(true)}
                className="absolute bottom-3 right-4 cursor-pointer"
              />
            ) : (
              <LHIconPasswordNotVisible
                onClick={() => setViewPassword(false)}
                className="absolute bottom-3 right-4 cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="flex pb-4">
          <div className="w-full">
            <InputSelect
              required
              datas={fleetTypes}
              placeholder="Asset Type"
              name="assetType"
              className="!h-[50px]"
              onChange={(e) => setVehicle(e.target.value)}
              value={vehicle}
            />
          </div>
        </div>
        <div className="relative mb-6">
          <InputField
            required
            placeholder="Rider Address"
            name="address"
            className="!h-[50px]"
            onChange={(e) => {
              setShowPredictions(true);
              setInput(e.target.value);
              getPlacePredictions({ input: e.target.value });
            }}
            loading={isPlacePredictionsLoading.toString()}
            value={input}
          />
          <LHIconSingleDistance className="absolute right-3 bottom-4" />
          {showPredictions &&
            !isPlacePredictionsLoading &&
            placePredictions?.map((item, idx) => (
              <p
                className="text-[12px] hover:bg-slate-100 p-1 rounded-lg mb-2 text-gullGray cursor-pointer"
                key={idx}
                onClick={() => {
                  setInput(item?.description);
                  setShowPredictions(false);
                  setAddress(item);
                }}
              >
                {item?.description}
              </p>
            ))}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewRider;
