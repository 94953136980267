import React, { useState } from "react";
import Button from "shared/Button";
import Card from "shared/Card";
import PageTitle from "shared/PageTitle";
import AddNewAdminModal from "./modal/addnewadmin";

const AccessControl = ({ currentAdmin, setAdmins }) => {
  const [isShown, setIsShown] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card>
      <AddNewAdminModal
        isEditing={isEditing}
        isShown={isShown}
        selectedRow={currentAdmin}
        setAdmins={setAdmins}
        hide={() => {
          setIsEditing(false);
          setIsShown(false);
        }}
      />
      <PageTitle title="Access control" />
      <div className="flex flex-wrap gap-2 justify-between">
        <div className="max-[450px]:w-full">
          <Button
            onClick={() => {
              setIsEditing(true);
              setIsShown(true);
            }}
            className="!h-[45px] max-[450px]:w-full bg-white !text-congressBlue rounded-none font-bold border-b-2 border-b-congressBlue"
            blue
          >
            Your Account
          </Button>
        </div>
        <div className="max-[450px]:w-full">
          <Button
            onClick={() => setIsShown(true)}
            className="!bg-pickledBlueWood max-[450px]:w-full !h-[45px] text-white"
          >
            New Admin
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default AccessControl;
