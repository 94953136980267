import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import NewOrderModal from "./modal/neworder";
import "react-loading-skeleton/dist/skeleton.css";
import Details from "./OrderDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterableOrders,
  getOrderAnalytics,
} from "appstate/features/order/orderActions";
import { getClients } from "appstate/features/client/clientActions";
import {
  getFilterableRiders,
  getFleetType,
} from "appstate/features/fleet/fleetActions";
import {
  setEndDate,
  setFilterStatus,
  setFilteredClient,
  setStartDate,
} from "appstate/features/order/orderSlice";

const OrdersContents = () => {
  const {
    orders,
    currentPage,
    isLoading,
    limit,
    total,
    startDate,
    endDate,
    filterStatus,
    filteredClient,
  } = useSelector((state) => state.order);

  const [currentOrders, setCurrentOrders] = useState(orders);
  const [isShown, setIsShown] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [todayStatus, setTodayStatus] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
    dispatch(getFleetType());
    dispatch(getFilterableRiders({ page: 1 }));
    dispatch(getFilterableOrders({ page: 1, today: true })).then((data) => {
      setCurrentOrders(data?.payload?.data);
    });
    dispatch(setStartDate(null));
    dispatch(setEndDate(null));
    dispatch(setFilterStatus(null));
    dispatch(setFilteredClient(null));
    dispatch(
      getOrderAnalytics({
        merchantId: false,
        merchantOutletId: false,
        riderAuthId: false,
      })
    ).then((data) => {
      if (data?.payload?.status === 201) {
        setTotalOrders(data?.payload?.data?.totalOrders);
        setActiveOrders(data?.payload?.data?.activeOrders);
        setCompletedOrders(data?.payload?.data?.completedOrders);
        setPendingOrders(data?.payload?.data?.pendingOrders);
      }
    });
  }, [dispatch]);

  return (
    <DashboardLayout>
      <NewOrderModal
        isShown={isShown}
        setCurrentOrders={setCurrentOrders}
        hide={() => setIsShown(false)}
      />
      <Header
        info1="All"
        info2="Active"
        info3="Inactive"
        info4="Delivered"
        title="Order"
        btnText="New Order"
        dashboard
        todayStatus={todayStatus}
        setTodayStatus={setTodayStatus}
        todayFilterFn={() => {
          if (filterStatus) {
            filteredClient
              ? dispatch(
                  getFilterableOrders({
                    page: 1,
                    today: true,
                    status: filterStatus,
                    merchantId: filteredClient,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                })
              : dispatch(
                  getFilterableOrders({
                    page: 1,
                    today: true,
                    status: filterStatus,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                });
          } else {
            filteredClient
              ? dispatch(
                  getFilterableOrders({
                    page: 1,
                    today: true,
                    merchantId: filteredClient,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                })
              : dispatch(
                  getFilterableOrders({
                    page: 1,
                    today: true,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                });
          }
          dispatch(setStartDate(null));
          dispatch(setEndDate(null));
        }}
        allFilterFn={() => {
          if (filterStatus) {
            filteredClient
              ? dispatch(
                  getFilterableOrders({
                    page: 1,
                    status: filterStatus,
                    merchantId: filteredClient,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                })
              : dispatch(
                  getFilterableOrders({
                    page: 1,
                    status: filterStatus,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                });
          } else {
            filteredClient
              ? dispatch(
                  getFilterableOrders({
                    page: 1,
                    merchantId: filteredClient,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                })
              : dispatch(
                  getFilterableOrders({
                    page: 1,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                });
          }
          dispatch(setStartDate(null));
          dispatch(setEndDate(null));
        }}
        dateFilterFn={() => {
          if (filterStatus) {
            filteredClient
              ? dispatch(
                  getFilterableOrders({
                    page: 1,
                    startDate,
                    endDate,
                    status: filterStatus,
                    merchantId: filteredClient,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                })
              : dispatch(
                  getFilterableOrders({
                    page: 1,
                    startDate,
                    endDate,
                    status: filterStatus,
                  })
                ).then((data) => {
                  setCurrentOrders(data?.payload?.data);
                });
          } else {
            dispatch(
              getFilterableOrders({
                page: 1,
                startDate,
                endDate,
              })
            ).then((data) => {
              setCurrentOrders(data?.payload?.data);
            });
          }
        }}
        setCurrentOrders={setCurrentOrders}
        currentOrders={currentOrders}
        total={total}
        amount={`${totalOrders}`}
        activeAmount={`${activeOrders}`}
        inactiveAmount={`${completedOrders}`}
        deliveredAmount={`${pendingOrders}`}
        onClick={() => setIsShown(true)}
      />
      <Details
        todayStatus={todayStatus}
        startDate={startDate}
        endDate={endDate}
        currentOrders={currentOrders}
        setCurrentOrders={setCurrentOrders}
        currentPage={currentPage}
        isLoading={isLoading}
        limit={limit}
        total={total}
      />
    </DashboardLayout>
  );
};

export default OrdersContents;
