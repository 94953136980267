import { LHIconFilter, LHIconMenuDots } from "assets/svgs";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import Table from "shared/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovedRateAdmin,
  updateApprovedRate,
} from "appstate/features/client/clientActions";
import Button from "shared/Button";
import DashboardLayout from "layout/dashboardlayout";
import ApprovedRateModal from "features/client/singleclient/modal/addNewRate";
import { toast } from "react-toastify";
import TableControl from "shared/TableControl";
import Loader from "shared/Loader";
import InputSelect from "shared/InputSelect";
import { MdOutlineStorefront } from "react-icons/md";
import Dropdown from "shared/Dropdown";
import { BiFilterAlt } from "react-icons/bi";

export const filterItems = [
  { name: "Status [All]", value: "" },
  {
    name: "Active",
    value: "Active",
  },
  {
    name: "Approved",
    value: "Approved",
  },
  {
    name: "Unapproved",
    value: "Unapproved",
  },
  {
    name: "Inactive",
    value: "Inactive",
  },
];

const Details = () => {
  const { isLoading } = useSelector((state) => state.pricing);
  const { clients } = useSelector((state) => state.client);
  const [isShown, setIsShown] = useState(false);
  const [approvedRates, setApprovedRates] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentApprovedRate, setCurrentApprovedRate] = useState(null);
  const [showControls, setShowControls] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [activeMerchant, setActiveMerchant] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getApprovedRateAdmin({
        page: 1,
      })
    ).then((data) => {
      if (data?.payload?.status === 201) {
        setApprovedRates(data?.payload?.data);
      }
    });
  }, [dispatch]);

  const controls = [
    {
      text: "Edit Rate",
      action: () => {
        setIsEditing(true);
        setIsShown(true);
        setCurrentApprovedRate(selectedRow);
      },
    },
    {
      text: "Disapprove Rate",
      action: () => {
        dispatch(
          updateApprovedRate({
            id: selectedRow?._id,
            body: { rate: selectedRow?.rate, approved: false, active: true },
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success("Rate disapproved!");
            dispatch(
              getApprovedRateAdmin({
                page: 1,
              })
            ).then((data) => {
              setApprovedRates(data?.payload?.data);
            });
            setShowControls(false);
          }
        });
      },
    },

    {
      text: "Deactivate Rate",
      action: () => {
        dispatch(
          updateApprovedRate({
            id: selectedRow?._id,
            body: { rate: selectedRow?.rate, approved: true, active: false },
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success("Rate deactivated!");
            dispatch(
              getApprovedRateAdmin({
                page: 1,
              })
            ).then((data) => {
              setApprovedRates(data?.payload?.data);
            });
            setShowControls(false);
          }
        });
      },
    },
  ];

  const handleOrderMerchantFilter = (merchant) => {
    // console.log(merchant);
    setActiveMerchant(merchant);
    merchant
      ? dispatch(
          getApprovedRateAdmin({
            page: 1,
            merchantId: merchant?._id,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        })
      : dispatch(
          getApprovedRateAdmin({
            page: 1,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        });
  };

  const handleFilterStatus = (item) => {
    setCurrentStatus(item);
    item === "Active"
      ? dispatch(
          getApprovedRateAdmin({
            page: 1,
            active: true,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        })
      : item === "Inactive"
      ? dispatch(
          getApprovedRateAdmin({
            page: 1,
            active: false,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        })
      : item === "Approved"
      ? dispatch(
          getApprovedRateAdmin({
            page: 1,
            approved: true,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        })
      : item === "Unapproved"
      ? dispatch(
          getApprovedRateAdmin({
            page: 1,
            approved: false,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        })
      : dispatch(
          getApprovedRateAdmin({
            page: 1,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            setApprovedRates(data?.payload?.data);
          }
        });
  };

  const ratesColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Location",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.locations[0]?.address}</p>
      ),
    },
    {
      Header: "Rate",
      accessor: (d) => <p className="flex justify-start ml-4">NGN{d.rate}</p>,
    },
    {
      Header: "Partner",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.partner?.partnerName}</p>
      ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.active ? (
          <div className="flex justify-start gap-1 ml-4">
            {d.approved ? (
              <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
                Approved
              </div>
            ) : (
              <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2">
                Unapproved
              </div>
            )}
            <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
              Active
            </div>
          </div>
        ) : (
          <div className="flex justify-start gap-1 ml-4">
            {d.approved ? (
              <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
                Approved
              </div>
            ) : (
              <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2">
                Unapproved
              </div>
            )}
            <div className="bg-varden text-koromiko text-[12px] rounded-lg py-1 px-2">
              Inactive
            </div>
          </div>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Card className="!h-fit">
        <ApprovedRateModal
          undefinedClient={true}
          setApprovedRates={setApprovedRates}
          isShown={isShown}
          isEditing={isEditing}
          currentApprovedRate={currentApprovedRate}
          hide={() => {
            setShowControls(false);
            setIsShown(false);
            setIsEditing(false);
          }}
        />
        <div className="flex flex-wrap gap-2 justify-between items-center">
          <p className="text-[18px] font-medium">Approved Rate(s)</p>
          <div className="flex flex-wrap gap-4 items-center">
            <div className="w-[13rem] relative flex items-center gap-2 text-sm py-[8px] px-[12px] cursor-pointer rounded-xl bg-white text-[#6F767E] border border-[#EFEFEF]">
              <MdOutlineStorefront size={16} />
              <Dropdown
                isObj={true}
                items={clients}
                selectedItem={activeMerchant?.name || "Merchant [All]"}
                setSelectedItem={(item) => handleOrderMerchantFilter(item)}
              />
            </div>

            <div className="w-[11rem] relative flex items-center gap-2 text-sm py-[8px] px-[12px] cursor-pointer rounded-xl bg-white text-[#6F767E] border border-[#EFEFEF]">
              <BiFilterAlt size={16} />
              <Dropdown
                items={filterItems}
                selectedItem={currentStatus || "Status [All]"}
                setSelectedItem={(item) => handleFilterStatus(item)}
              />
            </div>

            <Button
              onClick={() => setIsShown(true)}
              className="!h-[40px]"
              type="button"
              blue
            >
              Add New Rate
            </Button>
          </div>
        </div>
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              columns={ratesColumns}
              data={approvedRates}
              type="You don’t have any approved rate. Create an approved rate to get started"
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Details;

{
  /* <div className="">
              <InputSelect
                admin
                datas={clients}
                placeholder="Merchant"
                name="merchant"
                divClass=" border-2 border-gullGray rounded-lg w-[200px]"
                className="!bg-white"
                onChange={(e) => {
                  e.target.value
                    ? dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                          merchantId: e.target.value,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      })
                    : dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      });
                }}
              />
            </div> */
}

{
  /* <div className="">
              <InputSelect
                admin
                datas={[
                  {
                    name: "Active",
                    value: "Active",
                  },
                  {
                    name: "Approved",
                    value: "Approved",
                  },
                  {
                    name: "Unapproved",
                    value: "Unapproved",
                  },
                  {
                    name: "Inactive",
                    value: "Inactive",
                  },
                ]}
                placeholder="Status"
                name="status"
                divClass="border-2 border-gullGray rounded-lg"
                className=" !bg-white"
                onChange={(e) => {
                  e.target.value === "Active"
                    ? dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                          active: true,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      })
                    : e.target.value === "Inactive"
                    ? dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                          active: false,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      })
                    : e.target.value === "Approved"
                    ? dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                          approved: true,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      })
                    : e.target.value === "Unapproved"
                    ? dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                          approved: false,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      })
                    : dispatch(
                        getApprovedRateAdmin({
                          page: 1,
                        })
                      ).then((data) => {
                        if (data?.payload?.status === 201) {
                          setApprovedRates(data?.payload?.data);
                        }
                      });
                }}
              />
            </div> */
}
