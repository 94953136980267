import { LHActivityFilled, LHStarFilled, LHTimerFilled } from "assets/images";
import React, { useState } from "react";
import Card from "shared/Card";
import PageTitle from "shared/PageTitle";
import {
  AverageDelivery,
  StorePerformance,
  SuccessfulDeliveries,
} from "./optionsComponents";

const DashboardDetails = () => {
  const [activeComp, setActiveComp] = useState(0);

  const options = [
    {
      id: 0,
      name: "Successful Deliveries",
      icon: LHActivityFilled,
      component: <SuccessfulDeliveries />,
    },
    {
      id: 1,
      name: "Average Delivery Time",
      icon: LHTimerFilled,
      component: <AverageDelivery />,
    },
    {
      id: 2,
      name: "Total Deliveries",
      icon: LHStarFilled,
      component: <StorePerformance />,
    },
  ];

  return (
    <div className="flex gap-4 max-[1100px]:flex-col">
      <div className="w-[30%] max-[1100px]:w-full">
        <Card className={"!p-0 !pl-6 !py-6 min-h-full"}>
          <div className="mb-6">
            <PageTitle title="Others" />
          </div>
          <div className="flex flex-col gap-4">
            {options.map((option) => (
              <div
                key={option.id}
                onClick={() => setActiveComp(option.id)}
                className={`flex gap-5 items-center cursor-pointer ${
                  activeComp === option.id && "border-r-8 border-sunGlow"
                }`}
              >
                <img src={option.icon} alt={option.name} />
                <p className="text-[16px] font-medium">{option.name}</p>
              </div>
            ))}
          </div>
        </Card>
      </div>
      <div className="w-[70%] max-[1100px]:w-full">
        <Card>{options[activeComp].component}</Card>
      </div>
    </div>
  );
};

export default DashboardDetails;
