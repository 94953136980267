import Modal from "layout/modal/Modal";
import React from "react";
import AssignRiderToTeam from "./AssignRiderToTeam";

const AssignRiderToTeamModal = ({
  isShown,
  hide,
  team,
  teamRiders,
  setTeamRiders,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Assign Rider">
      <AssignRiderToTeam
        hide={hide}
        team={team}
        teamRiders={teamRiders}
        setTeamRiders={setTeamRiders}
      />
    </Modal>
  );
};

export default AssignRiderToTeamModal;
