import { logout } from "appstate/features/auth/authSlice";
import { LHWhiteLogo } from "assets/images";
import {
  LHIconArrowDown,
  LHIconArrowUp,
  LHIconCurve,
  LHIconLogout,
  LHIconStrippedLogo,
} from "assets/svgs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { sidebarContent } from "utils/data";

const DashboardSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sidebarArray, setSidebarArray] = useState(sidebarContent);

  const toggleSidebar = (index) => {
    setSidebarArray(
      sidebarArray.map((sidebar) => {
        sidebar.items.map((sidebarItem) => {
          if (sidebarItem.id === index) {
            sidebarItem.open = !sidebarItem.open;
          } else {
            sidebarItem.open = false;
          }
          return sidebarItem;
        });
        return sidebar;
      })
    );
  };

  return (
    <div className="bg-congressBlue flex flex-col md:min-w-[270px] justify-between gap-2 max-md:px-2 max-md:py-3 py-6">
      <div className="m-3 flex justify-center">
        <Link to={"/admin/dashboard"}>
          <img
            src={LHWhiteLogo}
            className="hidden md:block"
            alt="logistics Hub"
          />
          <div className="md:hidden p-3 rounded-full border-4 border-white">
            <LHIconStrippedLogo className="w-4 h-4" />
          </div>
        </Link>
      </div>
      <div className="overflow-y-auto max-md:h-[calc(100vh-162px)] px-5 h-[calc(100vh-138px)]">
        {sidebarArray.map((content, index) => (
          <div key={index} className="mb-4 text-white">
            <p className="max-md:hidden mb-6 text-[12px]">
              {content.title && content.title}
            </p>
            {content.items.map((item) => (
              <div
                key={item.id}
                className="md:ml-2 mb-6 flex max-md:items-center flex-col"
              >
                <div className="flex gap-2 flex-col">
                  <div className="flex items-center justify-between">
                    <NavLink
                      to={item.path}
                      onClick={() => {
                        toggleSidebar(item.id);
                      }}
                      className={({ isActive }) =>
                        `${
                          isActive && item.path
                            ? "w-full max-md:w-fit bg-sunGlow p-2 rounded-lg flex justify-between"
                            : "w-full rounded-lg flex justify-between"
                        }`
                      }
                    >
                      <div className="flex gap-3 justify-start items-center">
                        <span>{item.icon}</span>
                        <p className="text-[14px] hidden md:block">
                          {item.title}
                        </p>
                      </div>
                    </NavLink>
                    {item.collapsable && (
                      <span
                        className="max-md:hidden cursor-pointer z-40"
                        onClick={() => {
                          toggleSidebar(item.id);
                        }}
                      >
                        {item.open ? <LHIconArrowUp /> : <LHIconArrowDown />}
                      </span>
                    )}
                  </div>
                  {item.collapsable && item.open && (
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? "bg-sunGlow rounded-lg flex justify-between"
                            : "rounded-lg flex justify-between"
                        }`
                      }
                      to={item.collapsable.path}
                    >
                      <div className="relative -left-4 flex gap-3 items-center">
                        <LHIconCurve />
                        <p className="whitespace-nowrap hidden md:block text-[14px]">
                          {item.collapsable.title}
                        </p>
                      </div>
                    </NavLink>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div
        className="px-3"
        onClick={() => {
          dispatch(logout());
          navigate("/");
        }}
      >
        <div className="p-2 cursor-pointer flex justify-center rounded-lg border border-white text-white hover:text-red-500  hover:border-red-500">
          <p className="hidden md:block">Logout</p>
          <LHIconLogout className="md:hidden" />
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
