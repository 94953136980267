import moment from "moment";

export const formattedTime = (etaData) => {
  // console.log(etaData);
  const eta = parseInt(etaData) / 60;
  const formatted = moment.duration(eta.toFixed(1), "minutes");
  if (formatted?._data?.hours === 0) {
    return formatted?._data?.minutes + "mins";
  } else if (formatted?._data?.hours > 0) {
    return (
      formatted?._data?.hours + "hrs" + " " + formatted?._data?.minutes + "mins"
    );
  }
};

export const formattedLocalTime = (timeData) => {
  if (timeData === undefined) {
    return null;
  }
  const fTime = moment(timeData);
  return fTime?._d?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formattedLocalDate = (dateData) => {
  if (dateData === undefined) {
    return null;
  }
  const fDate = moment(dateData);
  return fDate?._d?.toString()?.slice(4, 16);
};
