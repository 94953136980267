import { ordersBreakDown } from "appstate/features/analytics/analyticsActions";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import Card from "shared/Card";
import ChartLabel from "shared/ChartLabel";

const DeliveryChart = () => {
  const [analyticsObj, setAnalyticsObj] = useState(null);
  const [avgDelivery, setAvgDelivery] = useState([]);
  const [failedDeliveries, setFailedDeliveries] = useState([]);
  const [completedDeliveries, setCompletedDeliveries] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersBreakDown({ merchantId: "" })).then((data) => {
      setAnalyticsObj(data?.payload?.data?.data?.sort((a, b) => a._id - b._id));
      setAvgDelivery(
        data?.payload?.data?.data?.map(({ avgDeliveryTime }) =>
          avgDeliveryTime === null ? 0 : avgDeliveryTime
        )
      );
      setFailedDeliveries(
        data?.payload?.data?.data?.map(({ totalFailedDeliveries }) =>
          totalFailedDeliveries === null ? 0 : totalFailedDeliveries
        )
      );
      setCompletedDeliveries(
        data?.payload?.data?.data?.map(({ totalCompletedDeliveries }) =>
          totalCompletedDeliveries === null ? 0 : totalCompletedDeliveries
        )
      );
    });
  }, [dispatch]);

  const chartOptions = {
    series: [
      {
        name: "Average Delivery Time",
        data: avgDelivery,
      },
      {
        name: "Failed Deliveries",
        data: failedDeliveries,
      },
      {
        name: "Completed Deliveries",
        data: completedDeliveries,
      },
    ],
    options: {
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      zoom: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#F03031", "#475569", "#08C15B"],
      yaxis: {
        labels: {
          formatter: function (val) {
            return val?.toFixed(0);
          },
        },
        tickAmount: 4,
      },
      xaxis: {
        categories: analyticsObj?.map(({ _id }) => {
          let month;
          if (_id === 1) {
            month = "Jan";
          } else if (_id === 2) {
            month = "Feb";
          } else if (_id === 3) {
            month = "Mar";
          } else if (_id === 4) {
            month = "Apr";
          } else if (_id === 5) {
            month = "May";
          } else if (_id === 6) {
            month = "Jun";
          } else if (_id === 7) {
            month = "Jul";
          } else if (_id === 8) {
            month = "Aug";
          } else if (_id === 9) {
            month = "Sep";
          } else if (_id === 10) {
            month = "Oct";
          } else if (_id === 11) {
            month = "Nov";
          } else if (_id === 12) {
            month = "Dec";
          }
          return month;
        }),
      },
    },
  };

  return (
    <Card>
      <div className="flex flex-wrap gap-4 justify-between items-center mb-8">
        <div className={`flex gap-4 items-center justify-start`}>
          <div className="w-[16px] h-[32px] rounded-sm bg-malachite"></div>
          <p className="text-[12px] md:text-[20px] font-semibold">Delivery</p>
        </div>
        <div className="flex gap-2 flex-wrap">
          <ChartLabel color={"bg-pomegranate"} text={"Average Delivery Time"} />
          <ChartLabel color={"bg-fiord"} text={"Failed Deliveries"} />
          <ChartLabel color={"bg-malachite"} text={"Completed Deliveries"} />
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        height={350}
      />
    </Card>
  );
};

export default DeliveryChart;
