import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import DashboardDetails from "./Details";
import { useDispatch } from "react-redux";
import { getClients } from "appstate/features/client/clientActions";
import { getOrderAnalytics } from "appstate/features/order/orderActions";

const AdminDashboardContents = () => {
  const dispatch = useDispatch();
  const [todayStatus, setTodayStatus] = useState(true);
  const [totalOrders, setTotalOrders] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);

  useEffect(() => {
    dispatch(getClients());
    dispatch(
      getOrderAnalytics({
        merchantId: false,
        merchantOutletId: false,
        riderAuthId: false,
      })
    ).then((data) => {
      if (data?.payload?.status === 201) {
        setTotalOrders(data?.payload?.data?.totalOrders);
        setActiveOrders(data?.payload?.data?.activeOrders);
        setCompletedOrders(data?.payload?.data?.completedOrders);
        setPendingOrders(data?.payload?.data?.pendingOrders);
      }
    });
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Header
        dashboard
        todayStatus={todayStatus}
        setTodayStatus={setTodayStatus}
        dateFilterFn={() => {}}
        todayFilterFn={() => {}}
        allFilterFn={() => {}}
        info1="Total Clients"
        info2="Active Client"
        info3="Inactive Client"
        info4="Total order"
        amount={`${totalOrders}`}
        activeAmount={`${activeOrders}`}
        inactiveAmount={`${completedOrders}`}
        deliveredAmount={`${pendingOrders}`}
        title="Dashboard"
        // onClick={() => setIsShown(true)}
      />
      <DashboardDetails />
    </DashboardLayout>
  );
};

export default AdminDashboardContents;
