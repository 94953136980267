import React from "react";
import Chart from "./Chart";
import RiderInfo from "./RiderInfo";

const SingleRiderDetails = ({ currentRider, setCurrentRider }) => {
  return (
    <div>
      <div className="flex max-[1200px]:flex-wrap gap-4">
        <div className="w-full min-[1200px]:w-3/5 flex flex-col gap-4">
          <Chart currentRider={currentRider} />
        </div>
        <div className="w-full min-[1200px]:w-2/5">
          <RiderInfo
            currentRider={currentRider}
            setCurrentRider={setCurrentRider}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleRiderDetails;
