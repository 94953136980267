import Modal from "layout/modal/Modal";
import React, { useState } from "react";
import Locations from "./Locations";
import Fee from "./Fee";
import Details from "./Details";

const AddNewPricingModal = ({ isShown, hide }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [body, setBody] = useState({});

  const handleNextStep = () => {
    if (currentStep === 0) {
      return setCurrentStep((prev) => prev + 1);
    } else if (currentStep === 1) {
      return setCurrentStep((prev) => prev + 1);
    }
  };

  const addNewPricingSteps = [
    <Locations
      next={handleNextStep}
      setBody={setBody}
      hide={() => {
        setCurrentStep(0);
        hide();
      }}
    />,
    <Fee
      next={handleNextStep}
      body={body}
      setBody={setBody}
      hide={() => {
        setCurrentStep(0);
        hide();
      }}
    />,
    <Details
      body={body}
      hide={() => {
        setCurrentStep(0);
        hide();
      }}
    />,
  ];

  return (
    <Modal
      isShown={isShown}
      hide={() => {
        setCurrentStep(0);
        hide();
      }}
      title="New Pricing"
    >
      {addNewPricingSteps[currentStep]}
    </Modal>
  );
};

export default AddNewPricingModal;
