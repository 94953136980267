import { getMerchantStores } from "appstate/features/client/clientActions";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { LHIconSingleDistance } from "assets/svgs";
import { toast } from "react-toastify";
import {
  createInvoice,
  getInvoices,
} from "appstate/features/invoice/invoiceActions";
import AddNewOrderModal from "../addNewOrder";

const AddNewInvoice = ({ hide }) => {
  const { activeUser } = useSelector((state) => state.auth);
  const { clients } = useSelector((state) => state.client);
  const { isLoading } = useSelector((state) => state.invoice);
  const [amount, setAmount] = useState(0);
  const [merchantStores, setMerchantStores] = useState([]);
  const [selectedClientStore, setSelectedClientStore] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [showPredictions, setShowPredictions] = useState(false);
  const [input, setInput] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: ["address"],
  };
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      options,
    });

  const dispatch = useDispatch();

  const clientList = clients
    ?.filter((user) => user?.approved)
    ?.map((client) => ({
      id: client?._id,
      name: client?.name,
      value: { merchantName: client?.name, merchantId: client?._id },
    }));

  const clientStoreList = merchantStores?.map((store) => ({
    id: store?._id,
    name: store?.name,
    value: {
      outletName: store?.name,
      outletId: store?._id,
    },
  }));

  const onClientSelect = (selectedList, selectedItem) => {
    setSelectedClient(selectedItem.value);
  };

  const onClientStoreSelect = (selectedList, selectedItem) => {
    setSelectedClientStore(selectedItem.value);
  };

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        getMerchantStores({ id: selectedClient?.merchantId, page: 0 })
      ).then((data) => {
        setMerchantStores(data?.payload?.data?.data?.data);
      });
    }
  }, [dispatch, selectedClient]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input || !selectedClient)
      return toast.error("Please fill out all fields!");
    const body = selectedClientStore
      ? {
          merchant: selectedClient,
          merchantOutlet: selectedClientStore ? selectedClientStore : null,
          user: {
            firstName: activeUser?.firstName,
            lastName: activeUser?.lastName,
            email: activeUser?.email,
            mobile: activeUser?.mobile,
            authId: activeUser?._id,
            country: "Nigeria",
          },
          additionalFee: amount ? Number(amount) : 0,
          billingAddress: input,
        }
      : {
          merchant: selectedClient,
          user: {
            firstName: activeUser?.firstName,
            lastName: activeUser?.lastName,
            email: activeUser?.email,
            mobile: activeUser?.mobile,
            authId: activeUser?._id,
            country: "Nigeria",
          },
          additionalFee: amount ? Number(amount) : 0,
          billingAddress: input,
        };
    dispatch(createInvoice(body)).then((data) => {
      if (data?.payload?.status === 201) {
        dispatch(getInvoices());
        setInvoiceData(data?.payload?.data?.data);
        toast.success("Invoice successfully created!");
        setIsOpen(true);
      }
    });
  };

  return (
    <div>
      <AddNewOrderModal
        isShown={isOpen}
        hide={() => {
          hide();
          setIsOpen(false);
        }}
        invoice={invoiceData}
      />
      <form onSubmit={handleSubmit} className="flex gap-4 flex-col">
        <div>
          <p className="font-normal mt-2 text-xs text-midGray">
            Client Information
          </p>
          <div className="">
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onClientSelect}
              options={clientList}
              value={selectedClient}
              placeholder="Select client"
              className="h-[50px] pl-2 rounded-lg bg-catSkillWhite text-[12px]"
            />
          </div>
        </div>
        {merchantStores?.length > 0 && (
          <div className="flex gap-1 flex-col">
            <p className="font-normal mt-2 text-xs text-midGray">
              Select store
            </p>
            <div className="">
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onClientStoreSelect}
                options={clientStoreList}
                value={selectedClientStore}
                placeholder="Optional store"
                className="h-[50px] pl-2 rounded-lg bg-catSkillWhite text-[12px]"
              />
            </div>
          </div>
        )}
        <div>
          <InputField
            label="Additional Fee"
            placeholder="Amount"
            name="amount"
            className="!h-[50px]"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
        </div>
        <div className="relative mb-6">
          <InputField
            label="Billing Address"
            required
            placeholder="Address"
            name="address"
            className="!h-[50px]"
            onChange={(e) => {
              setShowPredictions(true);
              setInput(e.target.value);
              getPlacePredictions({ input: e.target.value });
            }}
            loading={isPlacePredictionsLoading.toString()}
            value={input}
          />
          <LHIconSingleDistance className="absolute right-3 bottom-4" />
          {showPredictions &&
            !isPlacePredictionsLoading &&
            placePredictions?.map((item, idx) => (
              <p
                className="text-[12px] hover:bg-slate-100 p-1 rounded-lg mb-2 text-gullGray cursor-pointer"
                key={idx}
                onClick={() => {
                  setInput(item?.description);
                  setShowPredictions(false);
                }}
              >
                {item?.description}
              </p>
            ))}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px] !px-8" blue>
            {isLoading ? <ButtonAnimation /> : "Create Invoice"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewInvoice;
