const TextArea = ({
  type,
  placeholder,
  disabled,
  onChange,
  name,
  id,
  onBlur,
  className,
  value,
  defaultValue,
  htmlFor,
  onFocus,
  label,
  autoComplete,
  required,
}) => {
  const defaultClass =
    "h-[73px] rounded-lg bg-catSkillWhite placeholder:text-xs placeholder:text-santasGray outline-none p-4 text-xs font-normal";

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className="font-normal text-xs text-midGray mb-[5px]"
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      <textarea
        required={required}
        rows="5"
        className={`${className} ${defaultClass}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        name={name}
        id={id}
        onBlur={onBlur}
        value={value}
        onFocus={onFocus}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default TextArea;
