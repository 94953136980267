import {
  deleteCluster,
  getLocationsClusters,
} from "appstate/features/pricing/pricingActions";
import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InputField from "shared/InputField";
import Loader from "shared/Loader";
import Table from "shared/Table";
import AddNewClusterModal from "./modal/addNewCluster";
import TableControl from "shared/TableControl";

const ClustersDetails = ({ isClusterModalShown, setIsClusterModalShown }) => {
  const { locationsClusters } = useSelector((state) => state.pricing);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [showControls, setShowControls] = useState(false);
  const { isLoading } = useSelector((state) => state.pricing);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const controls = [
    {
      text: "Edit Cluster",
      action: () => {
        setIsEditing(true);
        setShowControls(false);
        setIsClusterModalShown(true);
      },
    },
    {
      text: "Delete Cluster",
      action: () =>
        dispatch(deleteCluster({ clusterId: selectedRow?._id })).then(
          (data) => {
            if (data?.payload?.status === 200) {
              toast.success("Cluster deleted successfully!");
              dispatch(getLocationsClusters());
              setShowControls(false);
            }
          }
        ),
    },
  ];

  const clusterColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Cluster name",
      accessor: (d) => <p className="flex justify-start ml-4">{d.address}</p>,
    },
    {
      Header: "Number of Locations",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4 w-[1rem]">
          {d.locations?.length}
        </p>
      ),
    },
    {
      Header: "Multiplier",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4 w-[1rem]">
          {d.multiplier ? d.multiplier : "N/A"}
        </p>
      ),
    },
    {
      Header: "Locations",
      accessor: (d) => (
        <div className="flex justify-start ml-4">
          {d.locations?.map((location, idx) => (
            <p key={idx} className="mr-1">
              {location?.address?.slice(0, 20)},{" "}
            </p>
          ))}
        </div>
      ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="h-[300px] w-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <AddNewClusterModal
            isShown={isClusterModalShown}
            hide={() => {
              setIsClusterModalShown(false);
              setIsEditing(false);
            }}
            selectedRow={selectedRow}
            isEditing={isEditing}
          />
          <div className="flex items-center flex-wrap gap-3 justify-between">
            <div className="relative">
              <InputField
                placeholder="Search for clusters"
                type="text"
                name="search"
                className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
              />
              <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
            </div>
            <div className="flex items-center flex-wrap gap-4">
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <p className="text-xs">Delete</p>
              </div>
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <LHIconFilter />
              </div>
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <p className="text-xs">Name</p>
              </div>
            </div>
          </div>
          <div>
            <Table
              columns={clusterColumns}
              data={locationsClusters?.filter((cluster) => cluster?.isCluster)}
              type="You don’t have any clusters. Create a new cluster to get started"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ClustersDetails;
