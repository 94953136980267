import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { addFleet, getFleets } from "appstate/features/fleet/fleetActions";

const AddNewFleet = ({ hide }) => {
  const { isLoading, fleetTypes } = useSelector((state) => state.fleet);
  const { clients } = useSelector((state) => state.client);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [locObject, setLocObject] = useState("");
  const [address, setAddress] = useState({});
  const [input, setInput] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedFleetType, setSelectedFleetType] = useState(null);

  const dispatch = useDispatch();

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: ["address"],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: address.place_id,
        },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, address, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      setLng(
        locObject?.geometry?.location?.lng((e) => {
          return e;
        })
      );
      setLat(
        locObject?.geometry?.location?.lat((e) => {
          return e;
        })
      );
    };
    getLngLat();
  }, [locObject]);

  const clientList = clients
    ?.filter((client) => client.approved)
    ?.map((client) => ({
      id: client?._id,
      name: client?.name,
      teamId: client?.team?.id,
      value: {
        merchantId: client?._id,
        merchantName: client?.name,
      },
    }));

  const fleetTypeList = fleetTypes?.map((fleetType) => ({
    id: fleetType?._id,
    name: fleetType?.name,
    value: {
      fleetType: fleetType?.name,
      fleetTypeId: fleetType?._id,
    },
  }));

  const onClientSelect = (selectedList, selectedItem) => {
    setSelectedClient(selectedItem.value);
  };

  const onClientRemove = (selectedList, selectedItem) => {
    setSelectedClient(null);
  };

  const onFleetTypeSelect = (selectedList, selectedItem) => {
    setSelectedFleetType(selectedItem.value);
  };

  const onFleetTypeRemove = (selectedList, selectedItem) => {
    setSelectedFleetType(null);
  };

  const formik = useFormik({
    initialValues: {
      fleetType: "",
      fleetBrand: "",
      regNumber: "",
    },
    onSubmit: (values) => {
      const body = {
        ...selectedFleetType,
        fleetBrand: values.fleetBrand,
        regNumber: values.regNumber,
        locLatitude: lat,
        locLongitude: lng,
        merchant: selectedClient,
      };
      dispatch(addFleet(body)).then((data) => {
        if (data?.payload?.status === 201) {
          toast.success("Fleet created successfully");
          dispatch(getFleets());
          hide();
        }
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
        <div className="relative">
          <div className="flex gap-1 flex-col">
            <p className="font-normal mt-2 text-xs text-midGray">
              Fleet Information
            </p>
            <div className="">
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onFleetTypeSelect}
                onRemove={onFleetTypeRemove}
                options={fleetTypeList}
                value={selectedFleetType}
                placeholder="Fleet Type"
                className="h-[50px] mb-2 pl-2 rounded-lg bg-catSkillWhite text-[12px]"
              />
            </div>
          </div>
        </div>
        <div className="">
          <InputField
            placeholder="Fleet Brand"
            name="fleetBrand"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fleetBrand}
          />
          {formik.touched.fleetBrand && formik.errors.fleetBrand ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.fleetBrand}
            </p>
          ) : null}
        </div>
        <div className="">
          <InputField
            placeholder="Plate Number"
            name="regNumber"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.regNumber}
          />
          {formik.touched.regNumber && formik.errors.regNumber ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.regNumber}
            </p>
          ) : null}
        </div>
        <div className="flex gap-1 flex-col">
          <p className="font-normal mt-2 text-xs text-midGray">
            Client Information
          </p>
          <div className="">
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onClientSelect}
              onRemove={onClientRemove}
              options={clientList}
              value={selectedClient}
              placeholder="Select client"
              className="h-[50px] mb-2 pl-2 rounded-lg bg-catSkillWhite text-[12px]"
            />
          </div>
        </div>
        <div className="">
          <InputField
            placeholder="Fleet location"
            name="address"
            className="!h-[50px]"
            onChange={(e) => {
              setShowPredictions(true);
              setInput(e.target.value);
              getPlacePredictions({ input: e.target.value });
            }}
            loading={isPlacePredictionsLoading.toString()}
            value={input}
          />
          {showPredictions &&
            !isPlacePredictionsLoading &&
            placePredictions.map((item, idx) => (
              <p
                className="text-[12px] mb-2 text-gullGray cursor-pointer"
                key={idx}
                onClick={() => {
                  setInput(item.description);
                  setAddress(item);
                  setShowPredictions(false);
                }}
              >
                {item.description}
              </p>
            ))}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewFleet;
