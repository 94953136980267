import {
  LHIconBus,
  LHIconEdit,
  LHIconFlag,
  LHIconRoundCancel,
  LHIconRoundPause,
  LHIconSharpReport,
  LHIconTaskComplete,
  LHIconUpdateRotation,
} from "assets/svgs";
import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import Card from "shared/Card";
import CancelOrderModal from "./modal/cancelorder";
import { useDispatch } from "react-redux";
import {
  completeOrder,
  getOneOrder,
  getOrderDeliveryCode,
  startOrder,
} from "appstate/features/order/orderActions";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import UpdateOrderAmountModal from "./modal/updateAmount";
import { toast } from "react-toastify";

const Tracking = ({ currentOrder, setCurrentOrder }) => {
  const [isShown, setIsShown] = useState(false);
  const [isUpdateAmountShown, setIsUpdateAmountShown] = useState(false);
  const [deliveryCode, setDeliveryCode] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    currentOrder &&
      dispatch(getOrderDeliveryCode({ id: currentOrder?._id })).then((data) => {
        setDeliveryCode(data?.payload?.data?.data?.deliveryCode);
      });
  }, [dispatch, currentOrder]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const center = {
    lat: currentOrder?.destinationLocation?.coordinates[1],
    lng: currentOrder?.destinationLocation?.coordinates[0],
  };

  return (
    <Card className="!p-3">
      <CancelOrderModal
        currentOrder={currentOrder}
        id={currentOrder?._id}
        isShown={isShown}
        hide={() => setIsShown(false)}
        setCurrentOrder={setCurrentOrder}
      />
      <UpdateOrderAmountModal
        currentOrder={currentOrder}
        id={currentOrder?._id}
        isShown={isUpdateAmountShown}
        hide={() => setIsUpdateAmountShown(false)}
        setCurrentOrder={setCurrentOrder}
      />

      <div className="p-2 bg-pastelGreen rounded-lg flex justify-between gap-2 items-center">
        <div className="flex gap-2 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center rounded-full bg-malachite">
            <LHIconBus className="fill-white" />
          </div>
          <div>
            <p className="text-[11px]">LH Amount</p>
            <p className="text-fiord text-[14px]">NGN {currentOrder?.amount}</p>
          </div>
        </div>
        <LHIconEdit
          className="w-5 h-5 cursor-pointer"
          onClick={() => setIsUpdateAmountShown(true)}
        />
      </div>
      <div className="p-2 bg-barleyWhite rounded-lg flex gap-2 items-center">
        <div className="w-[37px] h-[37px] flex justify-center items-center rounded-full bg-amber">
          <LHIconBus className="fill-white" />
        </div>
        <div>
          <p className="text-[11px] text-gullGray">Payment on Delivery</p>
          <p className="text-fiord text-[14px]">
            NGN {currentOrder?.customerToPay?.orderCost}
          </p>
        </div>
      </div>
      <div className="p-2 bg-pigeonPost rounded-lg flex gap-2 items-center">
        <div className="w-[37px] h-[37px] flex justify-center items-center rounded-full bg-congressBlue">
          <LHIconBus className="fill-white" />
        </div>
        <div>
          <p className="text-[11px] text-gullGray">Delivery Cost</p>
          <p className="text-fiord text-[14px]">
            NGN {currentOrder?.customerToPay?.deliveryCost}
          </p>
        </div>
      </div>

      <div className="pb-3 border-b">
        <div className="flex justify-between my-1">
          <p className="text-[14px] font-semibold text-fiord">Order Tracking</p>
          <p className="text-[14px] font-semibold text-fiord">View Tracking</p>
        </div>
        <div className="w-full h-[400px]">
          {currentOrder?.destinationLocation?.coordinates[1] &&
          currentOrder?.destinationLocation?.coordinates[0] &&
          isLoaded ? (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={center}
              zoom={14}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
            >
              <Marker position={center} />
            </GoogleMap>
          ) : (
            <div className="h-full flex justify-center items-center">
              <p>Invalid Longtitude/Latitude</p>
            </div>
          )}
        </div>
      </div>
      <div className="pb-3 border-b">
        <p className="text-[14px] font-semibold text-fiord">
          Authentication Required
        </p>
        <div className="flex gap-2">
          <p className="text-red-500">No</p>
          <Toggle icons={false} onChange={() => {}} />
          <p className="text-malachite">Yes</p>
        </div>
      </div>
      <div className="pb-3 border-b">
        <p className="text-[14px] font-semibold text-fiord">Order Status</p>
        <div className="flex gap-2">
          <p className="font-extralight text-gullGray">
            {currentOrder?.status?.status}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-3 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center bg-pigeonPost rounded-full">
            <LHIconFlag />
          </div>
          <p
            onClick={() =>
              dispatch(startOrder({ id: currentOrder?._id })).then(() => {
                dispatch(getOneOrder({ id: currentOrder?._id })).then((data) =>
                  setCurrentOrder(data?.payload?.data?.data)
                );
              })
            }
            className="text-[14px] text-fiord cursor-pointer"
          >
            Start order
          </p>
        </div>
        <div className="flex gap-3 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center bg-pigeonPost rounded-full">
            <LHIconUpdateRotation />
          </div>
          <p className="text-[14px] text-fiord cursor-pointer">
            Update location
          </p>
        </div>
        <div className="flex gap-3 justify-between items-center">
          <div className="flex gap-3 items-center">
            <div className="w-[37px] h-[37px] flex justify-center items-center bg-pigeonPost rounded-full">
              <LHIconTaskComplete />
            </div>
            <p
              onClick={() => {
                currentOrder?.status?.status === "Completed" ||
                currentOrder?.status?.status === "Delivered"
                  ? toast.error("Order already completed!")
                  : deliveryCode === "false"
                  ? toast.error(
                      "Order must have started before it can be completed!"
                    )
                  : dispatch(
                      completeOrder({
                        id: currentOrder?._id,
                        deliveryCode: { deliveryCode },
                      })
                    ).then((data) => {
                      if (data?.payload?.status === 200) {
                        toast.success("Order completed!");
                        dispatch(getOneOrder({ id: currentOrder?._id })).then(
                          (data) => setCurrentOrder(data?.payload?.data?.data)
                        );
                      }
                    });
              }}
              className="text-[14px] text-fiord cursor-pointer"
            >
              Complete order
            </p>
          </div>
          {deliveryCode && deliveryCode !== "false" ? (
            <p className="text-[12px] text-fiord">Code: {deliveryCode}</p>
          ) : (
            <p className="text-[12px] text-fiord">Code: Order not started</p>
          )}
        </div>
        <div className="flex gap-3 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center bg-pigeonPost rounded-full">
            <LHIconRoundPause />
          </div>
          <p className="text-[14px] text-fiord cursor-pointer">Pause order</p>
        </div>
        <div className="flex gap-3 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center bg-bridesmaid rounded-full">
            <LHIconSharpReport />
          </div>
          <p className="text-[14px] text-froly cursor-pointer">Report order</p>
        </div>
        <div className="flex gap-3 items-center">
          <div className="w-[37px] h-[37px] flex justify-center items-center bg-bridesmaid rounded-full">
            <LHIconRoundCancel />
          </div>
          <p
            onClick={() => setIsShown(true)}
            className="text-[14px] text-froly cursor-pointer"
          >
            Cancel order
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Tracking;
