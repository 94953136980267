import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect } from "react";
import Header from "shared/Header";
import SingleTeamDetails from "./SingleTeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { getTeamByID } from "appstate/features/teams/teamActions";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getFilterableRiders } from "appstate/features/fleet/fleetActions";

const SingleTeamContents = () => {
  const { id } = useParams();
  const { currentTeam } = useSelector((state) => state.team);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTeamByID({ id })).then((data) => {
      if (data?.payload?.status !== 200) {
        toast.error(`Unable to get Team Details with id ${id}`);
        navigate("/admin/teams");
      }
    });
    dispatch(getFilterableRiders({ page: 1 }));
  }, [dispatch, id, navigate]);

  return (
    <DashboardLayout>
      <Header single btnText="View Orders" />
      <SingleTeamDetails currentTeam={currentTeam} />
    </DashboardLayout>
  );
};

export default SingleTeamContents;
