import { LHNigerianFlag } from "assets/images";
import { useFormik } from "formik";
import Button from "shared/Button";
import InputField from "shared/InputField";
import TextArea from "shared/TextArea";
import ButtonAnimation from "shared/buttonAnimation";
import { addClientValidationSchema } from "validation/client/addClientSchema";

const AddNewClient = ({ hide }) => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      phoneNumber: "",
      email: "",
      address: "",
      additionalInstructions: "",
    },
    validationSchema: addClientValidationSchema,
    onSubmit: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        businessName: values.businessName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        address: values.address,
        additionalInstructions: values.additionalInstructions,
      };
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="w-[45%]">
            <InputField
              placeholder="First Name"
              name="firstName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.firstName}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <InputField
              placeholder="Last Name"
              name="lastName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.lastName}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-[45%]">
            <InputField
              placeholder="Business Name"
              name="businessName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessName}
            />
            {formik.touched.businessName && formik.errors.businessName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.businessName}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <div className="flex items-center gap-2">
              <div className="bg-catSkillWhite px-2 h-[50px] rounded-lg flex gap-1 items-center">
                <img src={LHNigerianFlag} alt="select country" />
                <p className="text-slateGray text-[12px]">+234</p>
              </div>
              <InputField
                placeholder="Phone Number"
                name="phoneNumber"
                className="!h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
            </div>
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.phoneNumber}
              </p>
            ) : null}
          </div>
        </div>
        <div className="">
          <InputField
            placeholder="Customers Email"
            name="email"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.email}
            </p>
          ) : null}
        </div>
        <div className="">
          <InputField
            placeholder="Customers Address"
            name="address"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.address}
            </p>
          ) : null}
        </div>
        <div className="">
          <TextArea
            placeholder="Additional Instructions"
            name="additionalInstructions"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.additionalInstructions}
          />
          {formik.touched.additionalInstructions &&
          formik.errors.additionalInstructions ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.additionalInstructions}
            </p>
          ) : null}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {false ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewClient;
