import Modal from "layout/modal/Modal";
import React from "react";
import AddNewCluster from "./AddNewCluster";

const AddNewClusterModal = ({ isShown, hide, selectedRow, isEditing }) => {
  return (
    <Modal
      isShown={isShown}
      hide={hide}
      title={isEditing ? "Edit Cluster" : "New Cluster"}
    >
      <AddNewCluster
        hide={hide}
        selectedRow={selectedRow}
        isEditing={isEditing}
      />
    </Modal>
  );
};

export default AddNewClusterModal;
