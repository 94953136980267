import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fleetGet,
  riderAssignToFleet,
  ridersGet,
  fleetTypeGet,
  fleetAdd,
  fleetTypeAdd,
  fleetGetByRiderMobile,
  riderRegister,
  riderTeamUpdate,
  ridersGetByTeamId,
  riderAssignToClient,
  multipleRidersTeamUpdate,
  fleetGetByRegNumber,
  filterableRidersGet,
  riderApprove,
  riderDeactivate,
  riderBlacklist,
  fleetActivate,
  fleetDeactivate,
  availableRidersForDeliveryGet,
} from "appstate/api/fleet";

export const getFleets = createAsyncThunk("fleet/getFleets", fleetGet);
export const getFleetType = createAsyncThunk(
  "fleet/getFleetType",
  fleetTypeGet
);
export const assignRiderToFleet = createAsyncThunk(
  "fleet/assignRiderToFleet",
  riderAssignToFleet
);
export const getRidersByTeamId = createAsyncThunk(
  "fleet/getRidersByTeamId",
  ridersGetByTeamId
);
export const getRiders = createAsyncThunk("fleet/getRiders", ridersGet);
export const addFleet = createAsyncThunk("fleet/addFleet", fleetAdd);
export const addFleetType = createAsyncThunk(
  "fleet/addFleetType",
  fleetTypeAdd
);
export const getFleetByRiderMobile = createAsyncThunk(
  "fleet/getFleetByRiderMobile",
  fleetGetByRiderMobile
);
export const registerRider = createAsyncThunk(
  "fleet/registerRider",
  riderRegister
);
export const updateRiderTeam = createAsyncThunk(
  "fleet/updateRiderTeam",
  riderTeamUpdate
);
export const assignRiderToClient = createAsyncThunk(
  "fleet/assignRiderToClient",
  riderAssignToClient
);
export const updateMultipleRidersTeam = createAsyncThunk(
  "fleet/updateMultipleRidersTeam",
  multipleRidersTeamUpdate
);
export const getFleetByRegNumber = createAsyncThunk(
  "fleet/getFleetByRegNumber",
  fleetGetByRegNumber
);
export const getFilterableRiders = createAsyncThunk(
  "fleet/getFilterableRiders",
  filterableRidersGet
);
export const approveRider = createAsyncThunk(
  "fleet/approveRider",
  riderApprove
);
export const deactivateRider = createAsyncThunk(
  "fleet/deactivateRider",
  riderDeactivate
);
export const blacklistRider = createAsyncThunk(
  "fleet/blacklistRider",
  riderBlacklist
);
export const activateFleet = createAsyncThunk(
  "fleet/activateFleet",
  fleetActivate
);
export const deactivateFleet = createAsyncThunk(
  "fleet/deactivateFleet",
  fleetDeactivate
);
export const getAvailableRidersForDelivery = createAsyncThunk(
  "riders/getAvailableRidersForDelivery",
  availableRidersForDeliveryGet
);
