import { LHIconClose } from "assets/svgs";
import PageTitle from "shared/PageTitle";
import AdminModal from "@mui/material/Modal";

const Modal = ({
  divClass,
  isShown,
  hide,
  children,
  className,
  title,
  disableModalTitle,
  ...rest
}) => {
  return (
    <AdminModal open={isShown} onClose={hide}>
      <div
        className={`flex flex-col gap-4 bg-white p-6 rounded-lg !w-[90%] mt-[20%] lg:mt-[5%] mx-auto md:!w-[70%] lg:!w-[45%] !pb-6 !py-4 outline-none focus:outline-none shadow-lg ${
          disableModalTitle && "!p-0"
        } ${divClass}`}
        {...rest}
      >
        {!disableModalTitle && (
          <div className="flex justify-between">
            <PageTitle title={title} />
            <LHIconClose className="cursor-pointer" onClick={hide} />
          </div>
        )}
        <div className={`overflow-y-auto max-h-[500px] ${className}`}>
          {children}
        </div>
      </div>
    </AdminModal>
  );
};

export default Modal;
