import Api from "apiInstance";
import { toast } from "react-toastify";

export const pricingGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: "/rate/pricing",
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pricingAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/rate/pricing",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pricingRuleDelete = async ({ ruleId }) => {
  try {
    const response = await Api({
      method: "delete",
      url: `/rate/pricingRule/${ruleId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pricingRulesGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: "/rate/pricingRules",
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const locationsClustersGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: "/rate/location",
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const locationsAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/rate/location",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const locationsEdit = async (body) => {
  try {
    const response = await Api({
      method: "patch",
      url: "/rate/location",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const locationsDelete = async ({ locationId }) => {
  try {
    const response = await Api({
      method: "delete",
      url: `/rate/location/${locationId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const clustersAdd = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/rate/cluster",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const ClusterEdit = async (body) => {
  try {
    const response = await Api({
      method: "patch",
      url: "/rate/cluster",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const clusterDelete = async ({ clusterId }) => {
  try {
    const response = await Api({
      method: "delete",
      url: `/rate/cluster/${clusterId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
