import Modal from "layout/modal/Modal";
import React from "react";
import AddNewInvoice from "./AddNewInvoice";

const AddNewInvoiceModal = ({ isShown, hide }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Invoice">
      <AddNewInvoice hide={hide} />
    </Modal>
  );
};

export default AddNewInvoiceModal;
