import Modal from "layout/modal/Modal";
import React from "react";
import ChangeInvoiceStatus from "./ChangeInvoiceStatus";

const ChangeInvoiceStatusModal = ({ isShown, hide, invoice }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Change Status">
      <ChangeInvoiceStatus hide={hide} invoice={invoice} />
    </Modal>
  );
};

export default ChangeInvoiceStatusModal;
