import Skeleton from "react-loading-skeleton";
import { HiStatusOnline } from "react-icons/hi";
import { formattedLocalDate } from "utils/formattedTime";
import { RiRecycleFill } from "react-icons/ri";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import OverviewCard from "./OverviewCard";

const BillingOverview = ({ merchantBilling }) => {
  let cycleFrom;
  let cycleTo;

  if (
    formattedLocalDate(merchantBilling?.cycleFrom) !== null &&
    formattedLocalDate(merchantBilling?.cycleTo) !== null
  ) {
    cycleFrom = formattedLocalDate(merchantBilling?.cycleFrom);
    cycleTo = formattedLocalDate(merchantBilling?.cycleTo);
  } else {
    cycleFrom = "";
    cycleTo = "";
  }

  return (
    <div className="mb-6 bg-gray200 p-5 rounded-md">
      <div className="flex flex-col md:flex-row items-center justify-end mb-4">
        {/* <div className="flex items-center gap-3 ">
          <h2 className="text-fiord font-semibold ">Monthly Subscription </h2>{" "}
          <p
            className={`${
              merchantBilling?.active
                ? "text-greenCol bg-[#11E16417]"
                : "text-red bg-lightRed "
            } rounded-lg p-1 px-2`}
          >
            {merchantBilling?.active ? "Active" : "Inactive"}
          </p>
        </div> */}

        {merchantBilling !== null && (
          <div className="bg-greenCol w-[200px] h-[80px] rounded-lg p-3 shadow-sm">
            <h1 className="text-white pb-3">{merchantBilling?.billingType}</h1>

            <h1 className="text-white text-right font-semibold">
              {merchantBilling?.subscriptionDetail?.subscriptionType}
            </h1>
          </div>
        )}
      </div>

      <OverviewCard
        title="Billing Status"
        icon={<HiStatusOnline />}
        isStatus={true}
        bgColor="bg-purpleCol"
        isActive={merchantBilling?.active}
      />

      <div className="flex flex-col md:flex-row gap-3 my-3">
        <OverviewCard
          title="Billing Cycle"
          icon={<RiRecycleFill />}
          bgColor="bg-yellowCol"
          text={`${cycleFrom} - ${cycleTo}`}
        />

        <OverviewCard
          title="Next Billing Cycle"
          icon={<FaMoneyBillTransfer />}
          bgColor="bg-blue"
          text={`${cycleTo}` || "-"}
        />
      </div>
    </div>
  );
};

export default BillingOverview;
