import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import { useDispatch } from "react-redux";
import { getMerchantBilling } from "appstate/features/billing/billingActions";
import Dropdown2 from "shared/Dropdown2";
import BillingOverview from "./BillingOverview";
import PageTitle from "shared/PageTitle";
import SubscriptionDetail from "./SubscriptionDetails";
import { toast } from "react-toastify";

const Billings = ({ currentClient }) => {
  const dispatch = useDispatch();
  const [billingType, setBillingType] = useState(null);
  const [merchantBilling, setMerchantBilling] = useState(null);

  useEffect(() => {
    if (currentClient !== null) {
      dispatch(getMerchantBilling({ id: currentClient?._id })).then((data) => {
        if (data?.payload?.status !== 200) {
          toast.error(
            `Unable to get Merchant Billing Details with id ${currentClient?._id}`
          );
        } else {
          setMerchantBilling(data?.payload?.data?.data);
        }
      });
    }
  }, [dispatch, currentClient?._id]);

  // console.log(merchantBilling);

  return (
    <Card className="!h-fit">
      <div className="flex flex-wrap gap-2 justify-between items-center">
        <PageTitle title={"Billings"} />
        <div className="w-[13.5rem] rounded-xl border-2 border-gallery p-1 px-2">
          <Dropdown2
            items={["Monthly Subscription", "Pay As You Go"]}
            selectedItem={billingType}
            setSelectedItem={setBillingType}
            placeHolder="Change billing type"
          />
        </div>
      </div>
      <div>
        <BillingOverview merchantBilling={merchantBilling} />
        <SubscriptionDetail merchantBilling={merchantBilling} />
      </div>
    </Card>
  );
};

export default Billings;
