import { LHTrackingBike, LHTrackingBus, LHTrackingCar } from "assets/images";
import {
  LHIconClose,
  LHIconDownload,
  LHIconMenuDots,
  LHIconSearch,
  LHIconTaskComplete,
} from "assets/svgs";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { compose, withStateHandlers, withState, withHandlers } from "recompose";
import { useDispatch } from "react-redux";
import Button from "shared/Button";
import Card from "shared/Card";
import InputField from "shared/InputField";
import PageTitle from "shared/PageTitle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getFilterableOrders } from "appstate/features/order/orderActions";

const MainTrackingGrid = ({ setGrid, currentOrders, setCurrentOrders }) => {
  const [activeStatus, setActiveStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeOrder, setActiveOrder] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const icons = {
    bike: {
      icon: LHTrackingBike,
    },
    car: {
      icon: LHTrackingCar,
    },
    bus: {
      icon: LHTrackingBus,
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const center = activeOrder?.currentLocation
    ? {
        lat: activeOrder
          ? activeOrder?.currentLocation?.coordinates[1]
            ? activeOrder?.currentLocation?.coordinates[1]
            : 6.534347899999999
          : 6.534347899999999,
        lng: activeOrder
          ? activeOrder?.currentLocation?.coordinates[0]
            ? activeOrder?.currentLocation?.coordinates[0]
            : 3.3848981
          : 3.3848981,
      }
    : {
        lat: activeOrder
          ? activeOrder?.pickupLocation?.coordinates[1]
            ? activeOrder?.pickupLocation?.coordinates[1]
            : 6.534347899999999
          : 6.534347899999999,
        lng: activeOrder
          ? activeOrder?.pickupLocation?.coordinates[0]
            ? activeOrder?.pickupLocation?.coordinates[0]
            : 3.3848981
          : 3.3848981,
      };

  const MyMapComponent = compose(
    withState("selectedPlace", "updateSelectedPlace", null),
    withState("orderDetails", "setOrderDetails", null),
    withStateHandlers(
      () => ({
        isOpen: false,
        selectedOrder: null,
      }),
      {
        onToggleOpen:
          ({ isOpen }) =>
          () => ({
            isOpen: !isOpen,
          }),
      }
    ),
    withHandlers(() => {
      return {
        setOrder:
          ({ updateSelectedPlace }) =>
          (key) => {
            updateSelectedPlace(key);
          },
        setOrderDetails:
          ({ setOrderDetails }) =>
          (orderDetails) => {
            setOrderDetails(orderDetails);
          },
      };
    })
  )((props) => {
    return (
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={center}
        zoom={activeOrder ? 20 : 11}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {currentOrders?.map((order, idx) => (
          <Marker
            key={idx}
            onClick={() => navigate(`/admin/orders/${order?._id}`)}
            onMouseOver={() => {
              props.onToggleOpen();
              props.setOrder(idx);
              props.setOrderDetails(order);
            }}
            onMouseOut={props.onToggleOpen}
            position={
              order?.currentLocation
                ? {
                    lat: order?.currentLocation?.coordinates[1]
                      ? order?.currentLocation?.coordinates[1]
                      : 6.534347899999999,
                    lng: order?.currentLocation?.coordinates[0]
                      ? order?.currentLocation?.coordinates[0]
                      : 3.3848981,
                  }
                : {
                    lat: order?.pickupLocation?.coordinates[1]
                      ? order?.pickupLocation?.coordinates[1]
                      : 6.534347899999999,
                    lng: order?.pickupLocation?.coordinates[0]
                      ? order?.pickupLocation?.coordinates[0]
                      : 3.3848981,
                  }
            }
            icon={icons.bike.icon}
          >
            {props.isOpen && props.selectedPlace === idx && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <div className="rounded-lg bg-white p-1">
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">Order Id:</p>
                    <p className="text-fiord text-[14px]">
                      {props?.orderDetails?.orderId}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">Order status:</p>
                    <p className="text-fiord text-[14px]">
                      {props?.orderDetails?.status?.status}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">
                      {props?.orderDetails?.currentAddress
                        ? `Current Location: ${props?.orderDetails?.currentAddress}`
                        : `Pickup Location: ${props?.orderDetails?.pickupAddress}`}
                    </p>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(
        getFilterableOrders({
          page: 1,
          searchTerm,
        })
      ).then((data) => {
        setCurrentOrders(data?.payload?.data);
      });
    }
  };

  return (
    <div className="flex gap-1 max-[1100px]:flex-col">
      <Card className="!w-1/3 max-[1100px]:!w-full max-[1100px]:!h-full min-[1100px]:h-[calc(100vh-40px)] overflow-y-auto">
        <PageTitle title="Tracking" className="mb-4" />
        <div>
          <div className="mb-8">
            <div className="relative mb-2">
              <InputField
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Search for an order"
                type="text"
                name="search"
                className="!pl-10 !h-[45px] w-full !bg-catSkillWhite"
              />
              <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
            </div>
            <div className="flex gap-2 justify-between overflow-x-auto">
              {[
                { title: "All", value: "" },
                { title: "Pending", value: "Pending" },
                { title: "Assigned", value: "Assigned" },
                { title: "In transit", value: "Transit" },
              ].map((status, idx) => (
                <div
                  onClick={() => {
                    setActiveStatus(idx);
                    status.value === "Canceled"
                      ? dispatch(
                          getFilterableOrders({
                            page: 1,
                            canceled: true,
                          })
                        ).then((data) => {
                          setCurrentOrders(data?.payload?.data);
                        })
                      : dispatch(
                          getFilterableOrders({
                            page: 1,
                            status: status.value,
                          })
                        ).then((data) => {
                          setCurrentOrders(data?.payload?.data);
                        });
                  }}
                  key={idx}
                  className={`cursor-pointer flex gap-2 items-center rounded-lg py-1 px-1 ${
                    activeStatus === idx
                      ? "bg-congressBlue text-white"
                      : "bg-catSkillWhite"
                  }`}
                >
                  <p className="text-[12px] whitespace-nowrap">
                    {status.title}
                  </p>
                  <LHIconClose
                    className={`w-4 h-4 ${
                      activeStatus === idx && "fill-white stroke-white"
                    }`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="">
            {currentOrders?.map((order) => (
              <div
                key={order?._id}
                onClick={() => {
                  if (activeOrder?._id === order?._id) {
                    setActiveOrder(null);
                  } else {
                    setActiveOrder(order);
                  }
                }}
                className={`cursor-pointer border border-catSkillWhite px-4 py-2 rounded-lg mb-4 ${
                  activeOrder?._id === order?._id &&
                  "bg-congressBlue text-white"
                }`}
              >
                <div>
                  <div className="flex justify-between mb-2">
                    <p className="text-[12px]">Order ID: {order?.orderId}</p>
                    <div className="flex gap-4 items-center">
                      <p
                        className={`text-[12px] ${
                          activeOrder?._id === order?._id && "text-sunGlow"
                        }`}
                      >
                        {order?.status?.status}
                      </p>
                      <LHIconMenuDots />
                    </div>
                  </div>
                  <div className="bg-catSkillWhite w-full mb-4">
                    <div
                      className={`${
                        activeOrder?._id === order?._id
                          ? "bg-malachite"
                          : "bg-congressBlue"
                      } w-1/2 h-[5px]`}
                    ></div>
                  </div>
                  <div className="flex flex-col gap-6">
                    {order?.statusHistory?.map((statusHistory, idx) => (
                      <div key={idx} className="flex justify-between gap-2">
                        <div className="flex items-center gap-2">
                          <p className="text-[12px]">
                            {statusHistory?.date?.split("T")[0]}
                          </p>
                          <div className="relative">
                            <div
                              className={`w-[10px] h-[10px] rounded-full ${
                                activeOrder?._id === order?._id
                                  ? "bg-white"
                                  : "bg-congressBlue"
                              }`}
                            ></div>
                            <div
                              className={`${
                                idx === order?.statusHistory?.length - 1 &&
                                "hidden"
                              } absolute top-0 left-[5px] h-[45px] border-l ${
                                activeOrder?._id === order?._id
                                  ? "border-white"
                                  : "border-congressBlue"
                              }`}
                            ></div>
                          </div>
                          <p className="text-[12px]">{statusHistory?.status}</p>
                        </div>
                        <div>
                          <p className="text-[12px]">
                            {statusHistory?.date?.split("T")[1].split(".")[0]}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <Card className="!w-3/4 max-[1100px]:!w-full max-[1100px]:!h-full min-[1100px]:!max-h-[calc(100vh-40px)]">
        <div className="flex justify-between items-center py-2">
          <p className="text-fiord text-[20px] font-semibold">Map</p>
          <div className="bg-catSkillWhite px-2 py-1 rounded-lg flex items-center gap-2">
            <LHIconDownload />
            <p className="text-congressBlue text-[15px]">Documents</p>
          </div>
        </div>
        <div className="w-full h-full rounded-lg relative">
          {isLoaded && <MyMapComponent />}
          <div className="absolute top-2 left-4 flex gap-4 justify-between">
            <Button onClick={() => setGrid(false)}>
              <div className="flex gap-1 items-center">
                <LHIconTaskComplete />
                <p>Map</p>
              </div>
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MainTrackingGrid;
