import { LHIconEdit } from "assets/svgs";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import Table from "shared/Table";
import { useDispatch } from "react-redux";
import { getApprovedRateAdmin } from "appstate/features/client/clientActions";
import ApprovedRateModal from "./modal/addNewRate";
import Button from "shared/Button";

const ApprovedRates = ({ currentClient }) => {
  const [isShown, setIsShown] = useState(false);
  const [approvedRates, setApprovedRates] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentApprovedRate, setCurrentApprovedRate] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    currentClient?._id &&
      dispatch(
        getApprovedRateAdmin({
          merchantId: currentClient?._id,
          page: 1,
        })
      ).then((data) => {
        if (data?.payload?.status === 201) {
          setApprovedRates(data?.payload?.data);
        }
      });
  }, [dispatch, currentClient?._id]);

  const ratesColumns = [
    {
      Header: "Location",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.locations[0]?.address}</p>
      ),
    },
    {
      Header: "Rate",
      accessor: (d) => <p className="flex justify-start ml-4">NGN{d.rate}</p>,
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.active ? (
          <div className="flex justify-start gap-1 ml-4">
            {d.approved ? (
              <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
                Approved
              </div>
            ) : (
              <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2">
                Unapproved
              </div>
            )}
            <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
              Active
            </div>
          </div>
        ) : (
          <div className="flex justify-start gap-1 ml-4">
            {d.approved ? (
              <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
                Approved
              </div>
            ) : (
              <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2">
                Unapproved
              </div>
            )}
            <div className="bg-varden text-koromiko text-[12px] rounded-lg py-1 px-2">
              Inactive
            </div>
          </div>
        ),
    },
    {
      Header: " ",
      accessor: (d) => (
        <LHIconEdit
          onClick={() => {
            setIsEditing(true);
            setIsShown(true);
            setCurrentApprovedRate(d);
          }}
          className="cursor-pointer w-4 h-4"
        />
      ),
    },
  ];

  return (
    <Card className="!h-fit">
      <ApprovedRateModal
        currentClient={currentClient}
        setApprovedRates={setApprovedRates}
        isShown={isShown}
        isEditing={isEditing}
        currentApprovedRate={currentApprovedRate}
        hide={() => {
          setIsShown(false);
          setIsEditing(false);
        }}
      />
      <div className="flex flex-wrap gap-2 justify-between items-center">
        <p className="text-[18px] font-medium">Approved Rate(s)</p>
        <Button
          onClick={() => setIsShown(true)}
          className="!h-[40px]"
          type="button"
          blue
        >
          Add New Rate
        </Button>
      </div>
      <div>
        <Table
          columns={ratesColumns}
          data={approvedRates}
          type="You don’t have any assigned user. Create a new user to get started"
        />
      </div>
    </Card>
  );
};

export default ApprovedRates;
