import { useState, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Dropdown = ({ selectedItem, setSelectedItem, items, isObj }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, () => setIsOpen(false));

  // const handleSelection = (item) => {
  //   setSelectedItem(item);
  //   setIsOpen(false);
  // };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // console.log(defaultValue);

  return (
    <div ref={ref} className={` w-full relative  z-30`}>
      <button
        type="button"
        onClick={toggleDropdown}
        className="w-full inline-flex justify-between items-center rounded-lg outline-none text-sm "
      >
        <div className="flex items-center">
          <span className="text-sm">{selectedItem}</span>
        </div>

        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>

      {isOpen && items?.length > 0 && (
        <div className="absolute h-auto overflow-hidden z-[999999] mt-1 bg-white shadow-2xl rounded-xl w-full ">
          <ul
            tabIndex="-1"
            role="menu"
            aria-labelledby="menu-options"
            className="pt-1 pb-2"
          >
            {items.map((item, index) => {
              // console.log(item);
              return (
                <li
                  onClick={() => {
                    setSelectedItem(isObj ? item : item.value);
                    setIsOpen(false);
                  }}
                  className=" text-left p-3 text-sm text-fiord hover:bg-[#F4F4F4] mx-3 rounded-lg"
                  role="menuitem"
                  key={index}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
