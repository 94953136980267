import Modal from "layout/modal/Modal";
import React from "react";
import AddNewClient from "./AddNewClient";

const AddNewClientModal = ({ isShown, hide }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Client">
      <AddNewClient hide={hide} />
    </Modal>
  );
};

export default AddNewClientModal;
