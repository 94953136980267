import { ordersBreakDown } from "appstate/features/analytics/analyticsActions";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import Card from "shared/Card";

const Chart = ({ currentRider }) => {
  const [analyticsObj, setAnalyticsObj] = useState([]);
  const [chartType, setChartType] = useState("This year");
  const [deliveryType, setDeliveryTape] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    currentRider?.user?.authId &&
      dispatch(
        ordersBreakDown({ riderAuthId: currentRider?.user?.authId })
      ).then((data) => {
        setAnalyticsObj(data?.payload?.data?.data);
      });
  }, [dispatch, currentRider?.user?.authId]);

  const monthNames = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const groupedData =
    deliveryType === 1
      ? analyticsObj?.reduce((result, item) => {
          const month = monthNames[item?._id];
          result[month] = (result[month] || 0) + item?.onTimeDeliveries;
          return result;
        }, {})
      : deliveryType === 2
      ? analyticsObj?.reduce((result, item) => {
          const month = monthNames[item?._id];
          result[month] = (result[month] || 0) + item?.totalCancelledDeliveries;
          return result;
        }, {})
      : analyticsObj?.reduce((result, item) => {
          const month = monthNames[item?._id];
          result[month] = (result[month] || 0) + item?.totalCompletedDeliveries;
          return result;
        }, {});

  const month = new Date().toLocaleString("default", { month: "short" });
  const sortedData = analyticsObj?.sort((a, b) => a._id - b._id);

  const resultData =
    deliveryType === 1
      ? chartType === "This year"
        ? Array.from({ length: 12 }, (_, i) => ({
            month: i + 1,
            onTimeDeliveries: groupedData[monthNames[i + 1]] || 0,
          }))
        : chartType === "This month"
        ? Array.from({ length: 31 }, (_, index) => {
            const day = index + 1;
            const dayData = sortedData?.find((item) => item._id === day);

            const onTimeDeliveries = dayData?.onTimeDeliveries
              ? dayData?.onTimeDeliveries
              : 0;

            return { day: month + " " + day, onTimeDeliveries };
          })
        : chartType === "Last 7 days"
        ? Array.from({ length: 7 }, (_, index) => {
            const day = index + 1;
            const dayData = sortedData?.find((item) => item?._id === day);

            const onTimeDeliveries = dayData?.onTimeDeliveries
              ? dayData?.onTimeDeliveries
              : 0;

            return { day: day, onTimeDeliveries };
          })
        : []
      : deliveryType === 2
      ? chartType === "This year"
        ? Array.from({ length: 12 }, (_, i) => ({
            month: i + 1,
            totalCancelledDeliveries: groupedData[monthNames[i + 1]] || 0,
          }))
        : chartType === "This month"
        ? Array.from({ length: 31 }, (_, index) => {
            const day = index + 1;
            const dayData = sortedData.find((item) => item._id === day);

            const totalCancelledDeliveries = dayData?.totalCancelledDeliveries
              ? dayData?.totalCancelledDeliveries
              : 0;

            return { day: month + " " + day, totalCancelledDeliveries };
          })
        : chartType === "Last 7 days"
        ? Array.from({ length: 7 }, (_, index) => {
            const day = index + 1;
            const dayData = sortedData?.find((item) => item?._id === day);

            const totalCancelledDeliveries = dayData?.totalCancelledDeliveries
              ? dayData?.totalCancelledDeliveries
              : 0;

            return { day: day, totalCancelledDeliveries };
          })
        : []
      : chartType === "This year"
      ? Array.from({ length: 12 }, (_, i) => ({
          month: i + 1,
          totalCompletedDeliveries: groupedData[monthNames[i + 1]] || 0,
        }))
      : chartType === "This month"
      ? Array.from({ length: 31 }, (_, index) => {
          const day = index + 1;
          const dayData = sortedData.find((item) => item._id === day);

          const totalCompletedDeliveries = dayData?.totalCompletedDeliveries
            ? dayData?.totalCompletedDeliveries
            : 0;

          return { day: month + " " + day, totalCompletedDeliveries };
        })
      : chartType === "Last 7 days"
      ? Array.from({ length: 7 }, (_, index) => {
          const day = index + 1;
          const dayData = sortedData?.find((item) => item?._id === day);

          const totalCompletedDeliveries = dayData?.totalCompletedDeliveries
            ? dayData?.totalCompletedDeliveries
            : 0;

          return { day: day, totalCompletedDeliveries };
        })
      : [];

  const categories =
    chartType === "This year"
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ]
      : chartType === "This month"
      ? resultData?.map((item) => item?.day)
      : chartType === "Last 7 days"
      ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      : [];

  const chartOptions = {
    series: [
      {
        name:
          deliveryType === 1
            ? "On Time Deliveries"
            : deliveryType === 2
            ? "Cancelled Deliveries"
            : "Successful Deliveries",
        data:
          deliveryType === 1
            ? resultData?.map((item) => item?.onTimeDeliveries)
            : deliveryType === 2
            ? resultData?.map((item) => item?.totalCancelledDeliveries)
            : resultData?.map((item) => item?.totalCompletedDeliveries),
      },
    ],
    options: {
      chart: {
        id: "column-chart",
      },
      plotOptions: {
        bar: {
          distributed: true,
          barHeight: "85%",
        },
      },
      grid: {
        show: false,
      },
      colors: ["#B5E4CA", "#FFBC99"],
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: 4,
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <Card>
      <div className="flex max-[600px]:flex-wrap gap-2 justify-between items-center">
        <div className="border max-[600px]:w-full rounded-lg px-2">
          <select
            name="type"
            onChange={(e) => {
              setDeliveryTape(Number(e.target.value));
            }}
            className="border-l-8 max-[600px]:w-full border-[#35C778] outline-none bg-white my-2"
          >
            <option value={0}>Successful Deliveries</option>
            <option value={1}>On Time Deliveries</option>
            <option value={2}>Failed Deliveries</option>
          </select>
        </div>

        <div className="border max-[600px]:w-full rounded-lg px-2 text-[14px] text-paleSky">
          <select
            id="period"
            onChange={(e) => {
              if (e.target.value === "Last 7 days") {
                dispatch(
                  ordersBreakDown({
                    riderAuthId: currentRider?.user?.authId,
                    last7Days: true,
                  })
                ).then((data) => {
                  setAnalyticsObj(data?.payload?.data?.data);
                });
              } else if (e.target.value === "This month") {
                dispatch(
                  ordersBreakDown({
                    riderAuthId: currentRider?.user?.authId,
                    forMonth: true,
                    month: new Date().getMonth(),
                  })
                ).then((data) => {
                  setAnalyticsObj(data?.payload?.data?.data);
                });
              } else {
                dispatch(
                  ordersBreakDown({ riderAuthId: currentRider?.user?.authId })
                ).then((data) => {
                  setAnalyticsObj(data?.payload?.data?.data);
                });
              }
              setChartType(e.target.value);
            }}
            className="border-none max-[600px]:w-full outline-none bg-white my-2"
          >
            <option value="This year">This year</option>
            <option value="Last 7 days">Last 7 days</option>
            <option value="This month">This month</option>
          </select>
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        height={350}
      />
    </Card>
  );
};

export default Chart;
