import { LHlogo } from "assets/images";

const SplashScreen = () => {
  return (
    <div className="h-full w-full absolute top-0 left-0 bg-white">
      <div className="px-8 flex justify-center items-center h-full w-full">
        <img src={LHlogo} alt="Logistics Logo" />
      </div>
    </div>
  );
};

export default SplashScreen;
