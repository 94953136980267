import { getFilterableOrders } from "appstate/features/order/orderActions";
import {
  setFilterStatus,
  setFilteredClient,
} from "appstate/features/order/orderSlice";
import { LHIconUserAdd } from "assets/svgs";
import AssignRiderToFleetModal from "features/fleet/modal/assignRiderToFleet";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Dropdown from "shared/Dropdown";
import Skeleton from "react-loading-skeleton";
import Table from "shared/Table";
import TableControl from "shared/TableControl";
import { MdMoreVert } from "react-icons/md";
import { BiFilterAlt, BiSearch } from "react-icons/bi";
import { MdOutlineStorefront } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const filterItems = [
  { name: "Status [All]", value: "" },
  { name: "Arrived", value: "ArrivedPickup" },
  { name: "Pending", value: "Pending" },
  { name: "Assigned", value: "Assigned" },
  { name: "Transit", value: "Transit" },
  { name: "Cancelled", value: "Cancelled" },
  { name: "Delivered", value: "Delivered" },
];

const Details = ({
  currentOrders,
  setCurrentOrders,
  currentPage,
  isLoading,
  limit,
  total,
  startDate,
  endDate,
  todayStatus,
}) => {
  const navigate = useNavigate();

  const { clients } = useSelector((state) => state.client);
  const { filterStatus, filteredClient } = useSelector((state) => state.order);

  const [showControls, setShowControls] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShown, setIsShown] = useState(false);

  const [currentStatus, setCurrentStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(
        getFilterableOrders({
          page: 1,
          searchTerm,
          startDate,
          endDate,
        })
      ).then((data) => {
        setCurrentOrders(data?.payload?.data);
      });
    }
  };

  const controls = [
    {
      text: "Edit Order",
      action: () => {},
    },
    {
      text: "Delete Order",
      action: () => {},
    },
  ];

  // console.log(filteredClient);
  // console.log(filterStatus);

  const handleFilterStatus = (item) => {
    setCurrentStatus(item);
    if (filteredClient) {
      item === "Canceled"
        ? dispatch(
            getFilterableOrders({
              page: 1,
              merchantId: filteredClient?._id,
              canceled: true,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          })
        : dispatch(
            getFilterableOrders({
              page: 1,
              merchantId: filteredClient?._id,
              status: item,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          });
    } else {
      item === "Canceled"
        ? dispatch(
            getFilterableOrders({
              page: 1,
              canceled: true,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          })
        : dispatch(
            getFilterableOrders({
              page: 1,
              status: item,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          });
    }
    dispatch(setFilterStatus(item));
  };

  const handleOrderMerchantFilter = (merchant) => {
    if (filterStatus) {
      merchant !== null
        ? dispatch(
            getFilterableOrders({
              page: 1,
              merchantId: merchant?._id,
              startDate,
              endDate,
              status: filterStatus,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          })
        : dispatch(
            getFilterableOrders({
              page: 1,
              startDate,
              endDate,
              status: filterStatus,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          });
    } else {
      merchant !== null
        ? dispatch(
            getFilterableOrders({
              page: 1,
              merchantId: merchant?._id,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          })
        : dispatch(
            getFilterableOrders({
              page: 1,
              startDate,
              endDate,
              today: todayStatus,
            })
          ).then((data) => {
            setCurrentOrders(data?.payload?.data);
          });
    }
    dispatch(setFilteredClient(merchant));
  };

  const fetchNextPage = useCallback(() => {
    dispatch(
      getFilterableOrders({
        page: currentPage + 1,
        status: currentStatus ? currentStatus : "",
      })
    ).then((data) => {
      // console.log(data);
      setCurrentOrders(data?.payload?.data);
    });
  }, [currentPage, dispatch, currentStatus, setCurrentOrders]);

  const fetchPrevPage = useCallback(() => {
    dispatch(
      getFilterableOrders({
        page: currentPage - 1,
        status: currentStatus ? currentStatus : "",
      })
    ).then((data) => {
      setCurrentOrders(data?.payload?.data);
    });
  }, [currentPage, dispatch, currentStatus, setCurrentOrders]);

  const orderColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Order ID",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4"
          to={`/admin/orders/${d._id}`}
        >
          {d.orderId}
        </Link>
      ),
    },
    {
      Header: "Merchant Name",
      accessor: (d) => (
        <Link
          to={`/admin/orders/${d._id}`}
          className="flex justify-start ml-4 capitalize"
        >
          <p>{d.merchant?.merchantName ? d.merchant?.merchantName : ""}</p>
        </Link>
      ),
    },
    {
      Header: "Recipient",
      accessor: (d) => (
        <Link to={`/admin/orders/${d._id}`} className="flex justify-start ml-4">
          <div>
            +{d.recipient?.mobile} <br></br>{" "}
            <strong>{d.recipient?.name}</strong>
          </div>
        </Link>
      ),
    },
    {
      Header: "Delivery Address",
      accessor: (d) => (
        <Link
          to={`/admin/orders/${d._id}`}
          className="w-[10rem] flex justify-start ml-4"
        >
          <p>{d.destinationAddress ? d.destinationAddress : "N/A"}</p>
        </Link>
      ),
    },
    {
      Header: "Rider",
      accessor: (d) => (
        <div
          onClick={() => {
            setSelectedRow(d);
            setIsShown(true);
          }}
          className="flex justify-start ml-4 capitalize hover:underline cursor-pointer"
        >
          <p>
            {d.assignedRider?.firstName ? (
              `${d.assignedRider?.firstName} ${d.assignedRider?.lastName}`
            ) : (
              <LHIconUserAdd className="w-4 h-4" />
            )}
          </p>
        </div>
      ),
    },
    {
      Header: "Pickup Store",
      accessor: (d) => (
        <Link
          to={`/admin/orders/${d._id}`}
          className="w-[10rem] flex justify-start ml-4"
        >
          <p>{d.pickupAddress ? d.pickupAddress : "N/A"}</p>
        </Link>
      ),
    },
    {
      Header: "Completed Time",
      accessor: (d) => (
        <Link to={`/admin/orders/${d._id}`} className="flex justify-start ml-4">
          {d?.status?.status === "Delivered" ? (
            <div className="flex items-center gap-1 whitespace-nowrap">
              <p>
                {d?.status?.date?.split("T")[0].split("-").reverse().join("-")}
              </p>
              <p>{d?.status?.date?.split("T")[1]?.split(".")[0]}</p>
            </div>
          ) : (
            <p>Not Completed</p>
          )}
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: (d) =>
        d.twoWays ? (
          <Link to={`/admin/orders/${d._id}`}>
            <p className="flex justify-start ml-4 w-fit p-2 rounded-lg bg-gallery">
              P
            </p>
          </Link>
        ) : (
          <Link to={`/admin/orders/${d._id}`}>
            <p className="flex justify-start ml-4 w-fit p-2 rounded-lg bg-gallery">
              D
            </p>
          </Link>
        ),
    },
    {
      Header: "Status",
      accessor: (d) => (
        <div
          onClick={() => navigate(`/admin/orders/${d._id}`)}
          className={`p-1 rounded-md text-center bg-opacity-10 ${
            d.status?.status === "ArrivedPickup" &&
            "text-[#2A85FF] bg-[#2A85FF]"
          } ${d.status?.status === "Assigned" && "text-[#8E59FF] bg-[#8E59FF]"}
          ${d.status?.status === "Arrived" && "text-[#006769] bg-[#006769]"}
          ${d.status?.status === "Pending" && "text-[#FFBE07] bg-[#FFBE07]"} ${
            d.status?.status === "Delivered" && "text-[#08C15B] bg-[#08C15B]"
          } ${
            d.status?.status === "Transit" && "text-[#a208c1] bg-[#a208c1]"
          } ${
            d.status?.status === "Cancelled" && "text-[#c10808] bg-[#c10808]"
          }`}
        >
          {d.status?.status}
        </div>
      ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <MdMoreVert
            size={25}
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <AssignRiderToFleetModal
        order={selectedRow}
        reassign
        isShown={isShown}
        hide={() => {
          setIsShown(false);
          setSelectedRow(null);
        }}
        setCurrentOrder={setSelectedRow}
        setCurrentOrders={setCurrentOrders}
      />
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Search for order"
            type="text"
            name="search"
            className="!pl-10 !h-[40px] w-full md:w-[350px] !bg-catSkillWhite "
          />
          <BiSearch className="absolute bottom-[9px] left-2 w-5 h-5" />
        </div>

        <div className="flex gap-2 items-center ">
          <div className="w-[13rem] relative flex items-center gap-2 text-sm py-[8px] px-[12px] cursor-pointer rounded-xl bg-white text-[#6F767E] border border-[#EFEFEF]">
            <MdOutlineStorefront size={16} />
            <Dropdown
              isObj={true}
              items={clients?.filter((client) => client.approved)}
              selectedItem={filteredClient?.name || "Merchant [All]"}
              setSelectedItem={(item) => handleOrderMerchantFilter(item)}
            />
          </div>

          <div className="w-[11rem] relative flex items-center gap-2 text-sm py-[8px] px-[12px] cursor-pointer rounded-xl bg-white text-[#6F767E] border border-[#EFEFEF]">
            <BiFilterAlt size={16} />
            <Dropdown
              items={filterItems}
              selectedItem={filterStatus || "Status [All]"}
              setSelectedItem={(item) => handleFilterStatus(item)}
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Skeleton height={40} count={10} />
        ) : (
          <Table
            columns={orderColumns}
            data={currentOrders}
            type="You don’t have any orders. Add a new order to get started"
            limit={limit}
            total={total}
            currentPage={currentPage}
            fetchNextPage={fetchNextPage}
            fetchPrevPage={fetchPrevPage}
            disabledPrevBtn={currentPage === 1 ? true : false}
          />
        )}
      </div>
    </Card>
  );
};

export default Details;

{
  /* <div className="flex flex-wrap gap-4 items-center">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-md flex justify-center items-center">
            <p className="text-xs">Delete</p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-md flex justify-center items-center">
            <LHIconFilter />
          </div>
          <div className="w-fit">
            <InputSelect
              admin
              datas={clients?.filter((client) => client.approved)}
              placeholder="Merchant"
              name="merchant"
              divClass="border-2 border-gullGray rounded-lg w-[200px]"
              className="!bg-white"
              selected={clients?.filter(
                (client) => client?._id === filteredClient
              )}
              onChange={(e) => {
                if (filterStatus) {
                  e.target.value
                    ? dispatch(
                        getFilterableOrders({
                          page: 1,
                          merchantId: e.target.value,
                          startDate,
                          endDate,
                          status: filterStatus,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      })
                    : dispatch(
                        getFilterableOrders({
                          page: 1,
                          startDate,
                          endDate,
                          status: filterStatus,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      });
                } else {
                  e.target.value
                    ? dispatch(
                        getFilterableOrders({
                          page: 1,
                          merchantId: e.target.value,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      })
                    : dispatch(
                        getFilterableOrders({
                          page: 1,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      });
                }
                dispatch(setFilteredClient(e.target.value));
              }}
            />
          </div>
          <div className="">
            <InputSelect
              admin
              datas={[
                {
                  name: "Pending",
                  value: "Pending",
                },
                {
                  name: "Assigned",
                  value: "Assigned",
                },
                {
                  name: "Transit",
                  value: "Transit",
                },
                {
                  name: "Delivered",
                  value: "Delivered",
                },
                {
                  name: "Cancelled",
                  value: "Canceled",
                },
              ]}
              placeholder="Status"
              selected={[
                {
                  name: "Pending",
                  value: "Pending",
                },
                {
                  name: "Assigned",
                  value: "Assigned",
                },
                {
                  name: "Transit",
                  value: "Transit",
                },
                {
                  name: "Delivered",
                  value: "Delivered",
                },
                {
                  name: "Cancelled",
                  value: "Canceled",
                },
              ].filter((status) => status?.value === filterStatus)}
              name="status"
              divClass="border-2 border-gullGray rounded-lg"
              className=" !bg-white"
              onChange={(e) => {
                setCurrentStatus(e.target.value);
                if (filteredClient) {
                  e.target.value === "Canceled"
                    ? dispatch(
                        getFilterableOrders({
                          page: 1,
                          merchantId: filteredClient,
                          canceled: true,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      })
                    : dispatch(
                        getFilterableOrders({
                          page: 1,
                          merchantId: filteredClient,
                          status: e.target.value,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      });
                } else {
                  e.target.value === "Canceled"
                    ? dispatch(
                        getFilterableOrders({
                          page: 1,
                          canceled: true,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      })
                    : dispatch(
                        getFilterableOrders({
                          page: 1,
                          status: e.target.value,
                          startDate,
                          endDate,
                          today: todayStatus,
                        })
                      ).then((data) => {
                        setCurrentOrders(data?.payload?.data);
                      });
                }
                dispatch(setFilterStatus(e.target.value));
              }}
            />
          </div>
        </div> */
}
