import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import SingleRiderDetails from "./SingleRiderDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserById } from "appstate/features/auth/authActions";
import { toast } from "react-toastify";
import { getOrderAnalytics } from "appstate/features/order/orderActions";

const SingleRiderContents = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentRider, setCurrentRider] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);

  useEffect(() => {
    currentRider?.user?.authId &&
      dispatch(
        getOrderAnalytics({ riderAuthId: currentRider?.user?.authId })
      ).then((data) => {
        if (data?.payload?.status === 201) {
          setTotalOrders(data?.payload?.data?.totalOrders);
          setActiveOrders(data?.payload?.data?.activeOrders);
          setCompletedOrders(data?.payload?.data?.completedOrders);
          setPendingOrders(data?.payload?.data?.pendingOrders);
        }
      });
  }, [dispatch, currentRider?.user?.authId]);

  useEffect(() => {
    dispatch(getUserById({ id })).then((data) => {
      if (data?.payload?.status !== 200) {
        toast.error(`Unable to get Rider Details with id ${id}`);
        navigate("/admin/riders");
        return;
      }
      setCurrentRider(data?.payload?.data?.data);
    });
  }, [dispatch, id, navigate]);

  return (
    <DashboardLayout>
      <Header
        single
        info1="Total Orders"
        info2="Active Orders"
        info3="Completed Orders"
        info4="Pending Orders"
        amount={`${totalOrders}`}
        activeAmount={`${activeOrders}`}
        inactiveAmount={`${completedOrders}`}
        deliveredAmount={`${pendingOrders}`}
        btnText="View Orders"
      />
      <SingleRiderDetails
        setCurrentRider={setCurrentRider}
        currentRider={currentRider}
      />
    </DashboardLayout>
  );
};

export default SingleRiderContents;
