import React from "react";
import Card from "shared/Card";

const Header = ({ singleInvoice }) => {
  return (
    <Card className={"!mb-6"}>
      <div className="flex flex-wrap gap-6 justify-between items-center">
        <div>
          <p className="text-fiord text-[20px] max-md:text-[14px] font-semibold mb-2">
            Billing to
          </p>
          <p className="text-fiord text-[20px] max-md:text-[14px] font-semibold capitalize">
            {singleInvoice?.merchant?.merchantName}
          </p>
          <p className="text-fiord text-[16px] max-md:text-[12px] font-semibold capitalize">
            {singleInvoice?.merchantOutlet?.outletName}
          </p>
          <p className="text-fiord text-[14px] max-md:text-[12px]">
            {singleInvoice?.billingAddress}
          </p>
        </div>
        <div>
          <p className="text-fiord text-[40px] max-md:text-[22px] font-bold">
            INVOICE
          </p>
          <p className="text-fiord text-[14px] max-md:text-[12px]">
            Invoice {singleInvoice?.invoiceId}
          </p>
          <p className="text-fiord text-[14px] max-md:text-[12px]">
            Issued on{" "}
            {singleInvoice?.status?.date?.split("T")[0]?.split("-")?.join("/")}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Header;
