import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  breakdownOrders,
  metricsOrders,
  reportMerchantDownload,
} from "appstate/api/analytics";

export const ordersBreakDown = createAsyncThunk(
  "analytics/ordersBreakDown",
  breakdownOrders
);
export const ordersMetrics = createAsyncThunk(
  "analytics/ordersMetrics",
  metricsOrders
);
export const downloadMerchantReport = createAsyncThunk(
  "analytics/downloadMerchantReport",
  reportMerchantDownload
);
