import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import ReportHeader from "./ReportHeader";
import DownloadReport from "./DownloadReport";
import { useDispatch } from "react-redux";
import { getClients } from "appstate/features/client/clientActions";

const ReportContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch]);

  const reportOptions = [
    {
      _id: 0,
      name: "Delivery",
      value: "Delivery",
      selectOptions: [
        { name: "Completed Deliveries", value: "completed" },
        { name: "Active Deliveries", value: "active" },
        { name: "Pending Deliveries", value: "pending" },
      ],
    },
    // {
    //   _id: 1,
    //   name: "Fleet",
    //   value: "Fleet",
    //   selectOptions: [
    //     { name: "Active Fleet", value: "Active Fleet" },
    //     { name: "Inactive Fleet", value: "Inactive Fleet" },
    //     { name: "Faulty Fleet", value: "Faulty Fleet" },
    //   ],
    // },
    // {
    //   _id: 2,
    //   name: "Invoice",
    //   value: "Invoice",
    //   selectOptions: [
    //     { name: "All Invoice", value: "All Invoice" },
    //     { name: "Paid Invoice", value: "Paid Invoice" },
    //     { name: "Outstanding Fleet", value: "Outstanding Fleet" },
    //   ],
    // },
    // {
    //   _id: 3,
    //   name: "Client",
    //   value: "Client",
    // },
  ];

  const [reportMode, setReportMode] = useState(0);

  return (
    <DashboardLayout>
      <ReportHeader
        reportOptions={reportOptions}
        setReportMode={setReportMode}
      />
      <DownloadReport reportMode={reportMode} reportOptions={reportOptions} />
    </DashboardLayout>
  );
};

export default ReportContent;
