import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  manyOrdersGet,
  oneOrdersGet,
  orderCancel,
  orderStart,
  orderComplete,
  orderReassign,
  orderCreateDelivery,
  orderCreateDeliveryPickup,
  filterableOrdersGet,
  orderAmountUpdate,
  proofOfDeliveryUpload,
  analyticsOrdersGet,
  orderDeliveryCodeGet,
  reportAdminDownload,
} from "appstate/api/order";

export const getManyOrders = createAsyncThunk(
  "orders/getManyOrders",
  manyOrdersGet
);
export const getFilterableOrders = createAsyncThunk(
  "orders/getFilterableOrders",
  filterableOrdersGet
);
export const getOneOrder = createAsyncThunk("orders/getOneOrder", oneOrdersGet);
export const cancelOrder = createAsyncThunk("orders/cancelOrder", orderCancel);
export const startOrder = createAsyncThunk("orders/startOrder", orderStart);
export const completeOrder = createAsyncThunk(
  "orders/completeOrder",
  orderComplete
);
export const reassignOrder = createAsyncThunk(
  "orders/reassignOrder",
  orderReassign
);
export const createDeliveryOrder = createAsyncThunk(
  "orders/createDeliveryOrder",
  orderCreateDelivery
);
export const createDeliveryPickupOrder = createAsyncThunk(
  "orders/createDeliveryPickupOrder",
  orderCreateDeliveryPickup
);
export const updateOrderAmount = createAsyncThunk(
  "orders/updateOrderAmount",
  orderAmountUpdate
);
export const uploadProofOfDelivery = createAsyncThunk(
  "orders/uploadProofOfDelivery",
  proofOfDeliveryUpload
);
export const getOrderAnalytics = createAsyncThunk(
  "orders/getOrderAnalytics",
  analyticsOrdersGet
);
export const getOrderDeliveryCode = createAsyncThunk(
  "orders/getOrderDeliveryCode",
  orderDeliveryCodeGet
);
export const downloadAdminReport = createAsyncThunk(
  "orders/downloadAdminReport",
  reportAdminDownload
);
