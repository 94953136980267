import Modal from "layout/modal/Modal";
import React from "react";
import ApproveClient from "./ApproveClient";

const ApproveClientModal = ({ isShown, hide, selectedRow, selectedRows }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Approve Clients">
      <ApproveClient
        selectedRow={selectedRow}
        selectedRows={selectedRows}
        hide={hide}
      />
    </Modal>
  );
};

export default ApproveClientModal;
