import Modal from "layout/modal/Modal";
import React from "react";
import AddNewFleetType from "./AddNewFleetType";

const AddNewFleetTypeModal = ({ isShown, hide }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Fleet Type">
      <AddNewFleetType hide={hide} />
    </Modal>
  );
};

export default AddNewFleetTypeModal;
