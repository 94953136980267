import Modal from "layout/modal/Modal";
import React from "react";
import AddNewLocation from "./AddNewLocation";

const AddNewLocationModal = ({ isShown, hide, selectedRow, isEditing }) => {
  return (
    <Modal
      isShown={isShown}
      hide={hide}
      title={isEditing ? "Edit Location" : "New Location"}
    >
      <AddNewLocation
        hide={hide}
        selectedRow={selectedRow}
        isEditing={isEditing}
      />
    </Modal>
  );
};

export default AddNewLocationModal;
