import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Card from "shared/Card";

const Chart = () => {
  const categories = [
    ["Jan", "Feb", "Mar", "Apr"],
    ["May", "Jun", "Jul", "Aug"],
    ["Sep", "Oct", "Nov", "Dec"],
  ];
  const data = [
    [38, 33, 25, 20],
    [76, 19, 49, 30],
    [43, 13, 7, 10],
  ];

  const [category, setCategory] = useState(categories[0]);
  const [selectedMonth, setSelectedMonth] = useState("Jan");
  const [datum, setDatum] = useState(data[0]);

  const handleMonthChange = (event) => {
    setCategory(categories[parseInt(event.target.value)]);
    setDatum(data[parseInt(event.target.value)]);
    setSelectedMonth(event.target.value);
  };
  const chartOptions = {
    series: [
      {
        name: "Successful Deliveries",
        data: datum,
      },
    ],
    options: {
      chart: {
        id: "column-chart",
      },
      colors: ["#B5E4CA", "#F0CA18"],
      xaxis: {
        categories: category,
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <Card>
      <div className="flex max-[600px]:flex-wrap justify-between items-center">
        <div className="border rounded-lg px-2">
          <select
            id="month-select"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border-l-8 border-[#35C778] outline-none bg-white my-2"
          >
            <option value={0}>Successful Deliveries</option>
            <option value={1}>Failed Deliveries</option>
            <option value={2}>Last 12 months</option>
          </select>
        </div>

        <div className="border rounded-lg px-2">
          <select
            id="month-select"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="border-none outline-none bg-white my-2"
          >
            <option value={0}>Last 7 days</option>
            <option value={1}>Last 3 months</option>
            <option value={2}>Last 12 months</option>
          </select>
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        height={350}
      />
    </Card>
  );
};

export default Chart;
