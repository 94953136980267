import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  invoiceCreate,
  invoicesGet,
  invoicesGetOne,
  invoiceStatusChange,
  oneOrderAddToInvoice,
  multipleOrdersAddToInvoice,
  orderRemoveFromInvoice,
} from "appstate/api/invoice";

export const createInvoice = createAsyncThunk(
  "invoice/createInvoice",
  invoiceCreate
);

export const getInvoices = createAsyncThunk("invoice/getInvoices", invoicesGet);

export const getOneInvoice = createAsyncThunk(
  "invoice/getOneInvoice",
  invoicesGetOne
);

export const changeInvoiceStatus = createAsyncThunk(
  "invoice/changeInvoiceStatus",
  invoiceStatusChange
);

export const addOneOrderToInvoice = createAsyncThunk(
  "invoice/addOneOrderToInvoice",
  oneOrderAddToInvoice
);

export const addMultipleOrdersToInvoice = createAsyncThunk(
  "invoice/addMultipleOrdersToInvoice",
  multipleOrdersAddToInvoice
);

export const removeOrderFromInvoice = createAsyncThunk(
  "invoice/removeOrderFromInvoice",
  orderRemoveFromInvoice
);
