import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useState } from "react";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Table from "shared/Table";
import AssignRiderToFleetModal from "./modal/assignRiderToFleet";
import TableControl from "shared/TableControl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  activateFleet,
  deactivateFleet,
  getFleets,
} from "appstate/features/fleet/fleetActions";
import Loader from "shared/Loader";

const FleetDetails = ({ fleets }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showControls, setShowControls] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const { isLoading } = useSelector((state) => state.fleet);

  const dispatch = new useDispatch();

  const controls = [
    {
      text: "Assign Rider",
      action: () => {
        setIsShown(true);
        setShowControls(false);
      },
    },
    {
      text: "Activate Fleet",
      action: () => {
        if (!selectedRow?.deactivated) {
          toast.error("Fleet is active!");
          setShowControls(false);
          setSelectedRow(null);
          return;
        }
        dispatch(activateFleet({ regNumber: selectedRow?.regNumber })).then(
          (data) => {
            if (data?.payload?.status === 201) {
              dispatch(getFleets());
              setShowControls(false);
              setSelectedRow(null);
              toast.success("Fleet activated successfully!");
            }
          }
        );
      },
    },
    {
      text: "Deactivate Fleet",
      action: () => {
        if (selectedRow?.deactivated) {
          toast.error("Fleet is not active!");
          setShowControls(false);
          setSelectedRow(null);
          return;
        }
        dispatch(deactivateFleet({ regNumber: selectedRow?.regNumber })).then(
          (data) => {
            if (data?.payload?.status === 201) {
              dispatch(getFleets());
              setShowControls(false);
              setSelectedRow(null);
              toast.success("Fleet deactivated successfully!");
            }
          }
        );
      },
    },
  ];

  const fleetColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Number Plate",
      accessor: (d) => <p className="flex justify-start ml-4">{d.regNumber}</p>,
    },
    {
      Header: "Assigned Rider",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.currentRider?.firstName || d.currentRider?.lastName
            ? `${d.currentRider?.firstName} ${d.currentRider?.lastName}`
            : "N/A"}
        </p>
      ),
    },
    {
      Header: "Current Location",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.currentLocation?.coordinates[0]}°N{" "}
          {d.currentLocation?.coordinates[1]}°E
        </p>
      ),
    },
    {
      Header: "Type",
      accessor: (d) => <p className="flex justify-start ml-4">{d.fleetType}</p>,
    },
    {
      Header: "Brand",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.fleetBrand}</p>
      ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        !d.deactivated ? (
          <div className="flex justify-start ml-4">
            <div className="bg-offGreen text-malachite text-[12px] rounded-lg py-1 px-2">
              Active
            </div>
          </div>
        ) : (
          <div className="flex justify-start ml-4">
            <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2">
              Inactive
            </div>
          </div>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <AssignRiderToFleetModal
        isShown={isShown}
        hide={() => setIsShown(false)}
        selectedRow={selectedRow}
      />
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for fleet"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="flex items-center flex-wrap gap-4">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Delete</p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <LHIconFilter />
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Name</p>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            columns={fleetColumns}
            data={fleets}
            type="You don’t have any fleet. Create a new fleet to get started"
          />
        )}
      </div>
    </Card>
  );
};

export default FleetDetails;
