import {
  LHIconBike,
  LHIconDashboard,
  LHIconDocument,
  LHIconDoubleCheck,
  LHIconGroup,
  LHIconIntegration,
  LHIconInvoice,
  LHIconLocation,
  LHIconMessage,
  LHIconNotifications,
  LHIconPricing,
  LHIconReconciliation,
  LHIconStore,
  LHIconUser,
  LHIconWhiteGear,
} from "assets/svgs";

export const sidebarContent = [
  {
    items: [
      {
        icon: <LHIconDashboard />,
        title: "Dashboard",
        path: "/admin/dashboard",
        id: 0,
      },
      {
        icon: <LHIconDocument />,
        title: "Orders",
        path: "/admin/orders",
        open: false,
        id: 1,
      },
      {
        icon: <LHIconLocation />,
        title: "Tracking",
        path: "/admin/tracking",
        id: 2,
      },
    ],
  },
  {
    title: "Operation",
    items: [
      {
        collapsable: {
          title: "Prospective Riders",
          path: "/admin/rider/prospective",
        },
        icon: <LHIconUser />,
        title: "Rider",
        path: "/admin/rider",
        open: false,
        id: 3,
      },
      {
        collapsable: {
          title: "Prospective Clients",
          path: "/admin/clients/prospective",
        },
        icon: <LHIconStore />,
        title: "Client",
        path: "/admin/clients",
        open: false,
        id: 4,
      },
      {
        collapsable: {
          title: "Fleet Type",
          path: "/admin/fleet/type",
        },
        icon: <LHIconBike />,
        title: "Fleet",
        path: "/admin/fleet",
        open: false,
        id: 5,
      },
      {
        icon: <LHIconGroup />,
        title: "Teams",
        path: "/admin/teams",
        id: 6,
      },
      {
        icon: <LHIconDoubleCheck />,
        title: "Pricing",
        path: "/admin/pricing",
        id: 7,
      },
    ],
  },
  {
    title: "Messages",
    items: [
      {
        icon: <LHIconMessage />,
        title: "Chat",
        // path: "/admin/tracking",
        id: 8,
      },
      {
        icon: <LHIconNotifications />,
        title: "Notification",
        // path: "/admin/tracking",
        id: 9,
      },
    ],
  },
  {
    title: "Analytics",
    items: [
      {
        icon: <LHIconUser />,
        title: "Stats",
        path: "/admin/stats",
        id: 10,
      },
      {
        icon: <LHIconDocument />,
        title: "Reports",
        path: "/admin/reports",
        id: 11,
      },
    ],
  },
  {
    title: "Finance",
    items: [
      {
        icon: <LHIconDocument />,
        title: "Approved Rates",
        path: "/admin/approved-rates",
        id: 12,
      },
      {
        icon: <LHIconInvoice />,
        title: "Invoice",
        path: "/admin/invoice",
        id: 13,
      },
      {
        icon: <LHIconReconciliation />,
        title: "Reconciliation",
        // path: "/admin/tracking",
        id: 14,
      },
      {
        icon: <LHIconPricing />,
        title: "Pricing",
        // path: "/admin/tracking",
        id: 15,
      },
    ],
  },
  {
    title: "Settings",
    items: [
      {
        icon: <LHIconIntegration />,
        title: "Integrations",
        // path: "/admin/tracking",
        id: 16,
      },
      {
        icon: <LHIconWhiteGear />,
        title: "Access Control",
        path: "/admin/access-control",
        id: 17,
      },
    ],
  },
];

export const filterByPeriodData = [
  { name: "Today", value: "today" },
  { name: "This week", value: "today" },
  { name: "This month", value: "today" },
  { name: "Last 6 months", value: "today" },
  { name: "This year", value: "today" },
];
