import * as Yup from "yup";
const addClientValidationSchema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  businessName: Yup.string().required("This field is required"),
  phoneNumber: Yup.number("Must be a number").required(
    "This field is required"
  ),
  email: Yup.string().required("This field is required"),
  address: Yup.string().required("This field is required"),
  additionalInstruction: Yup.string().required("This field is required"),
});

export { addClientValidationSchema };
