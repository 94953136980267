import { adminLogin } from "appstate/features/auth/authActions";
import { LHbackground } from "assets/images";
import {
  LHIconEmail,
  LHIconPasswordNotVisible,
  LHIconPasswordVisible,
  LHIconStrippedLogo,
} from "assets/svgs";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";

const AdminLoginLayout = () => {
  const [viewPassword, setViewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const body = {
        email: values.email,
        password: values.password,
      };
      await dispatch(adminLogin(body)).then((data) => {
        if (data?.payload?.status === 200) {
          navigate("/admin/dashboard");
        }
      });
      setIsLoading(false);
    },
  });

  return (
    <section className="w-screen h-screen flex max-sm:flex-col-reverse justify-center items-center">
      <div className="w-1/2 max-sm:w-full flex justify-center items-center">
        <div className="w-3/5 max-[1025px]:w-full mt-5 p-5 px-12 lg:p-10 mx-auto">
          <div className="text-center flex flex-col gap-3 items-center justify-center mb-8">
            <div className="mb-2">
              <LHIconStrippedLogo />
            </div>
            <h2 className="text-2xl text-textCol font-bold">Sign In</h2>
            <p className="text-sm text-midGray text-opacity-70">
              Please sign in to access your account.
            </p>
          </div>
          <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
            <div className="relative">
              <InputField
                placeholder="Email address"
                type="email"
                name="email"
                label="Email address"
                className="!pl-10"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <LHIconEmail className="absolute bottom-6 left-2" />
            </div>
            <div className="relative">
              <InputField
                placeholder="Password"
                type={`${viewPassword ? "password" : "text"}`}
                name="password"
                label="Password"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {!viewPassword ? (
                <LHIconPasswordVisible
                  onClick={() => setViewPassword(true)}
                  className="absolute bottom-6 right-4 cursor-pointer"
                />
              ) : (
                <LHIconPasswordNotVisible
                  onClick={() => setViewPassword(false)}
                  className="absolute bottom-6 right-4 cursor-pointer"
                />
              )}
            </div>
            <Button blue state={isLoading} type="submit">
              {isLoading ? <ButtonAnimation /> : "Sign In"}
            </Button>
          </form>
        </div>
      </div>
      <div className="w-1/2 max-sm:hidden h-full">
        <img
          src={LHbackground}
          className="w-full h-full object-cover"
          alt="Logistics Hub"
        />
      </div>
    </section>
  );
};

export default AdminLoginLayout;
