import { formatCurrency } from "utils/formatCurrency";
import { FaMoneyBillTransfer, FaRegMoneyBill1 } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { PiPersonSimpleBikeBold } from "react-icons/pi";
import { FaInfinity } from "react-icons/fa";
import OverviewCard from "./OverviewCard";
import { HiStatusOnline } from "react-icons/hi";

const SubscriptionDetail = ({ merchantBilling }) => {
  const usedDelivery = merchantBilling?.usedDelivery || 0;
  const maxDelivery = merchantBilling?.subscriptionDetail?.maximumDeliveries;

  const usedValue = Math.floor((usedDelivery / maxDelivery) * 100);

  // console.log(usedValue);

  return (
    <div className="mb-6 bg-gray200 p-5 rounded-md">
      <h2 className="text-fiord font-semibold mb-4">Subscription Details</h2>

      <div className="bg-purpleCol h-2 w-full rounded-full shadow-md">
        <div
          style={{ width: usedValue || 0 }}
          className=" bg-greenCol h-2 rounded-full"
        />
      </div>

      <div className="flex flex-col md:flex-row gap-3 my-4">
        <OverviewCard
          title="Used Deliveries"
          icon={<FaCheckCircle />}
          text={merchantBilling?.usedDelivery || 0}
          bgColor="bg-greenCol"
          isSub={true}
        />

        <OverviewCard
          title="Available Deliveries"
          icon={<PiPersonSimpleBikeBold />}
          bgColor="bg-purpleCol"
          text={
            merchantBilling?.subscriptionDetail?.maximumDeliveries -
              merchantBilling?.usedDelivery || "-"
          }
          isSub={true}
        />
      </div>

      <div className="flex flex-col md:flex-row gap-3 mb-3">
        <OverviewCard
          title="Cost Per Delivery"
          icon={<FaRegMoneyBill1 />}
          bgColor="bg-fiord"
          text={merchantBilling?.subscriptionDetail?.costPerDelivery || "-"}
          isSub={true}
        />

        <OverviewCard
          title="Total Cost"
          icon={<FaInfinity />}
          bgColor="bg-blue"
          text={formatCurrency(merchantBilling?.currency).format(
            merchantBilling?.totalAmount || 0
          )}
          // isTotal={true}
          isSub={true}
        />
      </div>
    </div>
  );
};

export default SubscriptionDetail;

{
  /* <Skeleton height={60} width={270} count={1} /> */
}
