import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import AddNewClientModal from "../modal/addNewClient";
import Header from "shared/Header";
import ProspectiveDetails from "./ProspectiveDetails";
import { getClients } from "appstate/features/client/clientActions";
import {
  getPricing,
  getPricingRules,
} from "appstate/features/pricing/pricingActions";
import { useDispatch, useSelector } from "react-redux";

const ProspectiveClientsContents = () => {
  const { clients } = useSelector((state) => state.client);
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
    dispatch(getPricing());
    dispatch(getPricingRules());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewClientModal isShown={isShown} hide={() => setIsShown(false)} />
      <Header
        info1="Total"
        info2="Approved"
        info3="Unapproved"
        info4="Declined"
        amount={`${clients?.filter((client) => !client.approved).length}`}
        activeAmount={"0"}
        inactiveAmount={`${
          clients?.filter((client) => !client.approved).length
        }`}
        deliveredAmount={"0"}
        title="Prospective Clients"
        btnText="New Client"
        onClick={() => setIsShown(true)}
      />
      <ProspectiveDetails clients={clients} />
    </DashboardLayout>
  );
};

export default ProspectiveClientsContents;
