import Modal from "layout/modal/Modal";
import AddAdminToTeam from "./AddAdminToTeam";

const AddAdminToTeamModal = ({ isShown, hide, admins, currentTeam }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Add Member">
      <AddAdminToTeam hide={hide} admins={admins} currentTeam={currentTeam} />
    </Modal>
  );
};

export default AddAdminToTeamModal;
