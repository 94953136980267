// import { getTeams } from "appstate/features/teams/teamActions";
import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import { format } from "date-fns";
import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Loader from "shared/Loader";
import Table from "shared/Table";
import TableControl from "shared/TableControl";

const Details = ({ teams, currentPage, isLoading }) => {
  const [showControls, setShowControls] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  // console.log(teams);

  // const dispatch = useDispatch();

  // // const fetchNextPage = useCallback(() => {
  // //   dispatch(getTeams({ page: currentPage + 1 }));
  // // }, [currentPage, dispatch]);

  // // const fetchPrevPage = useCallback(() => {
  // //   dispatch(getTeams({ page: currentPage - 1 }));
  // // }, [currentPage, dispatch]);

  const controls = [
    {
      text: "Edit team",
      action: () => {},
    },
    {
      text: "Delete team",
      action: () => {},
    },
  ];

  const teamsColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Team Name",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4"
          to={`/admin/teams/${d._id}`}
        >
          {d.name}
        </Link>
      ),
    },
    {
      Header: "Manager",
      accessor: (d) => (
        <Link
          className="flex justify-start ml-4 capitalize"
          to={`/admin/teams/${d._id}`}
        >
          <p>
            {d.manager?.firstName
              ? `${d.manager?.firstName} ${d.manager?.lastName}`
              : "N/A"}
          </p>
        </Link>
      ),
    },
    {
      Header: "Team members",
      accessor: (d) => (
        <Link
          className="flex justify-start ml-4 capitalize"
          to={`/admin/teams/${d._id}`}
        >
          <p>{d.users?.length ? d.users?.length : 0}</p>
        </Link>
      ),
    },
    {
      Header: "Created Date",
      accessor: (d) => (
        <Link className="flex justify-start ml-4" to={`/admin/teams/${d._id}`}>
          <p>
            {format(
              new Date(d.createdAt.split("T")[0].split("-").join("/")),
              "EEEE, do MMMM yyyy"
            )}
          </p>
        </Link>
      ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for team"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="flex items-center flex-wrap gap-4">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Delete</p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <LHIconFilter />
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Name</p>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            columns={teamsColumns}
            data={teams}
            // disabledPrevBtn={currentPage === 0 ? true : false}
            type="You don’t have any team. Create a new team to get started"
          />
        )}
      </div>
    </Card>
  );
};

export default Details;
