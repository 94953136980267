import { format } from "date-fns";
import Modal from "layout/modal/Modal";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import Card from "shared/Card";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";
import { reportAdminDownload } from "appstate/api/order";
import { getMerchantStores } from "appstate/features/client/clientActions";

const DownloadReport = ({ reportMode, reportOptions }) => {
  const { clients } = useSelector((state) => state.client);

  const [merchantSubStores, setMerchantSubStores] = useState(null);
  const [isDateShown, setIsDateShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [deliveryType, setDeliveryType] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [merchantOutletId, setMerchantOutletId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (merchantId) {
      dispatch(getMerchantStores({ id: merchantId, page: 0 })).then((data) => {
        setMerchantSubStores(data?.payload?.data?.data?.data);
      });
    }
  }, [dispatch, merchantId]);

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "yyyy-MM-dd");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
    setStartDate(formatDateDisplay(ranges.selection.startDate, "Start Date"));
    setEndDate(formatDateDisplay(ranges.selection.endDate, "End Date"));
  };

  const handleDownloadReport = () => {
    if (Number(reportMode) === 0) {
      setIsLoading(true);

      const body =
        deliveryType === "Completed Deliveries"
          ? {
              startDate,
              endDate,
              merchantId,
              merchantOutletId,
              delivered: true,
            }
          : deliveryType === "Active Deliveries"
          ? {
              startDate,
              endDate,
              merchantId,
              merchantOutletId,
              active: true,
            }
          : deliveryType === "Pending Deliveries"
          ? {
              startDate,
              endDate,
              merchantId,
              merchantOutletId,
              pending: true,
            }
          : {
              startDate,
              endDate,
              merchantId,
              merchantOutletId,
            };
      reportAdminDownload(body)
        .then((content) => {
          const url = window.URL.createObjectURL(new Blob([content.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Logistics Hub ${
            deliveryType ? deliveryType : "All"
          } Order Report${
            startDate
              ? ` from ${startDate?.split("-").reverse().join("-")} to ${endDate
                  ?.split("-")
                  .reverse()
                  .join("-")}`
              : ``
          }.csv`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      <div className="flex justify-center">
        <div className="flex flex-col items-center">
          <p className="text-[20px] max-md:text-[12px] font-semibold text-mirage">
            Download Report
          </p>
          <p className="text-slateGray text-center text-[13px] max-md:text-[10px]">
            Download a detailed report of your{" "}
            {Number(reportMode) === 0
              ? "delivery"
              : Number(reportMode) === 1
              ? "fleet"
              : Number(reportMode) === 2
              ? "invoice"
              : "client"}{" "}
            activities
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <form className="w-3/5 max-md:w-full flex items-center gap-4 flex-col">
          {Number(reportMode) !== 3 && (
            <div className="w-full">
              <InputSelect
                datas={reportOptions[Number(reportMode)].selectOptions}
                label={"Download Type"}
                placeholder={
                  Number(reportMode) === 0
                    ? "All deliveries"
                    : Number(reportMode) === 1
                    ? "All Fleet"
                    : "All Invoice"
                }
                onChange={(e) => setDeliveryType(e.target.value)}
                name="downloadType"
                className="!h-[50px]"
                divClass="!w-full"
              />
            </div>
          )}
          <div className="w-full">
            <InputSelect
              admin
              datas={clients}
              onChange={(e) => setMerchantId(e.target.value)}
              label={"Select Store"}
              placeholder="All stores"
              name="merchant"
              className="!h-[50px]"
              divClass="!w-full"
            />
          </div>
          {merchantSubStores?.length > 1 && (
            <div className="w-full">
              <InputSelect
                admin
                datas={merchantSubStores}
                onChange={(e) => setMerchantOutletId(e.target.value)}
                label={"Select Merchant Sub Store"}
                placeholder="All sub stores"
                name="merchantSubStore"
                className="!h-[50px]"
                divClass="!w-full"
              />
            </div>
          )}
          <div className="w-full relative">
            <label className="font-normal text-xs text-midGray mb-[5px]">
              Date Range
            </label>
            <div
              onClick={() => setIsDateShown(!isDateShown)}
              className="h-[50px] cursor-pointer flex justify-start items-center px-4 py-2 text-paleSky text-[14px] font-medium rounded-lg bg-catSkillWhite"
            >
              <div className="flex flex-wrap gap-1">
                <p className="max-sm:text-[12px]">
                  {startDate
                    ? startDate?.split("-").reverse().join("-")
                    : "Start Date"}
                </p>
                -
                <p className="max-sm:text-[12px]">
                  {endDate
                    ? endDate?.split("-").reverse().join("-")
                    : "End Date"}
                </p>
              </div>
            </div>
            {isDateShown && (
              <Modal
                isShown={isDateShown}
                hide={() => setIsDateShown(false)}
                title="Select Date"
                divClass="md:!w-fit"
              >
                <DateRangePicker
                  onChange={(item) => {
                    handleSelect(item);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={[selectedDateRange]}
                  scroll={{ enabled: true }}
                  direction="vertical"
                />
                <div className="flex justify-end gap-4 pb-4 mr-7">
                  <button
                    className="text-white text-[12px] bg-congressBlue px-4 py-2 rounded-xl"
                    onClick={() => {
                      // dateFilterFn();
                      setIsDateShown(false);
                    }}
                  >
                    Done
                  </button>
                  <button
                    className="text-white text-[12px] bg-red-500 px-4 py-2 rounded-xl"
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                      setIsDateShown(false);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </Modal>
            )}
          </div>
          <div className="w-full mt-6">
            <Button
              onClick={handleDownloadReport}
              type="button"
              className="!min-w-full !h-[45px] !px-8"
              blue
            >
              {isLoading ? <ButtonAnimation /> : <p>Download Report</p>}
            </Button>

            <p className="text-[10px] text-slateGray font-medium">
              File will be downloaded in CSV format.
            </p>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default DownloadReport;
