import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { adminRoutes, publicRoutes } from "./routePaths";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return <Outlet />;
  }
  return <Navigate to="/" replace />;
};

const Routing = () => (
  <Routes>
    {adminRoutes.map((route, index) => {
      if (route.children) {
        const Children = () => {
          return (
            <Routes>
              {route?.children?.map((child, index) => {
                return (
                  <Route key={`${index}-xxx`} element={<PrivateRoute />}>
                    <Route
                      path={child.path}
                      key={`${index}-sss`}
                      index={child.index}
                      element={child.element}
                    />
                  </Route>
                );
              })}
            </Routes>
          );
        };

        return (
          <Route
            key={`${index}-yyy`}
            path={route.path}
            element={<Children />}
          />
        );
      }

      return (
        <Route key={`${index}-xxx`} element={<PrivateRoute />}>
          <Route
            key={Math.random()}
            path={route.path}
            element={route.element}
          />
        </Route>
      );
    })}

    {publicRoutes.map((publicRoute) => {
      return (
        <Route
          key={Math.random()}
          path={publicRoute.path}
          element={publicRoute.element}
        />
      );
    })}
  </Routes>
);

export default Routing;
