import Card from "shared/Card";
import { LHIconBus, LHIconEdit, LHIconGroup, LHIconTrash } from "assets/svgs";
import Button from "shared/Button";
import { useEffect, useState } from "react";
import UpdateRiderTeamModal from "./modal/updateRiderTeam";
import { useDispatch } from "react-redux";
import { getClientByTeamId } from "appstate/features/client/clientActions";

const RiderInfo = ({ currentRider, setCurrentRider }) => {
  const [isUpdateTeamModalShown, setIsUpdateTeamModalShown] = useState(false);
  const [riderClients, setRiderClients] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    currentRider?.team?.id &&
      dispatch(getClientByTeamId({ id: currentRider?.team?.id })).then(
        (data) => {
          setRiderClients(data?.payload?.data?.data?.client);
        }
      );
  }, [dispatch, currentRider?.team?.id]);

  return (
    <Card className="w-2/5 flex flex-col gap-3">
      <UpdateRiderTeamModal
        currentRider={currentRider}
        isShown={isUpdateTeamModalShown}
        hide={() => setIsUpdateTeamModalShown(false)}
        setCurrentRider={setCurrentRider}
      />
      <div className="flex items-center gap-2">
        <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
          <p className="text-[23px] uppercase font-medium text-paleSky">
            {currentRider?.user?.firstName?.charAt(0)}
            {currentRider?.user?.lastName?.charAt(0)}
          </p>
        </div>
        <div>
          <p className="text-[14px] font-medium capitalize text-paleSky">
            {currentRider?.user?.firstName} {currentRider?.user?.lastName}
          </p>
          <p className="text-grayChateau text-[14px]">
            {currentRider?.user?.mobile}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="flex items-center pr-2 gap-2 border-r">
          <div className="flex gap-2 items-center">
            <LHIconGroup className="fill-black stroke-black" />
            <p className="text-gullGray text-[12px]">Assigned Team</p>
          </div>
          <p className="text-[12px] pr-2">
            {currentRider?.team?.name ? currentRider?.team?.name : "N/A"}
          </p>
          <LHIconEdit
            className="w-4 h-4 cursor-pointer"
            onClick={() => setIsUpdateTeamModalShown(true)}
          />
        </div>
        <div className="flex items-center gap-2">
          <LHIconBus className="fill-black" />
          <p className="text-gullGray text-[12px]">Fleet Type</p>
          <p className="text-[12px] capitalize">{currentRider?.vehicle}</p>
        </div>
      </div>
      <div className="border-b pb-4">
        <Button className="!w-full !h-[45px]" blue>
          Edit Rider Info
        </Button>
      </div>
      <div className="border-b">
        <div className="flex items-center justify-between">
          <p className="text-[16px] font-medium">Assigned Client(s)</p>
          <LHIconTrash className="w-5 h-5" />
        </div>
        <div className="flex flex-col my-2 gap-3">
          <div className="my-1 flex gap-2 flex-col items-center">
            {riderClients?.map((client, idx) => (
              <div
                key={idx}
                className="w-full flex justify-between gap-2 items-center"
              >
                <div className="flex items-center gap-2">
                  <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
                    <p className="text-[18px] font-medium text-paleSky">
                      {client?.name?.split(" ")[0]?.charAt(0)}
                      {client?.name?.split(" ")[1]?.charAt(0)}
                    </p>
                  </div>
                  <div>
                    <p className="text-[14px] font-medium capitalize text-paleSky">
                      {client?.name}
                    </p>
                    <p className="text-grayChateau text-[14px]">
                      {client?.sector}
                    </p>
                  </div>
                </div>
                <input type="checkbox" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RiderInfo;
