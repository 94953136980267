import { createSlice } from "@reduxjs/toolkit";

import {
  getAdmins,
  getAdminById,
  editAdmin,
  deleteAdmin,
  registerAdmin,
} from "./adminActions";

const initialState = {
  isLoading: false,
  admins: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdmins.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admins = action.payload?.data;
    });
    builder.addCase(getAdmins.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAdminById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAdminById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editAdmin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAdmin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(registerAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(registerAdmin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default adminSlice.reducer;
