import { useState } from "react";
import DeliveryContents from "./Delivery";
import PickupDeliveryContent from "./PickupDelivery";

const NewOrderContent = ({ hide, setCurrentOrders }) => {
  const [isDeliveryOnly, setIsDeliveryOnly] = useState(true);

  return (
    <div>
      <div className="flex justify-center">
        <div className="w-[20rem] flex items-center gap-2 bg-wildSand py-1 px-1 rounded-2xl">
          <div
            onClick={() => setIsDeliveryOnly(true)}
            className={`cursor-pointer flex justify-center w-1/2 rounded-3xl py-1 px-4 ${
              isDeliveryOnly && "bg-white"
            }`}
          >
            <p className="text-[15px] whitespace-nowrap">Delivery Only</p>
          </div>
          <div
            onClick={() => setIsDeliveryOnly(false)}
            className={`cursor-pointer flex justify-center rounded-3xl w-1/2 py-1 px-4 ${
              !isDeliveryOnly && "bg-white"
            }`}
          >
            <p className="text-[15px] whitespace-nowrap">Pickup & Delivery</p>
          </div>
        </div>
      </div>
      {isDeliveryOnly ? (
        <DeliveryContents hide={hide} setCurrentOrders={setCurrentOrders} />
      ) : (
        <PickupDeliveryContent
          hide={hide}
          setCurrentOrders={setCurrentOrders}
        />
      )}
    </div>
  );
};

export default NewOrderContent;
