import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import {
  assignRiderToFleet,
  getAvailableRidersForDelivery,
  getFilterableRiders,
  getFleets,
  getRidersByTeamId,
} from "appstate/features/fleet/fleetActions";
import {
  getManyOrders,
  getOneOrder,
  reassignOrder,
} from "appstate/features/order/orderActions";
import { getUserById } from "appstate/features/auth/authActions";

const AssignRiderToFleet = ({
  hide,
  selectedRow,
  reassign,
  order,
  setCurrentOrder,
  setCurrentOrders,
}) => {
  const { isLoading } = useSelector((state) => state.fleet);

  const [selectedRider, setSelectedRider] = useState({});
  const [merchant, setMerchant] = useState(null);
  const [ridersToUse, setRidersToUse] = useState([]);
  const [riderOption, setRiderOption] = useState("Available Riders");

  const dispatch = useDispatch();

  let longitude;
  let latitude;
  // if (merchantOutlet) {
  //   longitude = merchant?.location?.coordinates
  //     ? merchant?.location?.coordinates[0]
  //     : "";
  //   latitude = merchant?.location?.coordinates
  //     ? merchant?.location?.coordinates[1]
  //     : "";
  // } else {
  //   longitude = merchantOutlet?.location?.coordinates
  //     ? merchantOutlet?.location?.coordinates[0]
  //     : "";
  //   latitude = merchantOutlet?.location?.coordinates
  //     ? merchantOutlet?.location?.coordinates[1]
  //     : "";
  // }
  longitude = merchant?.location?.coordinates
    ? merchant?.location?.coordinates[0]
    : "";
  latitude = merchant?.location?.coordinates
    ? merchant?.location?.coordinates[1]
    : "";

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(
        getAvailableRidersForDelivery({
          merchantId: order?.merchant?.merchantId,
          locLatitude: latitude,
          locLongitude: longitude,
        })
      ).then((data) => {
        setRiderOption("Available Riders");
        setRidersToUse(data?.payload?.data);
      });
    }
  }, [dispatch, order?.merchant?.merchantId, latitude, longitude]);

  useEffect(() => {
    dispatch(getUserById({ id: order?.merchant?.merchantId })).then((data) => {
      setMerchant(data?.payload?.data?.data);
    });
  }, [dispatch, order?.merchant?.merchantId]);

  const ridersList = ridersToUse?.map((rider) => ({
    id: rider?._id,
    name: `${rider?.user?.firstName} ${rider?.user?.lastName}`,
    value: {
      firstName: rider?.user?.firstName,
      lastName: rider?.user?.lastName,
      authId: rider?.user?.authId,
      mobile: rider?.user?.mobile,
      email: rider?.user?.email,
      country: "Nigeria",
    },
  }));

  const onSelect = (selectedList, selectedItem) => {
    setSelectedRider(selectedItem.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reassign) {
      dispatch(reassignOrder({ id: order?._id, rider: selectedRider })).then(
        (data) => {
          if (data?.payload?.status === 200) {
            toast.success(
              `Order reassigned to ${selectedRider.firstName} ${selectedRider.lastName}`
            );
            dispatch(getOneOrder({ id: order?._id })).then((data) =>
              setCurrentOrder(data?.payload?.data?.data)
            );
            dispatch(getManyOrders({ page: 1 })).then((data) => {
              setCurrentOrders(data?.payload?.data);
            });
            hide();
          }
        }
      );
    } else {
      dispatch(
        assignRiderToFleet({
          fleetId: selectedRow._id,
          riderInfo: selectedRider,
        })
      ).then((data) => {
        if (data?.payload?.status === 201) {
          dispatch(getFilterableRiders({ page: 1 }));
          dispatch(getFleets());
          toast.success(
            `Rider ${selectedRider.firstName} ${selectedRider.lastName} assigned successfully`
          );
          hide();
        }
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <p className="text-[14px] text-fiord mb-2">Assign Rider</p>
        <div className="pb-6">
          <div className="flex gap-5 mb-2">
            <div className="flex flex-wrap md:flex-row gap-3 mt-1">
              {[
                "Available Riders",
                "LH Dedicated Riders",
                "Team Riders",
                "Marketplace",
              ].map((item, idx) => (
                <button
                  type="button"
                  key={idx}
                  onClick={() => {
                    if (item === "Available Riders") {
                      dispatch(
                        getAvailableRidersForDelivery({
                          merchantId: order?.merchant?.merchantId,
                          locLatitude: latitude,
                          locLongitude: longitude,
                        })
                      ).then((data) => {
                        setRiderOption("Available Riders");
                        setRidersToUse(data?.payload?.data);
                      });
                    } else if (item === "LH Dedicated Riders") {
                      dispatch(
                        getRidersByTeamId({
                          teamId: merchant?.team?.id,
                          page: 1,
                        })
                      ).then((data) => {
                        setRiderOption("LH Dedicated Riders");
                        setRidersToUse(data?.payload?.data?.riders);
                      });
                    } else if (item === "Team Riders") {
                      dispatch(
                        getRidersByTeamId({
                          teamId: merchant?.team?.id,
                          page: 1,
                        })
                      ).then((data) => {
                        setRiderOption("Team Riders");
                        setRidersToUse(data?.payload?.data?.riders);
                      });
                    } else {
                      setRiderOption("Marketplace");
                      setSelectedRider({});
                      setRidersToUse([]);
                    }
                  }}
                  className={`text-sm ${
                    riderOption === item
                      ? "text-white bg-congressBlue"
                      : "text-congressBlue bg-white border"
                  } p-2 md:px-4 rounded-lg text-xs outline-none`}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <Multiselect
            showArrow={true}
            customArrow={
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
              </svg>
            }
            customCloseIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                  fill="#33383F"
                />
              </svg>
            }
            selectionLimit={1}
            displayValue="name"
            onSelect={onSelect}
            options={ridersList}
            value={selectedRider}
            placeholder="Assign rider"
            onChange={setSelectedRider}
            className="h-[50px] mb-7 rounded-lg bg-catSkillWhite text-[12px]"
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? (
              <ButtonAnimation />
            ) : reassign ? (
              "Reassign Rider"
            ) : (
              "Assign Rider"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AssignRiderToFleet;
