import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Table from "shared/Table";
import ApproveClientModal from "../modal/approveClient";
import {
  getPricing,
  getPricingRules,
} from "appstate/features/pricing/pricingActions";
import { getClients } from "appstate/features/client/clientActions";
import { Link } from "react-router-dom";
import TableControl from "shared/TableControl";

const ProspectiveDetails = ({ clients }) => {
  const [isShown, setIsShown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
    dispatch(getPricing());
    dispatch(getPricingRules());
  }, [dispatch]);

  const controls = [
    {
      text: "Edit Client",
      action: () => {},
    },
    {
      text: "Approve Client",
      action: () => {
        setIsShown(true);
        setShowControls(false);
      },
    },
    {
      text: "Delete Client",
      action: () => {},
    },
  ];

  const clientColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Client Name",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4"
          to={`/admin/clients/${d._id}`}
        >
          {d.name}
        </Link>
      ),
    },
    {
      Header: "No. of deliveries",
      accessor: (d) => (
        <Link
          className="flex justify-start ml-4"
          to={`/admin/clients/${d._id}`}
        >
          <p>{d.noOfDeliveries ? d.noOfDeliveries : "N/A"}</p>
        </Link>
      ),
    },
    {
      Header: "No. of Outlet(s)",
      accessor: (d) => (
        <Link
          to={`/admin/clients/${d._id}`}
          className="flex justify-start ml-4"
        >
          <p>{d.noOfOutlet}</p>
        </Link>
      ),
    },
    {
      Header: "Avg Delivery Per Day",
      accessor: (d) => (
        <Link
          className="flex justify-start ml-4"
          to={`/admin/clients/${d._id}`}
        >
          <p>{d.deliveriesPerDay}</p>
        </Link>
      ),
    },
    {
      Header: "Last Delivery Date",
      accessor: (d) => (
        <Link
          className="flex justify-start ml-4"
          to={`/admin/clients/${d._id}`}
        >
          <p>{d.lastDeliveryDate ? d.lastDeliveryDate.split("T")[0] : "N/A"}</p>
        </Link>
      ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.approved ? (
          <Link
            to={`/admin/clients/${d._id}`}
            className="flex justify-start ml-4"
          >
            <div className="bg-offGreen text-malachite text-xs rounded-lg py-1 px-2">
              Approved
            </div>
          </Link>
        ) : (
          <Link
            to={`/admin/clients/${d._id}`}
            className="flex justify-start ml-4"
          >
            <div className="bg-red-100 text-red-500 text-xs rounded-lg py-1 px-2">
              Unapproved
            </div>
          </Link>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <ApproveClientModal
        selectedRow={selectedRow}
        selectedRows={selectedRows}
        isShown={isShown}
        hide={() => {
          setSelectedRows([]);
          setSelectedRow(null);
          setIsShown(false);
        }}
      />
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for client"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="flex items-center flex-wrap gap-4">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p
              onClick={() => {
                if (selectedRow !== null || selectedRows.length > 0) {
                  setIsShown(true);
                }
                setShowControls(false);
              }}
              className="text-xs"
            >
              Approve
            </p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Delete</p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <LHIconFilter />
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Name</p>
          </div>
        </div>
      </div>
      <div>
        <Table
          columns={clientColumns}
          data={clients?.filter((client) => !client.approved)}
          type="You don’t have any prospective client. Create a client to get started"
        />
      </div>
    </Card>
  );
};

export default ProspectiveDetails;
