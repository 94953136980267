import Modal from "layout/modal/Modal";
import React from "react";
import AssignRiderToFleet from "./AssignRiderToFleet";

const AssignRiderToFleetModal = ({
  isShown,
  hide,
  selectedRow,
  reassign,
  order,
  setCurrentOrder,
  setCurrentOrders,
}) => {
  return (
    <Modal
      isShown={isShown}
      hide={hide}
      title={reassign ? "Reassign Rider" : "Assign Rider"}
    >
      <AssignRiderToFleet
        order={order}
        reassign={reassign}
        hide={hide}
        selectedRow={selectedRow}
        setCurrentOrder={setCurrentOrder}
        setCurrentOrders={setCurrentOrders}
      />
    </Modal>
  );
};

export default AssignRiderToFleetModal;
