import React from "react";

const ChartLabel = ({ color, text }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={`w-[10px] h-[10px] ${color}`}></div>
      <p className="text-[13px] font-medium">{text}</p>
    </div>
  );
};

export default ChartLabel;
