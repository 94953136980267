import Modal from "layout/modal/Modal";
import NewOrderContent from "./NewOrder";

const NewOrderModal = ({ isShown, hide, setCurrentOrders }) => {
  return (
    <Modal
      className="!max-h-[600px]"
      divClass="lg:!w-[60%]"
      isShown={isShown}
      hide={() => {
        hide();
      }}
      title="New Order"
    >
      <NewOrderContent
        hide={() => {
          hide();
        }}
        setCurrentOrders={setCurrentOrders}
      />
    </Modal>
  );
};

export default NewOrderModal;
