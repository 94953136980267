import React from "react";

const Button = ({ onClick, type, children, state, className, blue }) => {
  const defaultBlueClass = blue
    ? "h-[60px] min-w-[100px] whitespace-nowrap text-[14px] text-white bg-congressBlue rounded-lg px-4 py-2"
    : "h-[60px] min-w-[100px] whitespace-nowrap text-[14px] text-black bg-alabaster text-paleSky border-gallery border-2 rounded-lg px-4 py-2";
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={state}
      className={`${defaultBlueClass} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
