import { ordersBreakDown } from "appstate/features/analytics/analyticsActions";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import Card from "shared/Card";
import ChartLabel from "shared/ChartLabel";

const ClientChart = () => {
  const [analyticsObj, setAnalyticsObj] = useState(null);
  const [totalOrder, setOrderTotal] = useState([]);
  const [onTimeOrder, setOnTimeOrder] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersBreakDown({ merchantId: "" })).then((data) => {
      setAnalyticsObj(data?.payload?.data?.data?.sort((a, b) => a._id - b._id));
      setOrderTotal(
        data?.payload?.data?.data?.map(({ totalDeliveries }) => totalDeliveries)
      );
      setOnTimeOrder(
        data?.payload?.data?.data?.map(({ onTimeDeliveries }) =>
          onTimeDeliveries === null ? 0 : onTimeDeliveries
        )
      );
    });
  }, [dispatch]);

  const chartOptions = {
    series: [
      {
        name: "Total Order",
        data: totalOrder,
      },
      {
        name: "Total On Time Delivery",
        data: onTimeOrder,
      },
    ],
    options: {
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      zoom: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#FFBF05", "#04457B"],
      yaxis: {
        labels: {
          formatter: function (val) {
            return val?.toFixed(0);
          },
        },
      },
      xaxis: {
        categories: analyticsObj?.map(({ _id }) => {
          let month;
          if (_id === 1) {
            month = "Jan";
          } else if (_id === 2) {
            month = "Feb";
          } else if (_id === 3) {
            month = "Mar";
          } else if (_id === 4) {
            month = "Apr";
          } else if (_id === 5) {
            month = "May";
          } else if (_id === 6) {
            month = "Jun";
          } else if (_id === 7) {
            month = "Jul";
          } else if (_id === 8) {
            month = "Aug";
          } else if (_id === 9) {
            month = "Sep";
          } else if (_id === 10) {
            month = "Oct";
          } else if (_id === 11) {
            month = "Nov";
          } else if (_id === 12) {
            month = "Dec";
          }
          return month;
        }),
      },
    },
  };

  return (
    <Card>
      <div className="flex flex-wrap gap-4 justify-between items-center mb-8">
        <div className={`flex gap-4 items-center justify-start`}>
          <div className="w-[16px] h-[32px] rounded-sm bg-malachite"></div>
          <p className="text-[12px] md:text-[20px] font-semibold">Client</p>
        </div>
        <div className="flex gap-2 flex-wrap">
          <ChartLabel color={"bg-amber"} text={"Total Order"} />
          <ChartLabel
            color={"bg-congressBlue"}
            text={"Total On Time Delivery"}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        height={350}
      />
    </Card>
  );
};

export default ClientChart;
