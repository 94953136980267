import { createSlice } from "@reduxjs/toolkit";

import {
  getClients,
  setClientOpeningHours,
  approveClient,
  getMerchantStores,
  getMerchantUsers,
  createMerchantUsers,
  updateClientTeam,
  getClientByTeamId,
} from "./clientActions";

const initialState = {
  isLoading: false,
  clients: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clients = action.payload?.data?.data?.merchants;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(setClientOpeningHours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setClientOpeningHours.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(setClientOpeningHours.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveClient.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveClient.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(approveClient.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantStores.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMerchantStores.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantStores.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMerchantUsers.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantUsers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMerchantUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMerchantUsers.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMerchantUsers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateClientTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateClientTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateClientTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getClientByTeamId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientByTeamId.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getClientByTeamId.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default clientSlice.reducer;
