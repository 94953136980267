import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import SingleClientDetails from "./SingleClientDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserById } from "appstate/features/auth/authActions";
import { toast } from "react-toastify";
import { getOrderAnalytics } from "appstate/features/order/orderActions";
import { getFleetType } from "appstate/features/fleet/fleetActions";

const SingleClientContents = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentClient, setCurrentClient] = useState(null);

  const [totalOrders, setTotalOrders] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);

  useEffect(() => {
    currentClient?._id &&
      dispatch(getOrderAnalytics({ merchantId: currentClient?._id })).then(
        (data) => {
          if (data?.payload?.status === 201) {
            setTotalOrders(data?.payload?.data?.totalOrders);
            setActiveOrders(data?.payload?.data?.activeOrders);
            setCompletedOrders(data?.payload?.data?.completedOrders);
            setPendingOrders(data?.payload?.data?.pendingOrders);
          }
        }
      );
  }, [dispatch, currentClient?._id]);

  useEffect(() => {
    dispatch(getUserById({ id })).then((data) => {
      if (data?.payload?.status !== 200) {
        toast.error(`Unable to get Client Details with id ${id}`);
        navigate("/admin/clients");
        return;
      }
      setCurrentClient(data?.payload?.data?.data);
    });
  }, [dispatch, id, navigate]);

  useEffect(() => {
    dispatch(getFleetType());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Header
        single
        info1="Total Orders"
        info2="Active Orders"
        info3="Completed Orders"
        info4="Pending Orders"
        amount={`${totalOrders}`}
        activeAmount={`${activeOrders}`}
        inactiveAmount={`${completedOrders}`}
        deliveredAmount={`${pendingOrders}`}
        btnText="View Orders"
      />
      <SingleClientDetails
        currentClient={currentClient}
        setCurrentClient={setCurrentClient}
      />
    </DashboardLayout>
  );
};

export default SingleClientContents;
