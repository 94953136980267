import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { injectStore } from "apiInstance";
import authReducer from "appstate/features/auth/authSlice";
import adminReducer from "appstate/features/admin/adminSlice";
import teamReducer from "appstate/features/teams/teamSlice";
import clientReducer from "appstate/features/client/clientSlice";
import pricingReducer from "appstate/features/pricing/pricingSlice";
import fleetReducer from "appstate/features/fleet/fleetSlice";
import orderReducer from "appstate/features/order/orderSlice";
import invoiceReducer from "appstate/features/invoice/invoiceSlice";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "LogisticsHub",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  team: teamReducer,
  client: clientReducer,
  pricing: pricingReducer,
  fleet: fleetReducer,
  order: orderReducer,
  invoice: invoiceReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
injectStore(store);
export const persistor = persistStore(store);
export default store;
