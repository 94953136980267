import { LHIconSearch } from "assets/svgs";
import React from "react";
import Card from "shared/Card";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import PageTitle from "shared/PageTitle";

const ReportHeader = ({ reportOptions, setReportMode }) => {
  return (
    <Card>
      <div>
        <PageTitle title={"Report"} />
      </div>
      <div className="flex flex-wrap gap-3 mb-4 justify-between">
        <div className="relative max-sm:w-full">
          <InputField
            placeholder="Search for client"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-wildSand"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="max-sm:w-full">
          <InputSelect
            admin
            datas={reportOptions}
            name="reportMode"
            divClass="border-2 border-gallery rounded-lg"
            className=" !bg-wildSand"
            onChange={(e) => {
              setReportMode(e.target.value);
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default ReportHeader;
