import React from "react";

const PageTitle = ({ title, className }) => {
  return (
    <div className={`flex gap-4 items-center justify-start ${className}`}>
      <div className="w-[15px] h-[40px] rounded-sm bg-sunGlow"></div>
      <p className="text-[12px] md:text-[20px] font-semibold">{title}</p>
    </div>
  );
};

export default PageTitle;
