import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { toast } from "react-toastify";
import {
  addLocation,
  editLocation,
  getLocationsClusters,
} from "appstate/features/pricing/pricingActions";

const AddNewLocation = ({ hide, selectedRow, isEditing }) => {
  const { isLoading } = useSelector((state) => state.pricing);
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [locObject, setLocObject] = useState("");
  const [address, setAddress] = useState({});
  const [input, setInput] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);
  const [city, setCity] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [country, setCountry] = useState(null);
  const [multiplier, setMultiplier] = useState("");

  const dispatch = useDispatch();

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: [],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: address.place_id,
        },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, address, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      setLng(
        locObject?.geometry?.location?.lng((e) => {
          return e;
        })
      );
      setLat(
        locObject?.geometry?.location?.lat((e) => {
          return e;
        })
      );
      setCity(locObject && locObject?.address_components[0]?.long_name);
      setAddressState(locObject && locObject?.address_components[1]?.long_name);
      setCountry(locObject && locObject?.address_components[2]?.long_name);
    };
    getLngLat();
  }, [locObject]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address?.description) {
      toast.error("Please fill out input address!");
      return;
    } else {
      if (isEditing) {
        dispatch(
          editLocation({
            locationId: selectedRow?._id,
            address: address?.description,
            location: {
              type: address?.description,
              coordinates: [lat, lng],
            },
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success(`${address?.description} edited successfully!`);
            dispatch(getLocationsClusters());
            hide();
          }
        });
      } else {
        dispatch(
          addLocation({
            address: address?.description,
            locLatitude: lat,
            locLongitude: lng,
            city,
            state: addressState,
            country,
            multiplier,
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            toast.success(`${address?.description} created successfully!`);
            dispatch(getLocationsClusters());
            hide();
          }
        });
      }
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <InputField
              placeholder="Enter multiplier e.g 2"
              label="Multiplier for this location - Base fee will be multiplied by the multiplier"
              name="multiplier"
              className="!h-[50px] mb-3"
              onChange={(e) => setMultiplier(e.target.value)}
              value={multiplier}
            />
          </div>
          <div className="min-h-[200px]">
            <InputField
              placeholder="Input Address"
              label="Input location address"
              name="address"
              className="!h-[50px]"
              onChange={(e) => {
                setShowPredictions(true);
                setInput(e.target.value);
                getPlacePredictions({ input: e.target.value });
              }}
              loading={isPlacePredictionsLoading.toString()}
              value={input}
            />
            {showPredictions &&
              !isPlacePredictionsLoading &&
              placePredictions.map((item, idx) => (
                <p
                  className="text-[12px] mb-2 hover:bg-slate-100 p-1 rounded-lg text-gullGray cursor-pointer"
                  key={idx}
                  onClick={() => {
                    setInput(item?.description);
                    setShowPredictions(false);
                    setAddress(item);
                  }}
                >
                  {item.description}
                </p>
              ))}
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : isEditing ? "Edit" : "Create"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewLocation;
