import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import AddNewClientModal from "./modal/addNewClient";
import Details from "./ClientDetails";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "appstate/features/client/clientActions";
import {
  getPricing,
  getPricingRules,
} from "appstate/features/pricing/pricingActions";
import { getFleetType } from "appstate/features/fleet/fleetActions";
import { getTeams } from "appstate/features/teams/teamActions";

const ClientContents = () => {
  const { isLoading, clients } = useSelector((state) => state.client);
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClients());
    dispatch(getPricing());
    dispatch(getPricingRules());
    dispatch(getFleetType());
    dispatch(getTeams());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewClientModal isShown={isShown} hide={() => setIsShown(false)} />
      <Header
        info1="Total"
        info2="Approved"
        info3="Unapproved"
        info4="Declined"
        amount={`${clients?.filter((client) => client.approved).length}`}
        activeAmount={`${clients?.filter((client) => client.approved).length}`}
        inactiveAmount={"0"}
        deliveredAmount={"0"}
        title="Client"
        btnText="New Client"
        onClick={() => setIsShown(true)}
      />
      <Details clients={clients} isLoading={isLoading} />
    </DashboardLayout>
  );
};

export default ClientContents;
