import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import InvoiceHeader from "./InvoiceHeader";
import AddNewInvoiceModal from "./modal/addNewInvoice";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "appstate/features/invoice/invoiceActions";

const InvoiceContents = () => {
  const [isShown, setIsShown] = useState(false);
  const { invoices } = useSelector((state) => state.invoice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoices());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewInvoiceModal isShown={isShown} hide={() => setIsShown(false)} />
      <InvoiceHeader
        title={"Invoice"}
        setIsShown={setIsShown}
        invoices={invoices}
      />
    </DashboardLayout>
  );
};

export default InvoiceContents;
