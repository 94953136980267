import Modal from "layout/modal/Modal";
import AssignClientContent from "./AssignClient";

const AssignClientModal = ({
  isShown,
  hide,
  teamId,
  currentRider,
  setCurrentRider,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Assign Client">
      <AssignClientContent
        hide={hide}
        teamId={teamId}
        currentRider={currentRider}
        setCurrentRider={setCurrentRider}
      />
    </Modal>
  );
};

export default AssignClientModal;
