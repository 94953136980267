import DashboardLayout from "layout/dashboardlayout";
import MainTracking from "./Main";

const TrackingContents = () => {
  return (
    <DashboardLayout className="!mx-0">
      <MainTracking />
    </DashboardLayout>
  );
};

export default TrackingContents;
