import {
  deletePricingRule,
  getPricing,
} from "appstate/features/pricing/pricingActions";
import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InputField from "shared/InputField";
import Loader from "shared/Loader";
import Table from "shared/Table";
import TableControl from "shared/TableControl";

const PricingRuleDetails = ({ pricing }) => {
  const [showControls, setShowControls] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const { isLoading } = useSelector((state) => state.pricing);

  const dispatch = useDispatch();

  const controls = [
    {
      text: "Delete Pricing rule",
      action: () =>
        dispatch(deletePricingRule({ ruleId: selectedRow?._id })).then(
          (data) => {
            if (data?.payload?.status === 200) {
              toast.success("Pricing Rule deleted successfully!");
              dispatch(getPricing());
              setShowControls(false);
            }
          }
        ),
    },
  ];

  const pricingRuleColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: (d) => <p className="flex justify-start ml-4">{d.name}</p>,
    },
    {
      Header: "Pricing rule",
      accessor: (d) => (
        <p className="capitalize flex justify-start ml-4">{d.rule}</p>
      ),
    },
    {
      Header: "Multiplier",
      accessor: (d) => (
        <p className="flex justify-start ml-4">x{d.multiplier}</p>
      ),
    },
    {
      Header: "Base fee",
      accessor: (d) => (
        <p className="flex justify-start ml-4">NGN {d.baseFee}</p>
      ),
    },
    {
      Header: "Locations",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.locations[0]?.address
            ? `${d.locations[0]?.address}
          ${d.fromLocation?.address ? d.fromLocation?.address : ""}
          ${", "}
          ${d.toLocation?.address ? d.toLocation?.address : ""}`
            : "N/A"}
        </p>
      ),
    },
    {
      Header: "Clusters",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.clusters[0] ? d.clusters[0] : "N/A"}
        </p>
      ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="h-[300px] w-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex items-center flex-wrap gap-3 justify-between">
            <div className="relative">
              <InputField
                placeholder="Search for pricing"
                type="text"
                name="search"
                className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
              />
              <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
            </div>
            <div className="flex items-center flex-wrap gap-4">
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <p className="text-xs">Delete</p>
              </div>
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <LHIconFilter />
              </div>
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <p className="text-xs">Name</p>
              </div>
            </div>
          </div>
          <div>
            <Table
              columns={pricingRuleColumns}
              data={pricing}
              type="You don’t have any pricing. Create a new pricing to get started"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PricingRuleDetails;
