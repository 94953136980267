import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import Header from "shared/Header";
import AddNewFleetTypeModal from "../modal/addNewFleetType";
import FleetTypeDetails from "./FleetTypeDetails";
import { useSelector } from "react-redux";

const FleetTypeContents = () => {
  const [isShown, setIsShown] = useState(false);
  const { fleetTypes } = useSelector((state) => state.fleet);

  return (
    <DashboardLayout>
      <AddNewFleetTypeModal isShown={isShown} hide={() => setIsShown(false)} />
      <Header
        info1="All"
        info2="Active"
        info3="Inactive"
        info4="Available"
        amount={`${fleetTypes?.length}`}
        activeAmount={`${fleetTypes?.length}`}
        inactiveAmount={"0"}
        deliveredAmount={"0"}
        title="Fleet Type"
        btnText="New Fleet Type"
        onClick={() => setIsShown(true)}
      />
      <FleetTypeDetails fleetTypes={fleetTypes} />
    </DashboardLayout>
  );
};

export default FleetTypeContents;
