import {
  changeInvoiceStatus,
  getInvoices,
} from "appstate/features/invoice/invoiceActions";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";

const ChangeInvoiceStatus = ({ hide, invoice }) => {
  const [status, setStatus] = useState(invoice?.status?.status);
  const { isLoading } = useSelector((state) => state.invoice);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      changeInvoiceStatus({
        id: invoice?.invoiceId,
        body: {
          status,
        },
      })
    ).then((data) => {
      if (data?.payload?.status === 200) {
        toast.success(`Invoice status successfully changed to "${status}"!`);
        hide();
        dispatch(getInvoices());
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="pb-6">
          <InputSelect
            admin
            placeholder={invoice?.status?.status}
            datas={[
              {
                name: "Fully Paid",
                value: "Fully Paid",
              },
              {
                name: "Partially Paid",
                value: "Partially Paid",
              },
              {
                name: "Sent Physically",
                value: "Sent Physically",
              },
              {
                name: "Sent",
                value: "Sent",
              },
            ].filter((status) => status.value !== invoice?.status?.status)}
            label="Select Status"
            name="status"
            className="!h-[50px]"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Update"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangeInvoiceStatus;
