import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  clientsGet,
  clientsApprove,
  clientsOpeningHoursSet,
  merchantStoreGet,
  merchantUsersGet,
  merchantUsersCreate,
  clientTeamUpdate,
  clientGetByTeamId,
  adminApprovedRateGet,
  approveRateAdd,
  approveRateUpdate,
  outletStoreGet,
} from "appstate/api/client";

export const getClients = createAsyncThunk("client/getClients", clientsGet);
export const approveClient = createAsyncThunk(
  "client/approveClient",
  clientsApprove
);
export const setClientOpeningHours = createAsyncThunk(
  "client/setClientOpeningHours",
  clientsOpeningHoursSet
);
export const getMerchantStores = createAsyncThunk(
  "client/getMerchantStores",
  merchantStoreGet
);
export const getMerchantUsers = createAsyncThunk(
  "client/getMerchantUsers",
  merchantUsersGet
);
export const createMerchantUsers = createAsyncThunk(
  "client/createMerchantUsers",
  merchantUsersCreate
);
export const updateClientTeam = createAsyncThunk(
  "client/updateClientTeam",
  clientTeamUpdate
);
export const getClientByTeamId = createAsyncThunk(
  "client/getClientByTeamId",
  clientGetByTeamId
);
export const getApprovedRateAdmin = createAsyncThunk(
  "client/getApprovedRateAdmin",
  adminApprovedRateGet
);
export const addApprovedRate = createAsyncThunk(
  "client/addApprovedRate",
  approveRateAdd
);
export const updateApprovedRate = createAsyncThunk(
  "client/updateApprovedRate",
  approveRateUpdate
);
export const getOutletStore = createAsyncThunk(
  "client/getOutletStore",
  outletStoreGet
);
