import Modal from "layout/modal/Modal";
import React from "react";
import AssignRider from "./AssignRider";

const AssignRiderModal = ({ isShown, hide, selectedRow }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Assign Rider">
      <AssignRider hide={hide} selectedRow={selectedRow} />
    </Modal>
  );
};

export default AssignRiderModal;
