import { LHIconClose, LHIconSingleDistance } from "assets/svgs";
import { useFormik } from "formik";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";

const Locations = ({ next, hide, setBody }) => {
  const { pricingRules, isLoading, locationsClusters } = useSelector(
    (state) => state.pricing
  );
  const [fromLocationInput, setFromLocationInput] = useState("");
  const [toLocationInput, setToLocationInput] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [selectedAddressesIds, setSelectedAddressesIds] = useState([]);

  const traceSelect = (selected) => {
    console.log(traceSelect);

  }

  const formik = useFormik({
    initialValues: {
      rule: "",
      fromLocation: "",
      toLocation: "",
      locations: [],
    },
    onSubmit: (values) => {
      const body =
        formik.values.rule === "perRoute"
          ? {
              rule: values.rule,
              fromLocation: fromLocationInput,
              toLocation: toLocationInput,
            }
          : formik.values.rule === "perLocation"
          ? {
              rule: values.rule,
              locations: selectedAddressesIds,
            }
          : {
              rule: values.rule,
            };
      if (body.rule === "perRoute") {
        if (!fromLocationInput || !toLocationInput) {
          toast.error("Please input correct locations/clusters!");
          return;
        } else {
          setBody(body);
          next();
        }
      } else if (body.rule === "perLocation") {
        if (selectedAddressesIds < 1) {
          toast.error("Please select locations/clusters!");
          return;
        } else {
          setBody(body);
          next();
        }
      } else {
        setBody(body);
        next();
      }
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex mb-3 items-center gap-8">
        <p>Locations</p>
        <div className="h-[1px] w-full bg-catSkillWhite"></div>
        <p>Fee</p>
        <div className="h-[1px] w-full bg-catSkillWhite"></div>
        <p>Detail</p>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="">
            <InputSelect
              datas={pricingRules}
              label="Pricing rule type"
              placeholder="Pricing rule type"
              name="rule"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rule}
            />
            {formik.touched.rule && formik.errors.rule ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.rule}
              </p>
            ) : null}
          </div>
          {formik.values.rule === "perRoute" ? (
            <div className="flex flex-col gap-6">
              <div className="relative">
                <div>
                  <p className="font-normal text-xs text-midGray mb-[5px]">
                    From location/cluster
                  </p>
                  <Multiselect
                    showArrow={true}
                    customArrow={
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                        <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                      </svg>
                    }
                    customCloseIcon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                          fill="#33383F"
                        />
                      </svg>
                    }
                    selectionLimit={1}
                    name="fromLocation"
                    displayValue="address"
                    onSelect={(selectedList, selectedItem) => {
                      console.log("from location====", selectedItem)
                      setFromLocationInput(selectedItem._id);
                    }}
                    onRemove={() => setFromLocationInput("")}
                    options={locationsClusters}
                    value={fromLocationInput}
                    placeholder="Enter location or cluster here"
                    className="h-[50px] rounded-lg bg-catSkillWhite text-[12px]"
                  />
                </div>
              </div>
              <div className="relative">
                <div className="">
                  <p className="font-normal text-xs text-midGray mb-[5px]">
                    To location/cluster
                  </p>
                  <Multiselect
                    showArrow={true}
                    customArrow={
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                        <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                      </svg>
                    }
                    customCloseIcon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                          fill="#33383F"
                        />
                      </svg>
                    }
                    selectionLimit={1}
                    name="toLocation"
                    displayValue="address"
                    onSelect={(selectedList, selectedItem) => {
                      console.log("to location====", selectedItem)
                      setToLocationInput(selectedItem._id);
                    }}
                    onRemove={() => setToLocationInput("")}
                    options={locationsClusters}
                    value={toLocationInput}
                    placeholder="Enter location or cluster here"
                    className="h-[50px] rounded-lg bg-catSkillWhite text-[12px]"
                  />
                </div>
              </div>
            </div>
          ) : formik.values.rule === "perLocation" ? (
            <div className="relative">
              <div className="border-b pb-4">
                <p className="font-normal text-xs text-midGray mb-[5px]">
                  Add location/cluster
                </p>
                <Multiselect
                  showArrow={true}
                  customArrow={
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                      <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                    </svg>
                  }
                  customCloseIcon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                        fill="#33383F"
                      />
                    </svg>
                  }
                  name="toLocation"
                  displayValue="address"
                  onSelect={(selectedList, selectedItem) => {
                    setSelectedAddresses([...selectedAddresses, selectedItem]);
                    setSelectedAddressesIds([
                      ...selectedAddressesIds,
                      selectedItem._id,
                    ]);
                  }}
                  onRemove={(selectedList, selectedItem) => {
                    setSelectedAddressesIds(
                      selectedAddressesIds.filter(
                        (selected) => selected !== selectedItem._id
                      )
                    );
                    setSelectedAddresses(
                      selectedAddresses.filter(
                        (selected) => selected._id !== selectedItem._id
                      )
                    );
                  }}
                  options={locationsClusters}
                  selectedValues={selectedAddresses}
                  placeholder="Enter location or cluster here"
                  className="h-[50px] rounded-lg bg-catSkillWhite text-[12px]"
                />
              </div>
              {selectedAddresses.length > 0 && (
                <div className="">
                  <div className="border-b py-4">
                    <p className="text-[12px] my-2">Locations</p>
                    {selectedAddresses?.map(
                      (address) =>
                        !address.isCluster && (
                          <div
                            key={address?._id}
                            className="flex justify-between"
                          >
                            <div className="flex justify-start gap-2">
                              <LHIconSingleDistance />
                              <p className="text-[12px]">{address?.address}</p>
                            </div>
                            <LHIconClose
                              className="cursor-pointer"
                              onClick={() => {
                                setSelectedAddressesIds(
                                  selectedAddressesIds.filter(
                                    (selected) => selected !== address._id
                                  )
                                );
                                setSelectedAddresses(
                                  selectedAddresses.filter(
                                    (selected) => selected._id !== address._id
                                  )
                                );
                              }}
                            />
                          </div>
                        )
                    )}
                  </div>
                  <p className="text-[12px] my-2">Clusters</p>
                  {selectedAddresses?.map(
                    (address) =>
                      address.isCluster && (
                        <div
                          key={address?._id}
                          className="flex justify-between"
                        >
                          <div className="flex justify-start gap-2">
                            <LHIconSingleDistance />
                            <p className="text-[12px]">{address?.address}</p>
                          </div>
                          <LHIconClose
                            className="cursor-pointer"
                            onClick={() => {
                              setSelectedAddressesIds(
                                selectedAddressesIds.filter(
                                  (selected) => selected !== address._id
                                )
                              );
                              setSelectedAddresses(
                                selectedAddresses.filter(
                                  (selected) => selected._id !== address._id
                                )
                              );
                            }}
                          />
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          ) : formik.values.rule === "perDistance" ? (
            <div></div>
          ) : null}
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : "Next"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Locations;
