import Api from "apiInstance";
import { toast } from "react-toastify";

export const invoiceCreate = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/invoice`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const invoicesGet = async () => {
  try {
    const response = await Api({
      method: "get",
      url: `/invoice`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const invoicesGetOne = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/invoice/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const invoiceStatusChange = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/invoice/${id}/status`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const oneOrderAddToInvoice = async ({ id, orderId }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/invoice/addOrder/${id}/${orderId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const multipleOrdersAddToInvoice = async ({ id, orderIds }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/invoice/addOrders/${id}`,
      data: orderIds,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};

export const orderRemoveFromInvoice = async ({ id, orderId }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/invoice/${id}/${orderId}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.data?.message + "!");
  }
};
