import { createSlice } from "@reduxjs/toolkit";

import {
  getPricing,
  getPricingRules,
  getLocationsClusters,
  addLocation,
  addCluster,
  editLocation,
  deleteCluster,
  deleteLocation,
  addPricing,
  deletePricingRule,
  editCluster,
} from "./pricingActions";

import {
  addApprovedRate,
  updateApprovedRate,
  getApprovedRateAdmin,
} from "../client/clientActions";

const initialState = {
  isLoading: false,
  pricing: [],
  pricingRules: [],
  locationsClusters: [],
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPricing.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPricing.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pricing = action.payload?.data?.data;
    });
    builder.addCase(getPricing.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPricingRules.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPricingRules.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pricingRules = action.payload?.data?.data;
    });
    builder.addCase(getPricingRules.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLocationsClusters.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLocationsClusters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locationsClusters = action.payload?.data?.data;
    });
    builder.addCase(getLocationsClusters.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addLocation.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addLocation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addCluster.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCluster.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addCluster.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editLocation.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editLocation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteLocation.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocation.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCluster.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCluster.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCluster.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addPricing.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPricing.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addPricing.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deletePricingRule.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deletePricingRule.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deletePricingRule.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editCluster.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editCluster.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editCluster.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addApprovedRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addApprovedRate.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addApprovedRate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateApprovedRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateApprovedRate.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateApprovedRate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApprovedRateAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApprovedRateAdmin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getApprovedRateAdmin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default pricingSlice.reducer;
