import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import AccessControl from "./AccessControl";
import Details from "./DashboardDetails";
import { useDispatch, useSelector } from "react-redux";
import { getAdminById, getAdmins } from "appstate/features/admin/adminActions";
import { toast } from "react-toastify";

const AccessControlContents = () => {
  const { activeAdmin } = useSelector((state) => state.auth);

  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [admins, setAdmins] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdmins()).then((data) => {
      if (data?.payload?.status === 200) {
        setAdmins(data?.payload?.data);
      }
    });
    dispatch(getAdminById({ id: activeAdmin?._id })).then((data) => {
      if (data?.payload?.status === 200) {
        setCurrentAdmin(data?.payload?.data?.data);
      } else {
        toast.error("Unable to get admin details");
        return;
      }
    });
  }, [dispatch, activeAdmin?._id]);

  return (
    <DashboardLayout>
      <AccessControl setAdmins={setAdmins} currentAdmin={currentAdmin} />
      <Details
        currentAdmin={currentAdmin}
        admins={admins}
        setAdmins={setAdmins}
      />
    </DashboardLayout>
  );
};

export default AccessControlContents;
