import { LHIconTrash } from "assets/svgs";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import Table from "shared/Table";
import { useDispatch } from "react-redux";
import { getMerchantStores } from "appstate/features/client/clientActions";

const ClientStores = ({ currentClient }) => {
  const [merchantStores, setMerchantStores] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    currentClient &&
      dispatch(getMerchantStores({ id: currentClient?._id, page: 0 })).then(
        (data) => {
          setMerchantStores(data?.payload?.data?.data?.data);
        }
      );
  }, [dispatch, currentClient, currentClient?._id]);

  const usersColumns = [
    {
      Header: "Name",
      accessor: (d) => <p className="flex justify-start ml-4">{d?.name}</p>,
    },
    {
      Header: "Address",
      accessor: (d) => <p className="flex justify-start ml-4">{d.address}</p>,
    },
    {
      Header: "Manager",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.manager?.firstName + " " + d.manager?.lastName}
        </p>
      ),
    },
    {
      Header: " ",
      accessor: (d) => <LHIconTrash className="cursor-pointer w-4 h-4" />,
    },
  ];

  return (
    <Card className="!h-fit">
      <div className="flex flex-wrap gap-2 justify-between items-center">
        <p className="text-[18px] font-medium">Sub store(s)</p>
      </div>
      <div>
        <Table
          columns={usersColumns}
          data={merchantStores}
          type="You don’t have any outlet. Create a new outlet to get started"
        />
      </div>
    </Card>
  );
};

export default ClientStores;
