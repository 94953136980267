import { useState, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Dropdown2 = ({
  selectedItem,
  setSelectedItem,
  items,
  onRenderItems,
  placeHolder,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderSelectedItem = () => {
    if (typeof selectedItem === "object" && selectedItem !== null) {
      return (
        selectedItem.title || selectedItem.name || JSON.stringify(selectedItem)
      );
    }
    return selectedItem;
  };

  const renderItem = (item) => {
    if (typeof item === "object" && item !== null) {
      return item.title || item.name || JSON.stringify(item);
    }
    return item;
  };

  return (
    <div ref={ref} className="w-full relative ">
      <button
        type="button"
        onClick={toggleDropdown}
        className="w-full inline-flex justify-between items-center rounded-lg outline-none text-sm "
      >
        {selectedItem !== null ? (
          <div className="flex items-center">
            <span className="text-sm">{renderSelectedItem()}</span>
          </div>
        ) : (
          <span className="text-gullGray">{placeHolder}</span>
        )}
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>

      {isOpen && items?.length > 0 && (
        <div className="absolute h-auto overflow-hidden z-[9999999999] mt-[10px] bg-white shadow-2xl rounded-xl w-full">
          <ul
            tabIndex="-1"
            role="menu"
            aria-labelledby="menu-options"
            className="pt-1 pb-2"
          >
            {items.map((item, index) => (
              <li
                onClick={() => {
                  setSelectedItem(item);
                  setIsOpen(false);
                }}
                className="text-left p-3 text-sm text-fiord font-semibold hover:text-black cursor-pointer hover:bg-[#F4F4F4] mx-3 rounded-lg"
                role="menuitem"
                key={index}
              >
                {!onRenderItems ? renderItem(item) : onRenderItems(item)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
