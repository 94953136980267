import { LHIconFilter, LHIconSearch } from "assets/svgs";
import React from "react";
import Button from "shared/Button";
import Card from "shared/Card";
import DateModal from "shared/DateModal";
import InputField from "shared/InputField";
import PageTitle from "shared/PageTitle";

const StatsHeader = () => {
  return (
    <Card>
      <div className="flex flex-wrap gap-3 mb-4 justify-between">
        <PageTitle title={"Stats"} />
        <div className="flex justify-between">
          <div>
            <Button
              // onClick={onNewPricingClick}
              className="!bg-congressBlue !h-[45px] !text-white"
            >
              Download Report
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-3 mb-4 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for client"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gallery rounded-lg flex justify-center items-center">
            <LHIconFilter />
          </div>
          <DateModal execute={() => {}} />
        </div>
      </div>
    </Card>
  );
};

export default StatsHeader;
