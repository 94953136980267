import * as Yup from "yup";
const addAdminValidationSchema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  // mobile: Yup.number().required("This field is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Please confirm password")
    .required("Passwords do not match"),
  role: Yup.string().required("This field is required"),
});

export { addAdminValidationSchema };
