import { LHTrackingBike, LHTrackingBus, LHTrackingCar } from "assets/images";
import { LHIconGridIcon } from "assets/svgs";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import Button from "shared/Button";
import PageTitle from "shared/PageTitle";
import MainTrackingGrid from "./MainGrid";
import { useNavigate } from "react-router-dom";
import { compose, withStateHandlers, withState, withHandlers } from "recompose";
import { getFilterableOrders } from "appstate/features/order/orderActions";

const MainTracking = () => {
  const [grid, setGrid] = useState(false);
  const [currentOrders, setCurrentOrders] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getFilterableOrders({ page: 1, assigned: true, delivered: false })
    ).then((data) => {
      setCurrentOrders(data?.payload?.data);
    });
  }, [dispatch]);

  const icons = {
    bike: {
      icon: LHTrackingBike,
    },
    car: {
      icon: LHTrackingCar,
    },
    bus: {
      icon: LHTrackingBus,
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  });

  const center = {
    lat: 6.534347899999999,
    lng: 3.3848981,
  };

  const MyMapComponent = compose(
    withState("selectedPlace", "updateSelectedPlace", null),
    withState("orderDetails", "setOrderDetails", null),
    withStateHandlers(
      () => ({
        isOpen: false,
        selectedOrder: null,
      }),
      {
        onToggleOpen:
          ({ isOpen }) =>
          () => ({
            isOpen: !isOpen,
          }),
      }
    ),
    withHandlers(() => {
      return {
        setOrder:
          ({ updateSelectedPlace }) =>
          (key) => {
            updateSelectedPlace(key);
          },
        setOrderDetails:
          ({ setOrderDetails }) =>
          (orderDetails) => {
            setOrderDetails(orderDetails);
          },
      };
    })
  )((props) => {
    return (
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={center}
        zoom={11}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {currentOrders?.map((order, idx) => (
          <Marker
            key={idx}
            onClick={() => navigate(`/admin/orders/${order?._id}`)}
            onMouseOver={() => {
              props.onToggleOpen();
              props.setOrder(idx);
              props.setOrderDetails(order);
            }}
            onMouseOut={props.onToggleOpen}
            position={
              order?.currentLocation
                ? {
                    lat: order?.currentLocation?.coordinates[1]
                      ? order?.currentLocation?.coordinates[1]
                      : 6.534347899999999,
                    lng: order?.currentLocation?.coordinates[0]
                      ? order?.currentLocation?.coordinates[0]
                      : 3.3848981,
                  }
                : {
                    lat: order?.pickupLocation?.coordinates[1]
                      ? order?.pickupLocation?.coordinates[1]
                      : 6.534347899999999,
                    lng: order?.pickupLocation?.coordinates[0]
                      ? order?.pickupLocation?.coordinates[0]
                      : 3.3848981,
                  }
            }
            icon={icons.bike.icon}
          >
            {props?.isOpen && props?.selectedPlace === idx && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <div className="rounded-lg bg-white p-1">
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">Order Id:</p>
                    <p className="text-fiord text-[14px]">
                      {props?.orderDetails?.orderId}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">Order status:</p>
                    <p className="text-fiord text-[14px]">
                      {props?.orderDetails?.status?.status}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="text-gullGray text-[14px]">
                      {props?.orderDetails?.currentAddress
                        ? `Current Location: ${props?.orderDetails?.currentAddress}`
                        : `Pickup Location: ${props?.orderDetails?.pickupAddress}`}
                    </p>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  });

  return (
    <>
      {grid ? (
        <MainTrackingGrid
          currentOrders={currentOrders}
          setCurrentOrders={setCurrentOrders}
          setGrid={setGrid}
        />
      ) : (
        <div className="w-full h-full relative">
          {isLoaded && <MyMapComponent />}
          <div className="absolute top-2 left-4 flex gap-4 justify-between">
            <PageTitle
              className="bg-white px-2 py-1 rounded-lg"
              title="Tracking"
            />
            <Button onClick={() => setGrid(true)}>
              <div className="flex gap-1 items-center">
                <LHIconGridIcon />
                <p>Grid</p>
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MainTracking;
