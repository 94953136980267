import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import Loader from "shared/Loader";
import Table from "shared/Table";
import TableControl from "shared/TableControl";
import ChangeInvoiceStatusModal from "../modal/changeInvoiceStatus";
import AddNewOrderModal from "../modal/addNewOrder";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const All = ({ invoices }) => {
  const [showControls, setShowControls] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const { isLoading } = useSelector((state) => state.pricing);
  const [isShown, setisShown] = useState(false);
  const [isAddModalShown, setIsAddModalShown] = useState(false);

  // const dispatch = useDispatch();

  const controls = [
    {
      text: "Add order",
      action: () => setIsAddModalShown(true),
    },
    // {
    //   text: "Remove order",
    //   action: () => {},
    // },
    {
      text: "Change status",
      action: () => setisShown(true),
    },
  ];

  const invoiceColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Invoice ID",
      accessor: (d) => (
        <Link
          className="capitalize flex justify-start ml-4"
          to={`/admin/invoice/${d.invoiceId}`}
        >
          <p>{d.invoiceId}</p>
        </Link>
      ),
    },
    {
      Header: "Recipients",
      accessor: (d) => (
        <Link
          className="capitalize flex justify-start ml-4"
          to={`/admin/invoice/${d.invoiceId}`}
        >
          <p>{d.merchant?.merchantName}</p>
        </Link>
      ),
    },
    {
      Header: "Billing Address",
      accessor: (d) => (
        <Link
          className="capitalize flex justify-start ml-4"
          to={`/admin/invoice/${d.invoiceId}`}
        >
          <p>{d.billingAddress}</p>
        </Link>
      ),
    },
    {
      Header: "Date",
      accessor: (d) => (
        <Link
          className="capitalize flex justify-start ml-4"
          to={`/admin/invoice/${d.invoiceId}`}
        >
          <p>
            {format(
              new Date(d.status?.date?.split("T")[0].split("-").join("/")),
              "EEEE, do MMMM yyyy"
            )}
          </p>
        </Link>
      ),
    },
    {
      Header: "Total Fee",
      accessor: (d) => (
        <Link
          className="capitalize flex justify-start ml-4"
          to={`/admin/invoice/${d.invoiceId}`}
        >
          <p>NGN {d.totalAmount}</p>
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: (d) =>
        d.twoWays ? (
          <Link to={`/admin/invoice/${d.invoiceId}`}>
            <p className="flex justify-start ml-4 w-fit p-2 rounded-lg bg-gallery">
              P
            </p>
          </Link>
        ) : (
          <Link to={`/admin/invoice/${d.invoiceId}`}>
            <p className="flex justify-start ml-4 w-fit p-2 rounded-lg bg-gallery">
              D
            </p>
          </Link>
        ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.status?.status === "Fully Paid" ? (
          <Link to={`/admin/invoice/${d.invoiceId}`}>
            <div className="flex justify-start ml-4">
              <div className="bg-algaeGreen text-white text-[12px] rounded-lg py-1 px-2">
                {d.status?.status}
              </div>
            </div>
          </Link>
        ) : d.status?.status === "Sent" ? (
          <Link to={`/admin/invoice/${d.invoiceId}`}>
            <div className="flex justify-start ml-4">
              <div className="bg-varden text-koromiko text-[12px] rounded-lg py-1 px-2">
                {d.status?.status}
              </div>
            </div>
          </Link>
        ) : (
          <Link to={`/admin/invoice/${d.invoiceId}`}>
            <div className="flex justify-start ml-4">
              <div className="bg-lightMelrose text-fiord text-[12px] rounded-lg py-1 px-2">
                {d.status?.status}
              </div>
            </div>
          </Link>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <ChangeInvoiceStatusModal
        isShown={isShown}
        hide={() => {
          setisShown(false);
          setShowControls(false);
        }}
        invoice={selectedRow}
      />
      <AddNewOrderModal
        isShown={isAddModalShown}
        hide={() => {
          setIsAddModalShown(false);
          setShowControls(false);
        }}
        invoice={selectedRow}
      />
      {isLoading ? (
        <div className="h-[300px] w-full">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex items-center flex-wrap gap-3 justify-between">
            <div className="relative">
              <InputField
                placeholder="Search for Invoice"
                type="text"
                name="search"
                className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
              />
              <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
            </div>
            <div className="flex items-center flex-wrap gap-4">
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <p className="text-xs">Delete</p>
              </div>
              <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
                <LHIconFilter />
              </div>
              <div className="">
                <InputSelect
                  admin
                  datas={[
                    {
                      name: "Paid",
                      value: "paid",
                    },
                    {
                      name: "Physically Sent",
                      value: "SentPhysical",
                    },
                    {
                      name: "Partial Paid",
                      value: "PartialPaid",
                    },
                    {
                      name: "Request Paid",
                      value: "RequestPaid",
                    },
                    {
                      name: "Sent",
                      value: "sent",
                    },
                  ]}
                  placeholder="Status"
                  name="status"
                  divClass="border-2 border-gullGray rounded-lg"
                  className=" !bg-white"
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div>
            <Table
              columns={invoiceColumns}
              data={invoices}
              type="You don’t have any invoice. Create a new invoice to get started"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default All;
