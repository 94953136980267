import Modal from "layout/modal/Modal";
import UpdateClientTeamContents from "./UpdateClientTeam";

const UpdateClientTeamModal = ({
  isShown,
  hide,
  currentClient,
  setCurrentClient,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Edit client team">
      <UpdateClientTeamContents
        hide={hide}
        currentClient={currentClient}
        setCurrentClient={setCurrentClient}
      />
    </Modal>
  );
};

export default UpdateClientTeamModal;
