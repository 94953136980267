import {
  addFleetType,
  getFleetType,
} from "appstate/features/fleet/fleetActions";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import * as Yup from "yup";

const AddNewFleetType = ({ hide }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.fleet);

  const formik = useFormik({
    initialValues: {
      fleetType: "",
    },
    validationSchema: Yup.object({
      fleetType: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const body = {
        name: values.fleetType,
      };
      dispatch(addFleetType(body)).then((data) => {
        if (data?.payload?.status === 201) {
          toast.success(`${body.name} fleet type created successfully!`);
          dispatch(getFleetType());
          hide();
        }
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
        <div className="">
          <InputField
            placeholder="Fleet Type"
            name="fleetType"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fleetType}
          />
          {formik.touched.fleetType && formik.errors.fleetType ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.fleetType}
            </p>
          ) : null}
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewFleetType;
