import Api from "apiInstance";
import { toast } from "react-toastify";

export const reportMerchantDownload = async ({
  merchantId,
  merchantOutletId,
  riderAuthId,
  riderMobile,
  startDate,
  endDate,
  delivered,
  active,
  pending,
}) => {
  try {
    const response = await Api({
      method: "get",
      headers: {
        "Content-Type": `text/csv`,
      },
      url: `/analytics/orders/merchantReport${
        merchantId ||
        merchantOutletId ||
        riderAuthId ||
        riderMobile ||
        startDate ||
        endDate ||
        delivered ||
        active ||
        pending
          ? "?"
          : ""
      }${merchantId ? `merchantId=${merchantId}` : ``}${
        merchantOutletId ? `&merchantOutletId=${merchantOutletId}` : ``
      }${riderAuthId ? `&riderAuthId=${riderAuthId}` : ``}${
        riderMobile ? `&riderMobile=${riderMobile}` : ``
      }${startDate ? `${merchantId ? "&" : ""}startDate=${startDate}` : ``}${
        endDate ? `&endDate=${endDate}` : ``
      }${delivered ? `&delivered=${delivered}` : ``}${
        active ? `&active=${active}` : ``
      }${pending ? `&pending=${pending}` : ``}`,
    });
    return {
      data: response?.data,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const breakdownOrders = async ({
  merchantId,
  merchantOutletId,
  riderAuthId,
  forMonth,
  month,
  last7Days,
  byOutlet,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/analytics/orders/breakdown${
        merchantId ||
        merchantOutletId ||
        riderAuthId ||
        forMonth ||
        month ||
        last7Days ||
        byOutlet
          ? "?"
          : ""
      }${merchantId ? `merchantId=${merchantId}` : ``}${
        merchantOutletId ? `&merchantOutletId=${merchantOutletId}` : ``
      }${riderAuthId ? `riderAuthId=${riderAuthId}` : ``}${
        forMonth ? `&forMonth=${forMonth}` : ``
      }${month ? `&month=${month}` : ``}${
        last7Days ? `&last7Days=${last7Days}` : ``
      }${byOutlet ? `&byOutlet=${byOutlet}` : ``}`,
    });
    return {
      data: response?.data,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const metricsOrders = async ({
  merchantId,
  merchantOutletId,
  riderAuthId,
  forRider,
  month,
  byOutlet,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/analytics/orders/metrics${
        merchantId ||
        merchantOutletId ||
        riderAuthId ||
        forRider ||
        month ||
        byOutlet
          ? "?"
          : ""
      }${merchantId ? `merchantId=${merchantId}` : ``}${
        merchantOutletId ? `&merchantOutletId=${merchantOutletId}` : ``
      }${riderAuthId ? `&riderAuthId=${riderAuthId}` : ``}${
        forRider ? `&forRider=${forRider}` : ``
      }${month ? `&month=${month}` : ``}${
        byOutlet ? `&byOutlet=${byOutlet}` : ``
      }`,
    });
    return {
      data: response?.data,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
