import { LHIconGreenArrowUp } from "assets/svgs";
import React from "react";
import { motion } from "framer-motion";

const DashboardCard = ({
  cardColor,
  innerColor,
  icon,
  info,
  onClick,
  amount,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.5 }}
      onClick={onClick}
      className={`max-[650px]:w-full w-[253px] p-8 ${cardColor} rounded-lg flex flex-col cursor-pointer`}
    >
      <div className="flex justify-between">
        <div
          className={`w-[50px] ${innerColor} h-[50px] flex items-center justify-center rounded-full`}
        >
          {icon}
        </div>
        <div className="flex items-center gap-1">
          <span className="text-algaeGreen text-[10px] flex">
            <LHIconGreenArrowUp /> 37.8%
          </span>
          <p className="text-[10px]">this week</p>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <p className="text-[10px] flex justify-end">{info}</p>
        <p className="text-[34px] font-semibold">{amount}</p>
      </div>
    </motion.div>
  );
};

export default DashboardCard;
