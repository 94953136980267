import { ordersBreakDown } from "appstate/features/analytics/analyticsActions";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "shared/InputSelect";

const StorePerformance = () => {
  const { clients } = useSelector((state) => state.client);

  const [analyticsObj, setAnalyticsObj] = useState([]);
  const [chartType, setChartType] = useState("This year");
  const [currentClient, setCurrentClient] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersBreakDown({ merchantId: "" })).then((data) => {
      setAnalyticsObj(data?.payload?.data?.data);
    });
  }, [dispatch]);

  const monthNames = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const groupedData = analyticsObj?.reduce((result, item) => {
    const month = monthNames[item?._id];
    result[month] = (result[month] || 0) + item?.totalDeliveries;
    return result;
  }, {});

  const month = new Date().toLocaleString("default", { month: "short" });
  const sortedData = analyticsObj?.sort((a, b) => a._id - b._id);

  const resultData =
    chartType === "This year"
      ? Array.from({ length: 12 }, (_, i) => ({
          month: i + 1,
          totalDeliveries: groupedData[monthNames[i + 1]] || 0,
        }))
      : chartType === "This month"
      ? Array.from({ length: 31 }, (_, index) => {
          const day = index + 1;
          const dayData = sortedData.find((item) => item._id === day);

          const totalDeliveries = dayData?.totalDeliveries
            ? dayData?.totalDeliveries
            : 0;

          return { day: month + " " + day, totalDeliveries };
        })
      : chartType === "Last 7 days"
      ? Array.from({ length: 7 }, (_, index) => {
          const day = index + 1;
          const dayData = sortedData?.find((item) => item?._id === day);

          const totalDeliveries = dayData?.totalDeliveries
            ? dayData?.totalDeliveries
            : 0;

          return { day: day, totalDeliveries };
        })
      : [];

  const categories =
    chartType === "This year"
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ]
      : chartType === "This month"
      ? resultData?.map((item) => item?.day)
      : chartType === "Last 7 days"
      ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      : [];

  const chartOptions = {
    series: [
      {
        name: "Total Deliveries",
        data: resultData?.map((item) => item?.totalDeliveries),
      },
    ],
    options: {
      chart: {
        id: "column-chart",
      },
      plotOptions: {
        bar: {
          distributed: true,
          barHeight: "85%",
        },
      },
      colors: ["#B5E4CA", "#FFBC99"],
      xaxis: {
        categories: categories,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val?.toFixed(0);
          },
        },
        tickAmount: 4,
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      <div className="flex flex-wrap gap-4 justify-between items-center mb-8">
        <div className={`flex gap-4 items-center justify-start`}>
          <div className="w-[16px] h-[32px] rounded-sm bg-pickledBlueWood"></div>
          <p className="text-[12px] md:text-[20px] font-semibold">
            Total Deliveries
          </p>
        </div>
        <div className="flex gap-3 flex-wrap">
          <InputSelect
            admin
            datas={clients}
            placeholder={"All Stores"}
            name="client"
            divClass={"!border-gallery border-2 rounded-xl"}
            className={"!bg-white"}
            onChange={(e) => {
              e.target.value
                ? setCurrentClient(e.target.value)
                : setCurrentClient(null);
              dispatch(
                ordersBreakDown({
                  merchantId: e.target?.value,
                  forMonth: chartType === "This month" ? true : false,
                  last7Days: chartType === "Last 7 days" ? true : false,
                })
              ).then((data) => {
                setAnalyticsObj(data?.payload?.data?.data);
              });
            }}
          />
          <InputSelect
            onChange={(e) => {
              if (e.target.value === "Last 7 days") {
                dispatch(
                  ordersBreakDown({
                    merchantId: currentClient,
                    last7Days: true,
                  })
                ).then((data) => {
                  setAnalyticsObj(data?.payload?.data?.data);
                });
              } else if (e.target.value === "This month") {
                dispatch(
                  ordersBreakDown({
                    merchantId: currentClient,
                    forMonth: true,
                    month: new Date().getMonth(),
                  })
                ).then((data) => {
                  setAnalyticsObj(data?.payload?.data?.data);
                });
              } else {
                dispatch(ordersBreakDown({ merchantId: currentClient })).then(
                  (data) => {
                    setAnalyticsObj(data?.payload?.data?.data);
                  }
                );
              }
              setChartType(e.target.value);
            }}
            datas={[
              { address: "This year", value: "This year" },
              { address: "Last 7 days", value: "Last 7 days" },
              { address: "This month", value: "This month" },
            ]}
            name="period"
            divClass={"!border-gallery border-2 rounded-xl"}
            className={"!bg-white"}
          />
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default StorePerformance;
