import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminsGet,
  adminEdit,
  adminGetById,
  adminDelete,
  adminRegister,
} from "appstate/api/admin";

export const getAdmins = createAsyncThunk("admins/getAdmins", adminsGet);
export const editAdmin = createAsyncThunk("admins/editAdmin", adminEdit);
export const getAdminById = createAsyncThunk(
  "admins/getAdminById",
  adminGetById
);
export const deleteAdmin = createAsyncThunk("admins/deleteAdmin", adminDelete);
export const registerAdmin = createAsyncThunk(
  "admins/registerAdmin",
  adminRegister
);
