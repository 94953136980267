import { getUserById } from "appstate/features/auth/authActions";
import { getFleetByRiderMobile } from "appstate/features/fleet/fleetActions";
import {
  getOneOrder,
  uploadProofOfDelivery,
} from "appstate/features/order/orderActions";
import { LHEmptyImage } from "assets/images";
import { LHIconTruckDriver } from "assets/svgs";
import AssignRiderToFleetModal from "features/fleet/modal/assignRiderToFleet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import Card from "shared/Card";
import GoBack from "shared/GoBack";
import Loader from "shared/Loader";

const SingleOrderInfo = ({ currentOrder, setCurrentOrder }) => {
  const [isShown, setIsShown] = useState(false);
  const [orderClient, setOrderClient] = useState(null);
  const [orderFleet, setOrderFleet] = useState(null);
  const { isLoading } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  const setCurrentOrders = () => {};

  const uploadImage = (e) => {
    e.preventDefault();
    const fileTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (e.target.files.length && fileTypes.includes(e.target.files[0].type)) {
      const uploadFiles = [];
      for (let i = 0; i < e.target.files.length; i++) {
        uploadFiles.push(e.target.files[i]);
      }
      const formData = new FormData();
      formData.append("files", e.target.files[0]);
      dispatch(
        uploadProofOfDelivery({ id: currentOrder?._id, body: formData })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          dispatch(getOneOrder({ id: currentOrder?._id })).then((data) =>
            setCurrentOrder(data?.payload?.data?.data)
          );
          toast.success("Proof of delivery uploaded");
        }
      });
    } else {
      toast.error("Please select a image file (PNG or JPEG or JPG)");
      return;
    }
  };

  useEffect(() => {
    currentOrder?.merchant?.merchantId &&
      dispatch(getUserById({ id: currentOrder?.merchant?.merchantId })).then(
        (data) => setOrderClient(data?.payload?.data?.data)
      );
  }, [dispatch, currentOrder?.merchant?.merchantId]);

  useEffect(() => {
    currentOrder?.assignedRider?.mobile &&
      dispatch(
        getFleetByRiderMobile({
          mobile: `+${currentOrder?.assignedRider?.mobile}`,
        })
      ).then((data) => {
        setOrderFleet(data?.payload?.data?.data[0]);
      });
  }, [dispatch, currentOrder]);

  return (
    <div className="">
      <AssignRiderToFleetModal
        order={currentOrder}
        reassign
        isShown={isShown}
        hide={() => setIsShown(false)}
        setCurrentOrder={setCurrentOrder}
        setCurrentOrders={setCurrentOrders}
      />
      <Card className="!mb-4">
        <div className="flex justify-between items-center">
          <GoBack />
          <p className="text-congressBlue text-[13px] font-medium">Edit</p>
        </div>
        <div className="overflow-x-auto w-full">
          <div
            className={`mb-2 flex gap-2 ${
              currentOrder?.statusHistory?.length === 1
                ? "justify-center"
                : "justify-between"
            } px-4`}
          >
            {currentOrder?.statusHistory?.map((status) => (
              <div key={status?._id} className="flex flex-col items-center">
                <p className="font-bold pb-3">{status?.status}</p>
                <p className="text-gullGray text-[10px]">
                  {status?.date?.split("T")[0].split("-").reverse().join("-")}
                </p>
                <p className="text-gullGray text-[10px]">
                  {" "}
                  {status?.date?.split("T")[1]?.split(".")[0]}
                </p>
              </div>
            ))}
          </div>
          <div className="flex px-4 mb-4">
            <div className="w-full flex flex-grow">
              {currentOrder?.statusHistory?.map((status) => (
                <div
                  key={status?._id}
                  className="max-md:min-w-[70px] w-full flex items-center"
                >
                  <div className="w-full h-[1px] bg-malachite"></div>
                  <div className="w-[30px] h-[20px] bg-malachite"></div>
                  <div className="w-full h-[1px] bg-malachite"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
      <div className="flex max-[950px]:flex-wrap gap-2">
        <div className="w-full min-[950px]:w-3/5 flex flex-col gap-3">
          <Card className="!h-fit !w-full">
            <p className="text-fiord font-medium text-[14px]">
              Recipient Information
            </p>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Names</p>
              <p className="text-gullGray text-[14px]">
                {currentOrder?.recipient?.name}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Phone number</p>
              <p className="text-gullGray text-[14px]">
                +{currentOrder?.recipient?.mobile}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Email Address</p>
              <p className="text-gullGray text-[14px]">
                {currentOrder?.recipient?.email
                  ? currentOrder?.recipient?.email
                  : "N/A"}
              </p>
            </div>
          </Card>
          <Card className="!h-fit">
            <p className="text-fiord font-medium text-[14px]">
              Client Information
            </p>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Store name</p>
              <p className="text-gullGray text-[14px]">
                {currentOrder?.merchant?.merchantName}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Number of outlets</p>
              <p className="text-gullGray text-[14px]">
                {orderClient?.noOfOutlet}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Sector</p>
              <p className="text-gullGray text-[14px]">{orderClient?.sector}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Deliveries per day</p>
              <p className="text-gullGray text-[14px]">
                {orderClient?.deliveriesPerDay}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Phone number</p>
              <p className="text-gullGray text-[14px]">
                {orderClient?.authId?.mobile
                  ? orderClient?.authId?.mobile
                  : "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Email address</p>
              <p className="text-gullGray text-[14px]">
                {orderClient?.authId?.email
                  ? orderClient?.authId?.email
                  : "N/A"}
              </p>
            </div>
          </Card>
          <Card className="!h-fit">
            <div className="flex justify-between">
              <p className="text-fiord font-medium text-[14px]">
                Proof of Delivery
              </p>
              <label>
                <p className="text-fiord font-medium text-[14px] cursor-pointer">
                  Upload
                </p>
                <input
                  className="hidden"
                  name="proofOfDelivery"
                  type="file"
                  onChange={uploadImage}
                />
              </label>
            </div>
            {!currentOrder?.proofOfDelivery?.length ? (
              <div className="flex flex-col items-center">
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <img src={LHEmptyImage} alt="Add a proof of delivery" />
                    <p className="text-[12px]">No image available</p>
                  </>
                )}
              </div>
            ) : isLoading ? (
              <Loader />
            ) : (
              <div className="flex gap-2 overflow-x-auto">
                {currentOrder?.proofOfDelivery?.map((img, idx) => (
                  <div key={idx} className="min-w-[90%] h-[200px]">
                    <img
                      src={img}
                      alt="LH-deliveryProof"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            )}
          </Card>
          <Card className="!overflow-x-auto">
            <p className="text-fiord font-medium text-[14px]">Order History</p>
            <table className="w-full">
              <thead className="border-y text-left">
                <tr>
                  <th className="text-gullGray py-2 text-[14px] font-normal">
                    Date
                  </th>
                  <th className="text-gullGray py-2 pr-1 text-[14px] font-normal">
                    Description
                  </th>
                  <th className="text-gullGray py-2 text-[14px] font-normal">
                    By
                  </th>
                  <th className="text-gullGray py-2 text-[14px] font-normal">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="text-left">
                {currentOrder?.statusHistory?.map((status) => (
                  <tr key={status?._id}>
                    <td className="text-silver py-1 pr-1 whitespace-nowrap text-[12px]">
                      {status?.date?.split("T")[0]}
                    </td>
                    <td className="text-silver text-[12px]">
                      {status?.status}
                    </td>
                    <td className="text-silver whitespace-nowrap pr-1 text-[12px]">
                      {status?.user?.firstName} {status?.user?.lastName}
                    </td>
                    <td className="text-silver text-[12px]">
                      {status?.date?.split("T")[1]?.split(".")[0]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="w-full min-[950px]:w-2/5 flex flex-col gap-3">
          <Card>
            <div className="flex justify-between">
              <p className="text-fiord font-medium text-[14px]">
                Order Information
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Order ID</p>
              <p className="text-gullGray text-[14px]">
                {currentOrder?.orderId}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Type</p>
              <p className="text-gullGray text-[14px]">
                {currentOrder?.twoWays ? "Pickup & Delivery" : "Delivery only"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">
                Pickup location/cluster
              </p>
              <p className="text-gullGray text-right text-[14px]">
                {currentOrder?.pickupAddress
                  ? currentOrder?.pickupAddress
                  : "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">
                Delivery location/cluster
              </p>
              <p className="text-gullGray text-right text-[14px]">
                {currentOrder?.destinationAddress}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">ETA</p>
              <p className="text-gullGray text-[14px]">12:00 PM</p>
            </div>
          </Card>
          <Card className="!h-fit">
            <div className="flex items-center justify-between">
              <p className="text-fiord font-medium text-[14px]">
                Rider Information
              </p>
              <div className="flex gap-1">
                <Button
                  onClick={() => setIsShown(true)}
                  type="button"
                  className="!h-[35px] !px-4 text-xs"
                  blue
                >
                  Reassign
                </Button>
              </div>
            </div>
            <div className="flex justify-between items-center border border-catSkillWhite rounded-lg p-2">
              <div className="flex gap-1 items-center">
                <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center">
                  <LHIconTruckDriver />
                </div>
                <div>
                  <p className="text-pickledBlueWood capitalize text-[11px]">
                    {currentOrder?.assignedRider?.firstName}{" "}
                    {currentOrder?.assignedRider?.lastName}
                  </p>
                  <p className="text-pickledBlueWood text-[11px]">Rider</p>
                </div>
              </div>
              <div>
                <p className="text-pickledBlueWood text-[11px]">
                  {currentOrder?.assignedRider?.mobile}
                </p>
                <p className="text-pickledBlueWood text-[11px]">Lagos</p>
              </div>
            </div>
          </Card>
          <Card>
            <p className="text-fiord font-medium text-[14px]">Fleet</p>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Type</p>
              <p className="text-gullGray text-[14px]">
                {orderFleet?.fleetType ? orderFleet?.fleetType : "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Brand</p>
              <p className="text-gullGray text-[14px]">
                {orderFleet?.fleetBrand ? orderFleet?.fleetBrand : "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Plate number</p>
              <p className="text-gullGray text-[14px]">
                {orderFleet?.regNumber ? orderFleet?.regNumber : "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Current location</p>
              <p className="text-gullGray text-[14px]">N/A</p>
            </div>
          </Card>
          <Card>
            <p className="text-fiord font-medium text-[14px]">Pricing</p>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">N/A</p>
              <p className="text-gullGray text-[14px]">N/A</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Type</p>
              <p className="text-gullGray text-[14px]">N/A</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Base</p>
              <p className="text-gullGray text-[14px]">N/A</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gullGray text-[14px]">Multiplier</p>
              <p className="text-gullGray text-[14px]">N/A</p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SingleOrderInfo;
