import {
  LHIconDoubleCheck,
  LHIconInactive,
  LHIconUnassign,
  LHIconUser,
} from "assets/svgs";
import Button from "shared/Button";
import Card from "shared/Card";
import DashboardCard from "shared/DashboardCard";
import PageTitle from "shared/PageTitle";
import GoBack from "./GoBack";
import { useDispatch, useSelector } from "react-redux";
import { getFilterableOrders } from "appstate/features/order/orderActions";
import { getFilterableRiders } from "appstate/features/fleet/fleetActions";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
import { format } from "date-fns";
import { setEndDate, setStartDate } from "appstate/features/order/orderSlice";
import Modal from "layout/modal/Modal";

const Header = ({
  title,
  btnText,
  onClick,
  single,
  setCurrentOrders,
  setCurrentRiders,
  info1,
  info2,
  info3,
  info4,
  amount,
  activeAmount,
  inactiveAmount,
  deliveredAmount,
  dashboard,
  dateFilterFn,
  todayFilterFn,
  setTodayStatus,
  todayStatus,
  allFilterFn,
}) => {
  const dispatch = useDispatch();

  const [isDateShown, setIsDateShown] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const { startDate, endDate, filteredClient } = useSelector(
    (state) => state.order
  );

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "yyyy-MM-dd");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
    dispatch(
      setStartDate(formatDateDisplay(ranges.selection.startDate, "Start Date"))
    );
    dispatch(
      setEndDate(formatDateDisplay(ranges.selection.endDate, "End Date"))
    );
  };

  return (
    <Card>
      <div className="flex flex-wrap gap-4 mb-4 justify-between items-center">
        {single ? <GoBack /> : <PageTitle title={title} />}
        <div className="flex justify-between">
          <div className="flex flex-wrap items-center gap-2">
            {dashboard && (
              <div className="relative max-sm:w-full">
                <div className="flex gap-3">
                  <div
                    onClick={() => {
                      setTodayStatus(!todayStatus);
                      todayStatus ? allFilterFn() : todayFilterFn();
                    }}
                    className={`max-sm:w-full cursor-pointer flex justify-start items-center px-4 py-2 text-paleSky text-[14px] border-2 rounded-lg border-gallery font-medium ${
                      todayStatus ? "bg-congressBlue" : "bg-white"
                    } rounded-lg h-[45px]`}
                  >
                    <div
                      className={`flex flex-wrap gap-1 ${
                        todayStatus ? "text-white" : "text-congressBlue"
                      }`}
                    >
                      Today
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setIsDateShown(!isDateShown);
                    }}
                    className="max-sm:w-full cursor-pointer flex justify-start items-center px-4 py-2 text-paleSky text-[14px] font-medium border-2 rounded-lg border-gallery h-[45px]"
                  >
                    <div className="flex flex-wrap gap-1">Custom</div>
                  </div>
                </div>
                {isDateShown && (
                  <Modal
                    isShown={isDateShown}
                    hide={() => setIsDateShown(false)}
                    title="Select Date"
                    className="flex flex-col items-center"
                    divClass="md:!w-fit"
                  >
                    <DateRangePicker
                      onChange={(item) => {
                        handleSelect(item);
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      ranges={[selectedDateRange]}
                      scroll={{ enabled: true }}
                      direction="vertical"
                    />
                    <div className="w-full flex justify-end gap-4 py-4">
                      <button
                        className="text-white text-[12px] bg-congressBlue px-4 py-2 rounded-xl"
                        onClick={() => {
                          dateFilterFn();
                          setIsDateShown(false);
                          setTodayStatus(false);
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className="text-white text-[12px] bg-red-500 px-4 py-2 rounded-xl"
                        onClick={() => {
                          setIsDateShown(false);
                          allFilterFn();
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </Modal>
                )}
              </div>
            )}
            {btnText && (
              <Button
                onClick={onClick}
                className="!bg-pickledBlueWood !h-[45px] !text-white max-sm:w-full"
              >
                {btnText}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-4 w-full">
        <DashboardCard
          onClick={
            setCurrentOrders
              ? () =>
                  dispatch(
                    getFilterableOrders({
                      page: 1,
                      status: "",
                      today: todayStatus,
                      startDate,
                      endDate,
                      merchantId: filteredClient,
                    })
                  ).then((data) => {
                    setCurrentOrders(data?.payload?.data);
                  })
              : setCurrentRiders
              ? () =>
                  dispatch(
                    getFilterableRiders({
                      page: 1,
                    })
                  ).then((data) => {
                    setCurrentRiders(data?.payload?.data);
                  })
              : () => {}
          }
          cardColor="bg-varden"
          icon={<LHIconUser />}
          innerColor="bg-koromiko"
          info={info1 ? info1 : "Total"}
          amount={amount ? amount : 0}
        />
        <DashboardCard
          onClick={
            setCurrentOrders
              ? () =>
                  dispatch(
                    getFilterableOrders({
                      page: 1,
                      assigned: true,
                      delivered: false,
                      today: todayStatus,
                      startDate,
                      endDate,
                      merchantId: filteredClient,
                    })
                  ).then((data) => {
                    setCurrentOrders(data?.payload?.data);
                  })
              : setCurrentRiders
              ? () =>
                  dispatch(
                    getFilterableRiders({
                      page: 1,
                      active: true,
                      today: todayStatus,
                      startDate,
                      endDate,
                      merchantId: filteredClient,
                    })
                  ).then((data) => {
                    setCurrentRiders(data?.payload?.data);
                  })
              : () => {}
          }
          cardColor="bg-lightFrenchPass"
          icon={<LHIconDoubleCheck />}
          innerColor="bg-frenchPass"
          info={info2 ? info2 : "Active"}
          amount={activeAmount ? activeAmount : 0}
        />
        <DashboardCard
          onClick={
            setCurrentOrders
              ? () =>
                  dispatch(
                    getFilterableOrders({
                      page: 1,
                      status: "Pending",
                      today: todayStatus,
                      startDate,
                      endDate,
                      merchantId: filteredClient,
                    })
                  ).then((data) => {
                    setCurrentOrders(data?.payload?.data);
                  })
              : setCurrentRiders
              ? () =>
                  dispatch(
                    getFilterableRiders({
                      page: 1,
                      active: false,
                    })
                  ).then((data) => {
                    setCurrentRiders(data?.payload?.data);
                  })
              : () => {}
          }
          cardColor="bg-lightMelrose"
          icon={<LHIconInactive />}
          innerColor="bg-melrose"
          info={info3 ? info3 : "Inactive"}
          amount={inactiveAmount ? inactiveAmount : 0}
        />
        <DashboardCard
          onClick={
            setCurrentOrders
              ? () =>
                  dispatch(
                    getFilterableOrders({
                      page: 1,
                      status: "Delivered",
                      today: todayStatus,
                      startDate,
                      endDate,
                      merchantId: filteredClient,
                    })
                  ).then((data) => {
                    setCurrentOrders(data?.payload?.data);
                  })
              : setCurrentRiders
              ? () =>
                  dispatch(
                    getFilterableRiders({
                      page: 1,
                      available: true,
                    })
                  ).then((data) => {
                    setCurrentRiders(data?.payload?.data);
                  })
              : () => {}
          }
          cardColor="bg-lightPastelGreen"
          icon={<LHIconUnassign />}
          innerColor="bg-pastelGreen"
          info={info4 ? info4 : "Delivered"}
          amount={deliveredAmount ? deliveredAmount : 0}
        />
      </div>
    </Card>
  );
};

export default Header;
