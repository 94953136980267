import Modal from "layout/modal/Modal";
import AddNewUserContents from "./AddNewUser";

const AddNewUserModal = ({
  isShown,
  hide,
  currentClient,
  setMerchantUsers,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Add New User">
      <AddNewUserContents
        hide={hide}
        setMerchantUsers={setMerchantUsers}
        currentClient={currentClient}
      />
    </Modal>
  );
};

export default AddNewUserModal;
