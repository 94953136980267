import Modal from "layout/modal/Modal";
import React from "react";
import AddNewTeamContents from "./AddNewTeam";

const AddNewTeamModal = ({ isShown, hide, admins }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Team">
      <AddNewTeamContents hide={hide} admins={admins} />
    </Modal>
  );
};

export default AddNewTeamModal;
