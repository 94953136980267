import Card from "shared/Card";
import { LHIconEdit, LHIconGroup, LHIconStore, LHIconTrash } from "assets/svgs";
import Button from "shared/Button";
import { useEffect, useState } from "react";
import UpdateClientTeamModal from "./modal/updateClientTeam";
import { useDispatch } from "react-redux";
import { getRidersByTeamId } from "appstate/features/fleet/fleetActions";

const ClientInfo = ({ currentClient, setCurrentClient }) => {
  const [isShown, setIsShown] = useState(false);
  const [clientRiders, setClientRiders] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    currentClient?.team?.id &&
      dispatch(
        getRidersByTeamId({ teamId: currentClient?.team?.id, page: 1 })
      ).then((data) => {
        setClientRiders(data?.payload?.data?.riders);
      });
  }, [dispatch, currentClient?.team?.id]);

  return (
    <Card className="w-2/5 flex flex-col gap-3">
      <UpdateClientTeamModal
        currentClient={currentClient}
        isShown={isShown}
        hide={() => setIsShown(false)}
        setCurrentClient={setCurrentClient}
      />
      <div className="flex items-center gap-2">
        <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
          <p className="text-[23px] uppercase font-medium text-paleSky">
            {currentClient?.name?.split(" ")[0]?.charAt(0)}
            {currentClient?.name?.split(" ")[1]?.charAt(0)}
          </p>
        </div>
        <div>
          <p className="text-[16px] text-paleSky">{currentClient?.name}</p>
          <p className="text-grayChateau">+234 9032456754</p>
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="flex items-center gap-2 pr-1 border-r">
          <div className="flex gap-2 items-center">
            <LHIconGroup className="fill-black stroke-black w-4 h-4" />
            <p className="text-gullGray text-[12px]">Assigned Team</p>
          </div>
          <p className="text-[12px] pr-2">
            {currentClient?.team?.name ? currentClient?.team?.name : "N/A"}
          </p>
          <LHIconEdit
            className="w-4 h-4 cursor-pointer"
            onClick={() => setIsShown(true)}
          />
        </div>
        <div className="flex items-center gap-2">
          <LHIconStore className="fill-black stroke-black w-4 h-4" />
          <p className="text-gullGray text-[12px]">No of outlet(s)</p>
          <p>{currentClient?.noOfOutlet}</p>
        </div>
      </div>
      <div className="border-b pb-4">
        <Button className="!w-full !h-[45px]" blue>
          Edit Client Info
        </Button>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <p className="text-[20px] font-medium">Assigned Riders</p>
          <LHIconTrash className="w-4 h-4" />
        </div>
        <div className="flex flex-col my-2 gap-3">
          {clientRiders?.map((rider, idx) => (
            <div key={idx} className="my-1 flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
                  <p className="text-[18px] font-medium text-paleSky capitalize">
                    {rider?.user?.firstName?.charAt(0)?.toUpperCase()}
                    {rider?.user?.lastName?.charAt(0)?.toUpperCase()}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] text-paleSky capitalize">
                    {rider?.user?.firstName} {rider?.user?.lastName}
                  </p>
                  <p className="text-grayChateau text-[14px]">
                    {rider?.user?.mobile}
                  </p>
                </div>
              </div>
              <input type="checkbox" />
            </div>
          ))}
        </div>
        <div>
          <Button className="!w-full !h-[45px]" blue>
            Assign Rider
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ClientInfo;
