import { deleteAdmin, getAdmins } from "appstate/features/admin/adminActions";
import { LHAdmin } from "assets/images";
import { LHIconGear, LHIconMenuDots, LHIconTrash } from "assets/svgs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Card from "shared/Card";
import Table from "shared/Table";
import AddNewAdminModal from "./modal/addnewadmin";
import Loader from "shared/Loader";
import { logout } from "appstate/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import TableControl from "shared/TableControl";

const Details = ({ currentAdmin, admins, setAdmins }) => {
  const { activeAdmin, activeUser } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.admin);

  const [showControls, setShowControls] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const controls = [
    {
      text: "Edit Admin",
      action: () => {
        setShowControls(false);
        setIsShown(true);
      },
    },
    {
      text: "Delete Admin",
      action: () => {
        setShowControls(false);
        dispatch(deleteAdmin({ id: selectedRow?._id })).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success("Admin deleted!");
            dispatch(getAdmins()).then((data) => {
              if (data?.payload?.status === 200) {
                setAdmins(data?.payload?.data);
              }
            });
          }
        });
      },
    },
  ];

  const coAdminColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <input
          checked={selectedRows?.find(
            (selectedRow) => selectedRow._id === d._id
          )}
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          type="checkbox"
        />
      ),
    },
    {
      Header: "Name",
      accessor: (d) => (
        <div className="flex justify-start ml-2 gap-2">
          <p className="capitalize text-start">{d.firstName}</p>
          <p className="capitalize text-start">{d.lastName}</p>
        </div>
      ),
    },
    {
      Header: "Email Address",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.email ? d.email : "N/A"}</p>
      ),
    },
    {
      Header: "Role",
      accessor: (d) => (
        <p className="flex justify-start ml-4">{d.role ? d.role : "Admin"}</p>
      ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.activated ? (
          <div className="flex justify-start ml-4">
            <div className="w-[10px] h-[10px] bg-malachite rounded-full"></div>
          </div>
        ) : (
          <div className="flex justify-start ml-4">
            <div className="w-[10px] h-[10px] bg-slateGray rounded-full"></div>
          </div>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <AddNewAdminModal
        isEditing={true}
        selectedRow={selectedRow}
        isShown={isShown}
        hide={() => setIsShown(false)}
        setAdmins={setAdmins}
      />
      <div className="flex flex-wrap gap-4 justify-between items-start border-b py-4">
        <div className="flex flex-wrap gap-2 items-center mb-4">
          <div>
            <img className="object-cover" src={LHAdmin} alt="Manager" />
          </div>
          <div>
            <p className="text-[24px] font-semibold">{`${activeUser?.firstName} ${activeUser?.lastName}`}</p>
            <p className="text-[14px]">{activeUser?.email}</p>
            <p className="p-1 mt-1 rounded-lg border border-algaeGreen text-[16px] text-stTropaz font-bold flex justify-center items-center">
              Admin
            </p>
          </div>
        </div>
        <div className="flex gap-4">
          <div
            onClick={() => {
              dispatch(deleteAdmin({ id: currentAdmin?._id })).then((data) => {
                if (data?.payload?.status === 200) {
                  toast.success("Admin deleted!");
                  dispatch(logout());
                  navigate("/");
                }
              });
            }}
            className="cursor-pointer flex items-center gap-2"
          >
            <LHIconTrash />
            <p className="text-fiord max-md:text-[12px]">Delete Admin</p>
          </div>
          <div
            onClick={() => {
              setSelectedRow(currentAdmin.authId);
              setShowControls(false);
              setIsShown(true);
            }}
            className="cursor-pointer flex items-center gap-2"
          >
            <LHIconGear />
            <p className="text-fiord max-md:text-[12px]">Edit Admin</p>
          </div>
        </div>
      </div>
      <div>
        <div className="my-4">
          <p className="h-[45px] w-fit flex items-center text-black bg-pigeonPost rounded-lg px-2 py-2">
            Co-Admin(s)
          </p>
        </div>
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              columns={coAdminColumns}
              data={admins?.filter(
                (admin) => admin?._id !== activeAdmin?.authId
              )}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Details;
