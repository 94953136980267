import Modal from "layout/modal/Modal";
import AddNewAdminContents from "./AddNewAdmin";

const AddNewAdminModal = ({
  isShown,
  hide,
  isEditing,
  selectedRow,
  setAdmins,
}) => {
  return (
    <Modal
      isShown={isShown}
      hide={hide}
      title={isEditing ? "Edit Admin" : "Add Admin"}
    >
      <AddNewAdminContents
        hide={hide}
        isEditing={isEditing}
        selectedRow={selectedRow}
        setAdmins={setAdmins}
      />
    </Modal>
  );
};

export default AddNewAdminModal;
