import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import AddNewRiderModal from "./modal/addNewRider";
import {
  getFilterableRiders,
  getFleetType,
  getFleets,
} from "appstate/features/fleet/fleetActions";
import { useDispatch, useSelector } from "react-redux";
import Details from "./RiderDetails";

const RiderContents = () => {
  const { riders, isLoading } = useSelector((state) => state.fleet);

  const [isShown, setIsShown] = useState(false);
  const [currentRiders, setCurrentRiders] = useState(riders);

  const dispatch = useDispatch();

  // console.log(riders);

  useEffect(() => {
    dispatch(getFleets());
    dispatch(getFilterableRiders({ page: 1 }));
    dispatch(getFleetType());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewRiderModal
        setCurrentRiders={setCurrentRiders}
        isShown={isShown}
        hide={() => setIsShown(false)}
      />
      <Header
        info1="All"
        info2="Active"
        info3="Inactive"
        info4="Available"
        currentRiders={currentRiders}
        setCurrentRiders={setCurrentRiders}
        amount={`${currentRiders?.filter((rider) => rider?.active).length}`}
        activeAmount={`${
          currentRiders?.filter((rider) => rider?.active).length
        }`}
        inactiveAmount={"0"}
        deliveredAmount={`${
          currentRiders?.filter((rider) => rider?.available).length
        }`}
        title="Rider"
        btnText="New Rider"
        onClick={() => setIsShown(true)}
      />
      <Details
        isLoading={isLoading}
        currentRiders={currentRiders}
        setCurrentRiders={setCurrentRiders}
      />
    </DashboardLayout>
  );
};

export default RiderContents;
