import { LHIconFilter, LHIconMenuDots, LHIconSearch } from "assets/svgs";
import React, { useState } from "react";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Table from "shared/Table";
import TableControl from "shared/TableControl";

const FleetTypeDetails = ({ fleetTypes }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [showControls, setShowControls] = useState(false);

  const controls = [
    {
      text: "Edit Fleet Type",
      action: () => {},
    },
    {
      text: "Delete Fleet Type",
      action: () => {},
    },
  ];

  const fleetTypeColumns = [
    {
      Header: " ",
      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Type",
      accessor: (d) => <p className="flex justify-start ml-4">{d.name}</p>,
    },
    {
      Header: "Created Date",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.createdAt.split("T")[0].split("-").reverse().join("-")}
        </p>
      ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <LHIconMenuDots
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 left-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for client"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <LHIconSearch className="absolute bottom-3 left-2 w-5 h-5" />
        </div>
        <div className="flex items-center flex-wrap gap-4">
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Delete</p>
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <LHIconFilter />
          </div>
          <div className="h-[36px] cursor-pointer px-3 py-1 border-2 border-gullGray rounded-lg flex justify-center items-center">
            <p className="text-xs">Name</p>
          </div>
        </div>
      </div>
      <div>
        <Table
          columns={fleetTypeColumns}
          data={fleetTypes}
          type="You don’t have any fleet Type. Create a new fleet Type to get started"
        />
      </div>
    </Card>
  );
};

export default FleetTypeDetails;
