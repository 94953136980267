import * as Yup from "yup";
const createRiderValidationSchema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required"),
  // mobile: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  // vehicle: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});

export { createRiderValidationSchema };
