import {
  addPricing,
  getPricing,
} from "appstate/features/pricing/pricingActions";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import TextArea from "shared/TextArea";
import ButtonAnimation from "shared/buttonAnimation";
import { addDetailsValidationSchema } from "validation/pricing/addDetailsSchema";

const Details = ({ hide, body }) => {
  const { fleetTypes } = useSelector((state) => state.fleet);
  const { isLoading } = useSelector((state) => state.pricing);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      fleetType: "",
      name: "",
      description: "",
      country: "",
    },
    validationSchema: addDetailsValidationSchema,
    onSubmit: (values) => {
      const detailsBody = {
        fleetType: values.fleetType,
        name: values.name,
        country: values.country,
        description: values.description,
        currency: "NGN",
      };
      dispatch(addPricing({ ...body, ...detailsBody })).then((data) => {
        if (data?.payload?.status === 201) {
          toast.success(`${detailsBody.name} created successfully!`);
          dispatch(getPricing());
          hide();
        }
      });
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-8">
        <p>Locations</p>
        <div className="h-[1px] w-full bg-fruitSalad"></div>
        <p>Fee</p>
        <div className="h-[1px] w-full bg-fruitSalad"></div>
        <p>Detail</p>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="">
            <InputSelect
              datas={fleetTypes}
              label="Fleet type"
              placeholder="Fleet type"
              name="fleetType"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fleetType}
            />
            {formik.touched.fleetType && formik.errors.fleetType ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.fleetType}
              </p>
            ) : null}
          </div>
          <div className="">
            <InputField
              placeholder="Pricing name"
              label="Give this pricing a unique name"
              name="name"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.name}
              </p>
            ) : null}
          </div>
          <div className="">
            <InputSelect
              datas={[
                {
                  name: "Nigeria",
                  value: "Nigeria",
                },
              ]}
              label="Country"
              placeholder="Country"
              name="country"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
            />
            {formik.touched.country && formik.errors.country ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.country}
              </p>
            ) : null}
          </div>
          <div className="">
            <TextArea
              placeholder="Description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.description}
              </p>
            ) : null}
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : "Create Pricing Rule"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Details;
