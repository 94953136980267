import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  addCluster,
  editCluster,
  getLocationsClusters,
} from "appstate/features/pricing/pricingActions";
import { LHIconClose, LHIconSingleDistance } from "assets/svgs";

const AddNewCluster = ({ hide, selectedRow, isEditing }) => {
  const { isLoading } = useSelector((state) => state.pricing);
  const [clusterLocations, setClusterLocations] = useState(
    isEditing ? selectedRow?.locations : []
  );
  const [locObject, setLocObject] = useState("");
  const [inputIndex, setInputIndex] = useState(0);
  const [googlePlaceObj, setGooglePlaceObj] = useState({});
  const [inputAddresses, setInputAddresses] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);
  const [multiplier, setMultiplier] = useState(
    isEditing ? selectedRow?.multiplier : ""
  );

  const [input, setInput] = useState("");

  const dispatch = useDispatch();

  const options = {
    componentRestrictions: { country: [] },
    types: [],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        { placeId: googlePlaceObj.place_id },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, googlePlaceObj, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      const newClusterLocations = clusterLocations;
      newClusterLocations[inputIndex - 1] = {
        ...newClusterLocations[inputIndex - 1],
        locLongitude: locObject?.geometry?.location?.lng((e) => {
          return e;
        }),
        locLatitude: locObject?.geometry?.location?.lat((e) => {
          return e;
        }),
        city: locObject && locObject?.address_components[0]?.long_name,
        state: locObject && locObject?.address_components[1]?.long_name,
      };
      setClusterLocations(newClusterLocations);
    };
    locObject && getLngLat();
  }, [locObject, inputIndex, clusterLocations]);

  const formik = useFormik({
    initialValues: {
      address: isEditing ? selectedRow?.address : "",
      clusterLocations: clusterLocations,
    },
    validationSchema: Yup.object({
      address: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const body = {
        address: values.address,
        country: "Nigeria",
        state: "Lagos",
        locations: clusterLocations,
        multiplier: parseInt(multiplier),
      };
      if (clusterLocations.length < 2) {
        toast.error("A cluster must contain more than one location");
        return;
      }
      clusterLocations.map((location) => {
        if (!location.address) {
          toast.error("Please enter a valid location!");
          throw Error;
        }
        return location;
      });
      isEditing
        ? dispatch(editCluster({ ...body, clusterId: selectedRow?._id })).then(
            (data) => {
              if (data?.payload?.status === 201) {
                toast.success(`${body.address} cluster edited successfully!`);
                dispatch(getLocationsClusters());
                hide();
              }
            }
          )
        : dispatch(addCluster(body)).then((data) => {
            if (data?.payload?.status === 201) {
              toast.success(`${body.address} cluster created successfully!`);
              dispatch(getLocationsClusters());
              hide();
            }
          });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col">
        <div className="pb-6 border-b mb-4">
          <InputField
            placeholder="Cluster Name"
            name="address"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.address}
            </p>
          ) : null}
        </div>
        <div className="mb-4">
          <InputField
            type="number"
            label="Multiplier for this cluster - Base fee will be multiplied by the multiplier"
            placeholder="Multiplier"
            name="multiplier"
            className="!h-[50px]"
            onChange={(e) => setMultiplier(e.target.value)}
            value={multiplier}
          />
        </div>
        <div className="pb-4">
          <InputField
            placeholder="Add new location"
            label="Locations"
            name="address"
            id="address"
            className="!h-[50px]"
            onChange={(e) => {
              setShowPredictions(true);
              setInput(e.target.value);
              const newInputAddresses = [...inputAddresses];
              newInputAddresses[inputIndex] = e.target.value;
              setInputAddresses(newInputAddresses);
              getPlacePredictions({ input: e.target.value });
            }}
            loading={isPlacePredictionsLoading.toString()}
            value={input}
          />
        </div>
        <div className="">
          {showPredictions &&
            !isPlacePredictionsLoading &&
            placePredictions.map((item, idx) => (
              <p
                className="text-[12px] hover:bg-slate-100 p-1 rounded-lg text-gullGray cursor-pointer"
                key={idx}
                onClick={() => {
                  setShowPredictions(false);
                  setInput("");
                  setGooglePlaceObj(item);
                  const newClusterLocations = [...clusterLocations];
                  newClusterLocations[inputIndex] = {
                    ...newClusterLocations[inputIndex],
                    address: item.description,
                  };
                  setClusterLocations(newClusterLocations);
                  setInputIndex((prev) => prev + 1);
                }}
              >
                {item.description}
              </p>
            ))}
        </div>
        {clusterLocations.length > 0 && (
          <div className="mb-4">
            <div className="border-b py-4">
              <p className="text-[12px] my-2">Clusters</p>
              {clusterLocations?.map((address, idx) => (
                <div key={idx} className="flex justify-between">
                  <div className="flex justify-start gap-2">
                    <LHIconSingleDistance />
                    <p className="text-[12px]">{address?.address}</p>
                  </div>
                  <LHIconClose
                    className="cursor-pointer"
                    onClick={() => {
                      setClusterLocations(
                        clusterLocations.filter(
                          (selected) => selected.address !== address.address
                        )
                      );
                      setInputIndex((prev) => prev - 1);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : isEditing ? "Edit" : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewCluster;
