import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { approveClientValidationSchema } from "validation/client/approveClientSchema";
import {
  approveClient,
  getClients,
  setClientOpeningHours,
} from "appstate/features/client/clientActions";
import { toast } from "react-toastify";
import InputField from "shared/InputField";
import Multiselect from "multiselect-react-dropdown";

const ApproveClient = ({ hide, selectedRow, selectedRows }) => {
  const { pricingRules } = useSelector((state) => state.pricing);
  const { fleetTypes } = useSelector((state) => state.fleet);
  const { isLoading } = useSelector((state) => state.client);
  const { teams } = useSelector((state) => state.team);
  const [openingHourValue, setOpeningHourValue] = useState("");
  const [closingHourValue, setClosingHourValue] = useState("");
  const [selectedFleetType, setSelectedFleetType] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const fleetTypeList = fleetTypes.map((fleetType) => ({
    id: fleetType?._id,
    name: fleetType?.name,
    value: {
      fleetType: fleetType?.name,
      fleetTypeId: fleetType?._id,
    },
  }));

  const teamsList = teams.map((team) => ({
    id: team?._id,
    name: team?.name,
    value: {
      name: team?.name,
      id: team?._id,
    },
  }));

  const onSelect = (selectedList, selectedItem) => {
    setSelectedFleetType(selectedItem.value);
  };

  const onTeamsSelect = (selectedList, selectedItem) => {
    setSelectedTeam(selectedItem.value);
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      openingHour: openingHourValue,
      closingHour: closingHourValue,
      baseFee: "",
      pricingRule: "",
      fleetType: "",
      fleetTypeId: "",
    },
    validationSchema: approveClientValidationSchema,
    onSubmit: (values) => {
      const body = {
        openingHour: openingHourValue,
        closingHour: closingHourValue,
        baseFee: values.baseFee,
        pricingRule: values.pricingRule,
        fleetType: selectedFleetType?.fleetType,
        fleetTypeId: selectedFleetType?.fleetTypeId,
        team: selectedTeam,
      };
      if (body.openingHour.split(":")[0] === body.closingHour.split(":")[0]) {
        if (body.openingHour.split(":")[1] > body.closingHour.split(":")[1]) {
          toast.error(
            "Error! Opening hour cannot be greater than closing hour."
          );
          return;
        }
        return;
      } else if (
        body.openingHour.split(":")[0] > body.closingHour.split(":")[0]
      ) {
        toast.error("Error! Opening hour cannot be greater than closing hour.");
        return;
      } else {
        dispatch(
          setClientOpeningHours({
            userId: selectedRows[0] ? selectedRows[0]._id : selectedRow._id,
            openingHour: `${body.openingHour}:00`,
            closingHour: `${body.closingHour}:00`,
          })
        );
        dispatch(
          approveClient({
            merchantId: selectedRows[0] ? selectedRows[0]._id : selectedRow._id,
            baseFee: body.baseFee,
            pricingRule: body.pricingRule,
            fleetType: selectedFleetType.fleetType,
            fleetTypeId: selectedFleetType.fleetTypeId,
            team: selectedTeam,
            status: true,
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            dispatch(getClients());
            toast.success("Client approved successfully!");
            hide();
          }
        });
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
        <div className="flex justify-between border-b pb-3">
          <div className="w-[45%]">
            <label
              className="font-normal text-xs text-midGray mb-[5px]"
              htmlFor="openingHour"
            >
              Opening hour
            </label>
            <div className="relative">
              <TimePicker
                name="openingHour"
                className="bg-catSkillWhite w-full rounded-lg px-2 h-[50px] !border-0"
                onChange={setOpeningHourValue}
                onBlur={formik.handleBlur}
                value={openingHourValue}
                hourPlaceholder="hh"
                minutePlaceholder="mm"
              />
            </div>
            {formik.touched.openingHour && formik.errors.openingHour ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.openingHour}
              </p>
            ) : null}
          </div>
          <div className="w-[45%]">
            <label
              className="font-normal text-xs text-midGray mb-[5px]"
              htmlFor="closingHour"
            >
              Closing hour
            </label>
            <div className="relative">
              <TimePicker
                name="closingHour"
                className="bg-catSkillWhite w-full rounded-lg px-2 h-[50px] !border-0"
                onChange={setClosingHourValue}
                onBlur={formik.handleBlur}
                value={closingHourValue}
                hourPlaceholder="hh"
                minutePlaceholder="mm"
              />
            </div>
            {formik.touched.closingHour && formik.errors.closingHour ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.closingHour}
              </p>
            ) : null}
          </div>
        </div>
        <div className="pb-6">
          <div className="mb-3">
            <InputSelect
              admin
              datas={pricingRules}
              label="Select pricing rule"
              placeholder="Select pricing rule"
              name="pricingRule"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pricingRule}
            />
            {formik.touched.pricingRule && formik.errors.pricingRule ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.pricingRule}
              </p>
            ) : null}
          </div>
          <div className="mb-3">
            <InputField
              type="number"
              placeholder="Base Fee"
              name="baseFee"
              className="!h-[50px] mb-2"
              label="Base Fee"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.baseFee}
            />
            {formik.touched.baseFee && formik.errors.baseFee ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.baseFee}
              </p>
            ) : null}
          </div>

          <div className="mb-2">
            <p className="text-[12px] text-fiord mb-2">Select fleet type</p>
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onSelect}
              options={fleetTypeList}
              value={selectedFleetType}
              placeholder="Select fleet type"
              className="h-[50px] rounded-lg pl-2 bg-catSkillWhite text-[12px]"
            />
          </div>
          <div>
            <p className="text-[12px] text-fiord mb-2">Select team</p>
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onTeamsSelect}
              options={teamsList}
              value={selectedTeam}
              placeholder="Select team"
              className="h-[50px] rounded-lg pl-2 bg-catSkillWhite text-[12px]"
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Approve"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ApproveClient;
