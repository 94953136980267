import React from "react";
import Chart from "./Chart";
import AssignedUsers from "./AssignedUsers";
import ClientInfo from "./ClientInfo";
import ClientStores from "./ClientStores";
import ApprovedRates from "./ApprovedRates";
import Billings from "./Billings/Billings";

const SingleClientDetails = ({ currentClient, setCurrentClient }) => {
  return (
    <div>
      <div className="flex max-[1200px]:flex-wrap gap-4">
        <div className="w-full min-[1200px]:w-3/5 flex flex-col gap-4">
          <Chart currentClient={currentClient} />
          <Billings currentClient={currentClient} />
          <AssignedUsers currentClient={currentClient} />
          <ApprovedRates currentClient={currentClient} />
        </div>
        <div className="w-full min-[1200px]:w-2/5 flex flex-col gap-4">
          <ClientInfo
            currentClient={currentClient}
            setCurrentClient={setCurrentClient}
          />
          <ClientStores currentClient={currentClient} />
        </div>
      </div>
    </div>
  );
};

export default SingleClientDetails;
