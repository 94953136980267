import { createSlice } from "@reduxjs/toolkit";

import {
  getTeams,
  createTeam,
  assignRiderToTeam,
  getTeamByID,
  getTeamRiders,
  addMerchantsToTeam,
  getMerchantTeamsById,
  addAdminUserToTeam,
  removeAdminUserFromTeam,
} from "./teamActions";

const initialState = {
  isLoading: false,
  teams: [],
  currentTeam: {},
  currentPage: null,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.isLoading = false;
      state.teams = action.payload?.data?.data;
      state.currentPage = action.payload?.currentPage;
    });
    builder.addCase(getTeams.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTeamByID.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTeamByID.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentTeam = action.payload?.data?.data;
    });
    builder.addCase(getTeamByID.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignRiderToTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignRiderToTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTeamRiders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTeamRiders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTeamRiders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addMerchantsToTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addMerchantsToTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addMerchantsToTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantTeamsById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMerchantTeamsById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMerchantTeamsById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAdminUserToTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addAdminUserToTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAdminUserToTeam.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeAdminUserFromTeam.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeAdminUserFromTeam.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeAdminUserFromTeam.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default teamSlice.reducer;
