import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  teamsGet,
  teamsCreate,
  RiderToTeamAssign,
  teamGetByID,
  teamGetRiders,
  merchantAddToTeam,
  merchantTeamGetById,
  adminAddToTeam,
  adminRemoveToTeam,
} from "appstate/api/teams";

export const getTeams = createAsyncThunk("teams/getTeams", teamsGet);
export const getTeamByID = createAsyncThunk("teams/getTeamByID", teamGetByID);
export const createTeam = createAsyncThunk("teams/createTeam", teamsCreate);
export const assignRiderToTeam = createAsyncThunk(
  "teams/assignRiderToTeam",
  RiderToTeamAssign
);
export const getTeamRiders = createAsyncThunk(
  "teams/getTeamRiders",
  teamGetRiders
);
export const addMerchantsToTeam = createAsyncThunk(
  "teams/addMerchantsToTeam",
  merchantAddToTeam
);
export const getMerchantTeamsById = createAsyncThunk(
  "teams/getMerchantTeamsById",
  merchantTeamGetById
);
export const addAdminUserToTeam = createAsyncThunk(
  "teams/addAdminUserToTeam",
  adminAddToTeam
);
export const removeAdminUserFromTeam = createAsyncThunk(
  "teams/removeAdminUserFromTeam",
  adminRemoveToTeam
);
