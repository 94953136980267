import Api from "apiInstance";
import { toast } from "react-toastify";

export const loginAdmin = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: "/auth/login",
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const userGetById = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/auth/find/id/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
