import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import PricingHeader from "./PricingHeader";
import AddNewPricingModal from "./modal/addNewPricing";
import { useDispatch } from "react-redux";
import {
  getLocationsClusters,
  getPricing,
  getPricingRules,
} from "appstate/features/pricing/pricingActions";
import { getFleetType } from "appstate/features/fleet/fleetActions";

const PricingContents = () => {
  const [isPricingModalShown, setIsPricingModalShown] = useState(false);
  const [isLocationModalShown, setIsLocationModalShown] = useState(false);
  const [isClusterModalShown, setIsClusterModalShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFleetType());
    dispatch(getPricing());
    dispatch(getPricingRules());
    dispatch(getLocationsClusters());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewPricingModal
        isShown={isPricingModalShown}
        hide={() => setIsPricingModalShown(false)}
      />
      <PricingHeader
        isLocationModalShown={isLocationModalShown}
        setIsLocationModalShown={setIsLocationModalShown}
        isClusterModalShown={isClusterModalShown}
        setIsClusterModalShown={setIsClusterModalShown}
        isPricingModalShown={isPricingModalShown}
        setIsPricingModalShown={setIsPricingModalShown}
        title="Pricing"
        onNewPricingClick={() => setIsPricingModalShown(true)}
        onNewLocationClick={() => setIsLocationModalShown(true)}
        onNewClusterClick={() => setIsClusterModalShown(true)}
      />
    </DashboardLayout>
  );
};

export default PricingContents;
