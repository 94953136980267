import Modal from "layout/modal/Modal";
import React from "react";
import AddNewOrder from "./AddNewOrder";

const AddNewOrderModal = ({ isShown, hide, invoice }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Add New Order">
      <AddNewOrder hide={hide} invoice={invoice} />
    </Modal>
  );
};

export default AddNewOrderModal;
