import NotFound from "pages/NotFound";
import AdminLogin from "pages/auth/AdminLogin";
import Clients from "pages/dashboard/client/Clients";
import AccessControl from "pages/dashboard/AccessControl";
import Teams from "pages/dashboard/Teams";
import { Outlet } from "react-router-dom";
import ProspectiveClients from "pages/dashboard/client/ProspectiveClients";
import Rider from "pages/dashboard/rider/Rider";
import ProspectiveRider from "pages/dashboard/rider/ProspectiveRider";
import Pricing from "pages/dashboard/Pricing";
import Orders from "pages/dashboard/orders/Orders";
import Fleet from "pages/dashboard/fleet/Fleet";
import FleetType from "pages/dashboard/fleet/FleetType";
import SingleClientContents from "features/client/singleclient";
import SingleRiderContents from "features/rider/singlerider";
import SingleOrderContent from "features/orders/singleorder";
import SingleTeamContents from "features/teams/singleteam";
import Tracking from "pages/dashboard/Tracking";
import ApprovedRates from "pages/dashboard/ApprovedRates";
import AdminDashboard from "pages/dashboard/Dashboard";
import Stats from "pages/dashboard/Stats";
import Reports from "pages/dashboard/Reports";
import InvoiceContents from "features/invoice";
import SingleInvoiceContents from "features/invoice/singleInvoice";

export const adminRoutes = [
  {
    path: "admin/*",
    element: <Outlet />,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "access-control",
        element: <AccessControl />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
      {
        path: "teams/:id",
        element: <SingleTeamContents />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "clients/:id",
        element: <SingleClientContents />,
      },
      {
        path: "clients/prospective",
        element: <ProspectiveClients />,
      },
      {
        path: "rider",
        element: <Rider />,
      },
      {
        path: "rider/:id",
        element: <SingleRiderContents />,
      },
      {
        path: "rider/prospective",
        element: <ProspectiveRider />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "orders/:id",
        element: <SingleOrderContent />,
      },
      {
        path: "fleet",
        element: <Fleet />,
      },
      {
        path: "fleet/type",
        element: <FleetType />,
      },
      {
        path: "tracking",
        element: <Tracking />,
      },
      {
        path: "approved-rates",
        element: <ApprovedRates />,
      },
      {
        path: "approved-rates",
        element: <ApprovedRates />,
      },
      {
        path: "stats",
        element: <Stats />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "invoice",
        element: <InvoiceContents />,
      },
      {
        path: "invoice/:id",
        element: <SingleInvoiceContents />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export const publicRoutes = [
  {
    path: "/",
    element: <AdminLogin />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
