import { createSlice } from "@reduxjs/toolkit";

import { adminLogin, getUserById } from "./authActions";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  activeUser: null,
  activeAdmin: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.activeAdmin = null;
      state.activeUser = null;
      state.token = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload?.data?.data?.token;
      state.activeUser = action.payload?.data?.data?.user;
      state.activeAdmin = action.payload?.data?.data?.admin;
    });
    builder.addCase(getUserById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserById.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
