import Modal from "layout/modal/Modal";
import AddNewRate from "./AddNewRate";

const ApprovedRateModal = ({
  isShown,
  hide,
  currentClient,
  setApprovedRates,
  isEditing,
  currentApprovedRate,
  undefinedClient,
}) => {
  return (
    <Modal
      isShown={isShown}
      hide={hide}
      title={isEditing ? "Edit Rate" : "Add New Rate"}
    >
      <AddNewRate
        hide={hide}
        setApprovedRates={setApprovedRates}
        currentClient={currentClient}
        isEditing={isEditing}
        currentApprovedRate={currentApprovedRate}
        undefinedClient={undefinedClient}
      />
    </Modal>
  );
};

export default ApprovedRateModal;
