const InputSelect = ({
  label,
  htmlFor,
  onFocus,
  value,
  onChange,
  id,
  onBlur,
  datas = [],
  placeholder,
  name,
  admin,
  required,
  onClick,
  className,
  divClass,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      className={`min-w-[120px] max-md:min-w-full relative flex flex-col justify-center ${divClass}`}
    >
      {label && (
        <label
          className="font-normal text-xs text-midGray mb-[5px]"
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      <select
        required={required}
        name={name}
        value={value}
        onChange={onChange}
        id={id}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`capitalize appearance-none rounded-lg bg-catSkillWhite placeholder:text-xs placeholder:text-gullGray outline-none p-2 text-xs font-normal ${className}`}
      >
        {placeholder && <option value={""}>{placeholder}</option>}

        {selected?.length > 0 && (
          <option value="" selected disabled hidden>
            {selected[0]?.name}
          </option>
        )}
        {datas?.map((data, idx) => (
          <option
            // selected={
            //   data.value === filterStatus || data.value === filteredClient
            // }
            key={idx}
            value={
              admin
                ? data._id
                : data?.rule
                ? data?.rule
                : data?.name
                ? data?.name
                : data?.fleetType
                ? data?.fleetType
                : data.value
            }
          >
            {data?.name} {data?.authId?.firstName} {data?.authId?.lastName}{" "}
            {data?.fleetBrand} {data?.address}
          </option>
        ))}
      </select>
      <div
        className={`absolute inset-y-0 right-0 flex items-center px-2 ${
          label ? "top-[30px]" : "top-[7px]"
        } pointer-events-none`}
      >
        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
        </svg>
      </div>
    </div>
  );
};

export default InputSelect;
