import React, { useState } from "react";
import Card from "shared/Card";
import InputField from "shared/InputField";
import Table from "shared/Table";
import AssignRiderModal from "./modal/assignRider";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  blacklistRider,
  deactivateRider,
  getFilterableRiders,
} from "appstate/features/fleet/fleetActions";
import Loader from "shared/Loader";
import TableControl from "shared/TableControl";
import { toast } from "react-toastify";
import { BiFilterAlt, BiSearch } from "react-icons/bi";
import Dropdown from "shared/Dropdown";
import { MdMoreVert } from "react-icons/md";

export const filterItems = [
  // { name: "Filter [All]", value: "" },
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "Inactive" },
  { name: "Available", value: "Available" },
  { name: "Blacklisted", value: "Blacklisted" },
];

const Details = ({ currentRiders, setCurrentRiders, isLoading }) => {
  const [activeStatus, setActiveStatus] = useState("Filter [All]");
  const [showControls, setShowControls] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();
  console.log(activeStatus);

  const handleFilterStatus = (item) => {
    // console.log(item);
    if (item === "Active") {
      setActiveStatus("Active");
      dispatch(
        getFilterableRiders({
          page: 1,
          active: true,
        })
      ).then((data) => {
        setCurrentRiders(data?.payload?.data);
      });
    } else if (item === "Inactive") {
      dispatch(
        getFilterableRiders({
          page: 1,
          active: false,
        })
      ).then((data) => {
        setCurrentRiders(data?.payload?.data);
        setActiveStatus("Inactive");
      });
    } else if (item === "Available") {
      setActiveStatus("Available");
      dispatch(
        getFilterableRiders({
          page: 1,
          available: true,
        })
      ).then((data) => {
        setCurrentRiders(data?.payload?.data);
      });
    } else if (item === "Blacklisted") {
      setActiveStatus("Blacklisted");
      dispatch(
        getFilterableRiders({
          page: 1,
          blacklisted: true,
        })
      ).then((data) => {
        setCurrentRiders(data?.payload?.data);
      });
    } else {
      dispatch(
        getFilterableRiders({
          page: 1,
        })
      ).then((data) => {
        setCurrentRiders(data?.payload?.data);
      });
    }
  };

  const controls = [
    {
      text: "Assign Rider",
      action: () => setIsShown(true),
    },
    {
      text: "Deactivate Rider",
      action: () => {
        dispatch(deactivateRider({ authId: selectedRow?._id })).then((data) => {
          if (data?.payload?.status === 200) {
            setShowControls(false);
            setSelectedRow(null);
            toast.success(data?.payload?.data?.message);
            dispatch(getFilterableRiders({ page: 1 })).then((data) =>
              setCurrentRiders(data?.payload?.data)
            );
          }
        });
      },
    },
    {
      text: "Blacklist Rider",
      action: () => {
        dispatch(blacklistRider({ authId: selectedRow?._id })).then((data) => {
          if (data?.payload?.status === 200) {
            setShowControls(false);
            setSelectedRow(null);
            toast.success(data?.payload?.data?.message);
            dispatch(getFilterableRiders({ page: 1 })).then((data) =>
              setCurrentRiders(data?.payload?.data)
            );
          }
        });
      },
    },
  ];

  const riderColumns = [
    {
      Header: " ",

      accessor: (d) => (
        <div
          onClick={() => {
            if (setSelectedRows) {
              if (
                selectedRows.find((selectedRow) => selectedRow._id === d._id)
              ) {
                setSelectedRows(
                  selectedRows.filter(
                    (selectedRow) => selectedRow._id !== d._id
                  )
                );
              } else {
                setSelectedRows([...selectedRows, d]);
              }
            }
          }}
          className="w-5 h-5 bg-[#FCFCFC] border border-[#E2E8F0] rounded-md ml-3 flex justify-center items-center"
          type="checkbox"
        >
          <div
            className={`${
              selectedRows?.find((selectedRow) => selectedRow._id === d._id)
                ? "bg-congressBlue"
                : "bg-transparent"
            } w-[14px] h-[14px] rounded-md cursor-pointer`}
          />
        </div>
      ),
    },
    {
      Header: "Rider Name",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4 capitalize"
          to={`/admin/rider/${d._id}`}
        >
          {d.user?.firstName} {d.user?.lastName}
        </Link>
      ),
    },
    {
      Header: "Phone Number",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4"
          to={`/admin/rider/${d._id}`}
        >
          +{d.user?.mobile}
        </Link>
      ),
    },
    {
      Header: "Email",
      accessor: (d) => (
        <Link
          className="flex cursor-pointer items-center justify-start ml-4"
          to={`/admin/rider/${d._id}`}
        >
          {d.user?.email}
        </Link>
      ),
    },
    {
      Header: "Team",
      accessor: (d) => (
        <p className="flex justify-start ml-4 capitalize">
          {d.team?.name ? d.team?.name : "N/A"}
        </p>
      ),
    },
    {
      Header: "Status",
      accessor: (d) =>
        d.active ? (
          <div className="flex justify-start ml-4">
            <div className="bg-offGreen text-malachite text-[12px] rounded-lg py-1 px-2 capitalize">
              Active
            </div>
          </div>
        ) : (
          <div className="flex justify-start ml-4">
            <div className="bg-red-100 text-red-500 text-[12px] rounded-lg py-1 px-2 capitalize">
              Unapproved
            </div>
          </div>
        ),
    },
    {
      Header: "  ",
      accessor: (d) => (
        <div className="relative">
          <MdMoreVert
            size={25}
            onClick={() => {
              setSelectedRow(d);
              setShowControls(true);
            }}
            className="cursor-pointer"
          />
          {selectedRow?._id === d?._id && showControls && (
            <div className="absolute z-50 top-0 right-0">
              <div
                className="fixed -z-10 w-full h-full top-0 left-0 cursor-pointer opacity-70"
                onClick={() => setShowControls(false)}
              ></div>
              <TableControl controls={controls} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card>
      <AssignRiderModal
        isShown={isShown}
        hide={() => {
          setShowControls(false);
          setIsShown(false);
        }}
        selectedRow={selectedRow}
      />
      <div className="flex items-center flex-wrap gap-3 justify-between">
        <div className="relative">
          <InputField
            placeholder="Search for rider"
            type="text"
            name="search"
            className="!pl-10 !h-[45px] w-full md:w-[350px] !bg-catSkillWhite"
          />
          <BiSearch className="absolute bottom-[9px] left-2 w-5 h-5" />
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <div className="w-[11rem] relative flex items-center gap-2 text-sm py-[8px] px-[12px] cursor-pointer rounded-xl bg-white text-[#6F767E] border border-[#EFEFEF]">
            <BiFilterAlt size={16} />
            <Dropdown
              items={filterItems}
              selectedItem={activeStatus || "Filter [All]"}
              setSelectedItem={(item) => handleFilterStatus(item)}
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            columns={riderColumns}
            data={currentRiders?.filter((rider) => rider?.active)}
            type="You don’t have any active rider. Approve a rider to get started"
          />
        )}
      </div>
    </Card>
  );
};

export default Details;
