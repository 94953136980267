import ReactApexChart from "react-apexcharts";
import Card from "shared/Card";
import ChartLabel from "shared/ChartLabel";

const RiderChart = () => {
  const chartOptions = {
    series: [
      {
        name: "Riders Performance",
        data: [28, 29, 23, 26, 22, 22, 23],
      },
      {
        name: "Distance covered",
        data: [12, 11, 14, 18, 17, 13, 13],
      },
      {
        name: "Delivery Rating",
        data: [17, 9, 24, 20, 25, 11, 17],
      },
    ],
    options: {
      chart: {
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      zoom: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#FFBF05", "#475569", "#F03031"],
      yaxis: {
        min: 5,
        max: 30,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
  };

  return (
    <Card>
      <div className="flex flex-wrap gap-4 justify-between items-center mb-8">
        <div className={`flex gap-4 items-center justify-start`}>
          <div className="w-[16px] h-[32px] rounded-sm bg-malachite"></div>
          <p className="text-[12px] md:text-[20px] font-semibold">Rider</p>
        </div>
        <div className="flex gap-2 flex-wrap">
          <ChartLabel color={"bg-amber"} text={"Riders Performance"} />
          <ChartLabel color={"bg-fiord"} text={"Distance covered"} />
          <ChartLabel color={"bg-pomegranate"} text={"Delivery Rating"} />
        </div>
      </div>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        height={350}
      />
    </Card>
  );
};

export default RiderChart;
