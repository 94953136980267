import Card from "shared/Card";
import { LHIconTrash } from "assets/svgs";
import Button from "shared/Button";
import { useEffect, useState } from "react";
import AssignClientModal from "features/rider/singlerider/modal/assignClient";
import AssignRiderToTeamModal from "../modal/assignridertoteam";
import { useDispatch } from "react-redux";
import { getRidersByTeamId } from "appstate/features/fleet/fleetActions";
import { getClientByTeamId } from "appstate/features/client/clientActions";

const TeamInfo = ({ currentTeam }) => {
  const [isAssignClientShown, setIsAssignClientShown] = useState(false);
  const [isAssignRiderShown, setIsAssignRiderShown] = useState(false);
  const [teamRiders, setTeamRiders] = useState([]);
  const [teamClients, setTeamClients] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    currentTeam &&
      dispatch(getClientByTeamId({ id: currentTeam._id })).then((data) => {
        setTeamClients(data?.payload?.data?.data?.client);
      });
  }, [dispatch, currentTeam._id, currentTeam]);

  useEffect(() => {
    dispatch(getRidersByTeamId({ teamId: currentTeam._id, page: 1 })).then(
      (data) => {
        setTeamRiders(data?.payload?.data?.riders);
      }
    );
  }, [dispatch, currentTeam._id]);

  return (
    <Card className="w-2/5 flex flex-col gap-3">
      <AssignClientModal
        isShown={isAssignClientShown}
        hide={() => setIsAssignClientShown(false)}
        teamId={currentTeam._id}
      />
      <AssignRiderToTeamModal
        isShown={isAssignRiderShown}
        hide={() => setIsAssignRiderShown(false)}
        team={currentTeam}
        teamRiders={teamRiders}
        setTeamRiders={setTeamRiders}
      />
      <div className="flex items-center gap-2">
        <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
          <p className="text-[23px] font-medium text-paleSky">
            {currentTeam?.name?.split(" ")[0]?.charAt(0)?.toUpperCase()}
            {currentTeam?.name?.split(" ")[1]?.charAt(0)?.toUpperCase()}
          </p>
        </div>
        <div>
          <p className="text-[14px] text-paleSky capitalize">
            {currentTeam?.name}
          </p>
        </div>
      </div>
      <div className="border-b pb-4">
        <Button className="!w-full !h-[45px]" blue>
          Edit Team Info
        </Button>
      </div>
      <div>
        <div className="flex items-center justify-between">
          <p className="text-[16px] font-medium">Assigned Client(s)</p>
          <LHIconTrash className="w-5 h-5" />
        </div>
        <div className="flex flex-col my-2 gap-3 border-b">
          <div className="my-1 flex gap-2 flex-col items-center">
            {teamClients?.map((client, idx) => (
              <div
                key={idx}
                className="w-full flex justify-between gap-2 items-center"
              >
                <div className="flex items-center gap-2">
                  <div className="w-[72px] h-[72px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
                    <p className="text-[18px] font-medium text-paleSky">
                      {client?.name?.split(" ")[0]?.charAt(0)}
                      {client?.name?.split(" ")[1]?.charAt(0)}
                    </p>
                  </div>
                  <div>
                    <p className="text-[14px] capitalize text-paleSky">
                      {client?.name}
                    </p>
                    <p className="text-grayChateau text-[14px]">
                      {client?.sector}
                    </p>
                  </div>
                </div>
                <input type="checkbox" />
              </div>
            ))}
          </div>
        </div>
        {/* <div>
          <Button
            // onClick={() => setIsAssignClientShown(true)}
            className="!w-full !h-[45px]"
            blue
          >
            Assign Client
          </Button>
        </div> */}
      </div>
      <div>
        <div className="flex items-center justify-between mt-3">
          <p className="text-[16px] font-medium">Assigned Rider(s)</p>
          <LHIconTrash className="w-5 h-5" />
        </div>
        <div className="flex flex-col my-2 gap-3">
          {teamRiders?.map((rider) => (
            <div
              key={rider?._id}
              className="my-1 flex justify-between items-center"
            >
              <div className="flex gap-2 items-center">
                <div className="w-[60px] h-[60px] border-8 rounded-full border-catSkillWhite flex items-center justify-center">
                  <p className="text-[18px] font-medium text-paleSky">
                    {rider?.user?.firstName?.charAt(0)?.toUpperCase()}
                    {rider?.user?.lastName?.charAt(0)?.toUpperCase()}
                  </p>
                </div>
                <div>
                  <p className="text-[14px] text-paleSky capitalize">
                    {rider?.user?.firstName} {rider?.user?.lastName}
                  </p>
                  <p className="text-grayChateau text-[14px]">
                    {rider?.user?.mobile}
                  </p>
                </div>
              </div>
              <input type="checkbox" />
            </div>
          ))}
        </div>
        <div>
          <Button
            onClick={() => setIsAssignRiderShown(true)}
            className="!w-full !h-[45px]"
            blue
          >
            Assign Rider
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default TeamInfo;
