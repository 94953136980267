import {
  getOneOrder,
  updateOrderAmount,
} from "appstate/features/order/orderActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";

const UpdateOrderAmountContent = ({
  hide,
  id,
  currentOrder,
  setCurrentOrder,
}) => {
  const [amount, setAmount] = useState(currentOrder?.amount);
  const { isLoading } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!amount) {
      toast.error("Please enter amount!");
      return;
    } else {
      dispatch(updateOrderAmount({ id, body: { amount } })).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success(`Order amount updated!`);
          dispatch(getOneOrder({ id })).then((data) =>
            setCurrentOrder(data?.payload?.data?.data)
          );
          hide();
        }
      });
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="min-h-[120px]">
            <InputField
              placeholder="Enter amount"
              name="amount"
              className="!h-[50px]"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : "Update Amount"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOrderAmountContent;
