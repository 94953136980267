import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  pricingGet,
  pricingRulesGet,
  locationsClustersGet,
  locationsAdd,
  clustersAdd,
  locationsEdit,
  locationsDelete,
  clusterDelete,
  pricingAdd,
  pricingRuleDelete,
  ClusterEdit,
} from "appstate/api/pricing";

export const getPricing = createAsyncThunk("pricing/getPricing", pricingGet);
export const getPricingRules = createAsyncThunk(
  "pricing/getPricingRules",
  pricingRulesGet
);
export const deletePricingRule = createAsyncThunk(
  "pricing/deletePricingRule",
  pricingRuleDelete
);
export const getLocationsClusters = createAsyncThunk(
  "pricing/getLocationsClusters",
  locationsClustersGet
);
export const addLocation = createAsyncThunk(
  "pricing/addLocation",
  locationsAdd
);
export const addCluster = createAsyncThunk("pricing/addCluster", clustersAdd);
export const editLocation = createAsyncThunk(
  "pricing/editLocation",
  locationsEdit
);
export const deleteLocation = createAsyncThunk(
  "pricing/deleteLocation",
  locationsDelete
);
export const deleteCluster = createAsyncThunk(
  "pricing/deleteCluster",
  clusterDelete
);
export const addPricing = createAsyncThunk("pricing/addPricing", pricingAdd);
export const editCluster = createAsyncThunk("pricing/editCluster", ClusterEdit);
