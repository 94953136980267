import Api from "apiInstance";
import { toast } from "react-toastify";

export const manyOrdersGet = async ({ page }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/order/?page=${page}&today=true`,
    });
    return {
      data: response?.data?.data?.orders,
      status: response?.status,
      currentPage: Number(response?.data?.data?.page),
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const filterableOrdersGet = async ({
  page,
  assigned,
  dedicated,
  twoWays,
  status,
  delayed,
  canceled,
  delivered,
  searchTerm,
  merchantId,
  merchantOutletId,
  today,
  startDate,
  endDate,
  limit,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/order/?page=${page}${limit ? `&limit=${limit}` : ``}${
        !merchantId ? "" : `&merchantId=${merchantId}`
      }${!merchantOutletId ? "" : `&merchantOutletId=${merchantOutletId}`}${
        assigned === undefined ? "" : `&assigned=${assigned}`
      }${dedicated === undefined ? "" : `&dedicated=${dedicated}`}${
        twoWays === undefined ? "" : `&twoWays=${twoWays}`
      }${status ? `&status=${status}` : ``}${
        delayed === undefined ? "" : `&delayed=${delayed}`
      }${canceled === undefined ? "" : `&canceled=${canceled}`}${
        delivered === undefined ? "" : `&delivered=${delivered}`
      }${today === undefined ? "" : `&today=${today}`}${
        !startDate ? "" : `&startDate=${startDate}`
      }${!endDate ? "" : `&endDate=${endDate}`}${
        searchTerm ? `&searchTerm=${searchTerm}` : ""
      }`,
    });
    return {
      data: response?.data?.data?.orders,
      status: response?.status,
      currentPage: Number(response?.data?.data?.page),
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const oneOrdersGet = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/order/${id}`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderDeliveryCodeGet = async ({ id }) => {
  try {
    const response = await Api({
      method: "get",
      url: `/order/${id}/deliveryCode`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderCancel = async ({ id, reason }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/order/${id}/cancel`,
      data: reason,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderStart = async ({ id }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/order/${id}/start`,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderComplete = async ({ id, deliveryCode }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/order/${id}/deliver`,
      data: deliveryCode,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderReassign = async ({ id, rider }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/order/${id}/reassignOrder`,
      data: rider,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderAmountUpdate = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/order/updateAmount/${id}`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderCreateDelivery = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/order/delivery`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const orderCreateDeliveryPickup = async (body) => {
  try {
    const response = await Api({
      method: "post",
      url: `/order/pickupAndDelivery`,
      data: body,
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const proofOfDeliveryUpload = async ({ id, body }) => {
  try {
    const response = await Api({
      method: "post",
      url: `/order/${id}/proofOfDelivery`,
      data: body,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    });
    return { data: response?.data, status: response?.status };
  } catch (error) {
    toast.error(error?.message + "!");
  }
};

export const analyticsOrdersGet = async ({
  merchantId,
  merchantOutletId,
  riderAuthId,
}) => {
  try {
    const response = await Api({
      method: "get",
      url: `/analytics/orders/overall${
        merchantId ? `?merchantId=${merchantId}` : ``
      }${merchantOutletId ? `?merchantOutletId=${merchantOutletId}` : ``}${
        riderAuthId ? `?riderAuthId=${riderAuthId}` : ``
      }`,
    });
    return {
      data: response?.data?.data,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const reportAdminDownload = async ({
  merchantId,
  merchantOutletId,
  riderAuthId,
  riderMobile,
  startDate,
  endDate,
  delivered,
  active,
  pending,
}) => {
  try {
    const response = await Api({
      method: "get",
      headers: {
        "Content-Type": `text/csv`,
      },
      url: `/analytics/orders/report${
        merchantId ||
        merchantOutletId ||
        riderAuthId ||
        riderMobile ||
        startDate ||
        endDate ||
        delivered ||
        active ||
        pending
          ? "?"
          : ""
      }${merchantId ? `merchantId=${merchantId}` : ``}${
        merchantOutletId ? `&merchantOutletId=${merchantOutletId}` : ``
      }${riderAuthId ? `&riderAuthId=${riderAuthId}` : ``}${
        riderMobile ? `&riderMobile=${riderMobile}` : ``
      }${startDate ? `${merchantId ? "&" : ""}startDate=${startDate}` : ``}${
        endDate ? `&endDate=${endDate}` : ``
      }${delivered ? `&delivered=${delivered}` : ``}${
        active ? `&active=${active}` : ``
      }${pending ? `&pending=${pending}` : ``}`,
    });
    return {
      data: response?.data,
      status: response?.status,
    };
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
