import { createTeam, getTeams } from "appstate/features/teams/teamActions";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";
import Multiselect from "multiselect-react-dropdown";
import { addTeamValidationSchema } from "validation/team/addTeamSchema";

const AddNewTeamContents = ({ hide, admins }) => {
  const { isLoading } = useSelector((state) => state.team);

  const [selectedManager, setSelectedManager] = useState(null);

  const managersList = admins?.map((manager) => ({
    id: manager?._id,
    name: `${manager?.firstName} ${manager?.lastName}`,
    value: {
      firstName: manager?.firstName,
      lastName: manager?.lastName,
      authId: manager?._id,
      mobile: manager?.mobile,
      email: manager?.email,
      country: "Nigeria",
    },
  }));

  const onSelect = (selectedList, selectedItem) => {
    setSelectedManager(selectedItem.value);
  };

  const onRemove = (selectedList, selectedItem) => {
    setSelectedManager(null);
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      manager: "",
    },
    validationSchema: addTeamValidationSchema,
    onSubmit: (values) => {
      const body = {
        name: values.name,
        manager: selectedManager,
      };
      if (selectedManager) {
        dispatch(createTeam(body)).then((data) => {
          if (data?.payload?.status === 201) {
            dispatch(getTeams({ page: 0 }));
            toast.success(`${body.name} created successfully`);
            hide();
          }
        });
      } else {
        toast.error("Please select a manager!");
        return;
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="border-b pb-6">
          <InputField
            label="Team Name"
            placeholder="Team Name"
            name="name"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.name}
            </p>
          ) : null}
        </div>
        <div className="py-6">
          <Multiselect
            showArrow={true}
            customArrow={
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
              </svg>
            }
            customCloseIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                  fill="#33383F"
                />
              </svg>
            }
            selectionLimit={1}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            options={managersList}
            value={selectedManager}
            placeholder="Add member"
            className="h-[50px] mb-7 pl-2 rounded-lg capitalize bg-catSkillWhite text-[12px]"
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewTeamContents;
