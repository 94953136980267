import { createSlice } from "@reduxjs/toolkit";

import {
  getManyOrders,
  getOneOrder,
  cancelOrder,
  startOrder,
  completeOrder,
  reassignOrder,
  createDeliveryOrder,
  createDeliveryPickupOrder,
  getFilterableOrders,
  updateOrderAmount,
  uploadProofOfDelivery,
} from "./orderActions";
import { format } from "date-fns";

const initialState = {
  isLoading: false,
  orders: [],
  currentOrder: {},
  currentPage: null,
  limit: null,
  total: null,
  filterStatus: "",
  filteredClient: null,
  startDate: format(new Date(), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setFilterStatus(state, action) {
      state.filterStatus = action.payload;
    },
    setFilteredClient(state, action) {
      state.filteredClient = action.payload;
    },
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManyOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getManyOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload?.data;
      state.currentPage = action.payload?.currentPage;
      state.limit = action.payload?.limit;
      state.total = action.payload?.total;
    });
    builder.addCase(getManyOrders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFilterableOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFilterableOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders = action.payload?.data;
      state.currentPage = action.payload?.currentPage;
      state.limit = action.payload?.limit;
      state.total = action.payload?.total;
    });
    builder.addCase(getFilterableOrders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOneOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOneOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentOrder = action.payload?.data?.data;
    });
    builder.addCase(getOneOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(cancelOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(startOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(startOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(startOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(completeOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(completeOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(completeOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(reassignOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(reassignOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(reassignOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createDeliveryOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createDeliveryOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createDeliveryOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createDeliveryPickupOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createDeliveryPickupOrder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createDeliveryPickupOrder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateOrderAmount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrderAmount.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateOrderAmount.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadProofOfDelivery.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadProofOfDelivery.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadProofOfDelivery.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setFilterStatus, setFilteredClient, setStartDate, setEndDate } =
  orderSlice.actions;

export default orderSlice.reducer;
