import {
  addApprovedRate,
  getApprovedRateAdmin,
  getMerchantStores,
  updateApprovedRate,
} from "appstate/features/client/clientActions";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { LHIconClose, LHIconSingleDistance } from "assets/svgs";
import { toast } from "react-toastify";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";

const AddNewRate = ({
  currentClient,
  setApprovedRates,
  hide,
  isEditing,
  currentApprovedRate,
  undefinedClient,
}) => {
  const { fleetTypes } = useSelector((state) => state.fleet);
  const { isLoading } = useSelector((state) => state.pricing);
  const { clients } = useSelector((state) => state.client);

  const [merchantStores, setMerchantStores] = useState([]);
  const [selectedClientStore, setSelectedClientStore] = useState("");
  const [currency, setCurrency] = useState("");
  const [agreedRate, setAgreedRate] = useState("");
  const [locations, setLocations] = useState([]);
  const [locObject, setLocObject] = useState("");
  const [inputIndex, setInputIndex] = useState(0);
  const [googlePlaceObj, setGooglePlaceObj] = useState({});
  const [inputAddresses, setInputAddresses] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);
  const [input, setInput] = useState("");
  const [selectedFleetType, setSelectedFleetType] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  const dispatch = useDispatch();

  const clientList = clients?.map((client) => ({
    id: client?._id,
    name: client?.name,
    value: client?._id,
  }));

  const fleetTypeList = fleetTypes?.map((fleetType) => ({
    id: fleetType?._id,
    name: fleetType?.name,
    value: {
      fleetType: fleetType?.name,
      fleetTypeId: fleetType?._id,
    },
  }));

  const onSelect = (selectedList, selectedItem) => {
    setSelectedFleetType(selectedItem.value);
  };

  useEffect(() => {
    if (selectedClient || currentClient?._id) {
      dispatch(
        getMerchantStores({ id: selectedClient || currentClient?._id, page: 0 })
      ).then((data) => {
        setMerchantStores(data?.payload?.data?.data?.data);
      });
    }
  }, [dispatch, selectedClient, currentClient?._id]);

  const onClientSelect = (selectedList, selectedItem) => {
    setSelectedClient(selectedItem.value);
  };

  const clientStoreList = merchantStores?.map((store) => ({
    id: store?._id,
    name: store?.name,
    value: {
      merchantOutletId: store?._id,
    },
  }));

  const onClientStoreSelect = (selectedList, selectedItem) => {
    setSelectedClientStore(selectedItem.value.merchantOutletId);
  };

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: [],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    const getLngLat = () => {
      const newLocations = locations;
      newLocations[inputIndex - 1] = {
        ...newLocations[inputIndex - 1],
        locLongitude: locObject?.geometry?.location?.lng((e) => {
          return e;
        }),
        locLatitude: locObject?.geometry?.location?.lat((e) => {
          return e;
        }),
        city: locObject && locObject?.address_components[1]?.long_name,
        state: locObject && locObject?.address_components[2]?.long_name,
      };
      setLocations(newLocations);
    };
    locObject && getLngLat();
  }, [locObject, inputIndex, locations]);

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        { placeId: googlePlaceObj.place_id },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, googlePlaceObj, placesService]);

  const body = {
    merchantId: undefinedClient ? selectedClient : currentClient?._id,
    merchantOutletId: selectedClientStore,
    country: "Nigeria",
    locations,
    rate: Number(agreedRate),
    fleetType: selectedFleetType?.fleetType,
    fleetTypeId: selectedFleetType?.fleetTypeId,
    currency: currency ? currency : "NGN",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isEditing
      ? dispatch(
          updateApprovedRate({
            id: currentApprovedRate?._id,
            body: {
              rate: Number(agreedRate),
              approved: currentApprovedRate?.approved,
              active: currentApprovedRate?.active,
            },
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            toast.success("Approved rate updated!");
            dispatch(
              getApprovedRateAdmin({
                merchantId: currentClient?._id,
                page: 1,
              })
            ).then((data) => {
              setApprovedRates(data?.payload?.data);
            });
            hide();
          }
        })
      : dispatch(addApprovedRate(body)).then((data) => {
          if (data?.payload?.status === 201) {
            toast.success("Approved rate added!");
            dispatch(
              getApprovedRateAdmin({
                merchantId: currentClient?._id,
                page: 1,
              })
            ).then((data) => {
              setApprovedRates(data?.payload?.data);
            });
            hide();
          }
        });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {undefinedClient && !isEditing && (
          <div className="flex gap-1 flex-col">
            <p className="font-normal mt-2 text-xs text-midGray">
              Client Information*
            </p>
            <div className="">
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onClientSelect}
                options={clientList}
                value={selectedClient}
                placeholder="Select client"
                className="h-[50px] mb-2 pl-2 rounded-lg bg-catSkillWhite text-[12px]"
              />
            </div>
          </div>
        )}
        {!isEditing && merchantStores?.length > 0 && (
          <div className="flex gap-1 flex-col mb-3">
            <p className="font-normal mt-2 text-xs text-midGray">
              Select store
            </p>
            <div className="">
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onClientStoreSelect}
                options={clientStoreList}
                value={selectedClientStore}
                placeholder="Optional store"
                className="h-[50px] mb-2 pl-2 rounded-lg bg-catSkillWhite text-[12px]"
              />
            </div>
          </div>
        )}
        <div className="relative mb-3">
          <p className="font-normal mt-2 text-xs text-midGray">Rate*</p>
          <div className="flex gap-1">
            <div className="w-1/4">
              <InputSelect
                datas={[{ name: "NGN", value: "NGN" }]}
                placeholder="Select currency"
                name="currency"
                className="!h-[50px]"
                value={currency}
                required
                onChange={(e) => setCurrency(e.target.value)}
              />
            </div>
            <div className="w-3/4">
              <InputField
                placeholder="Enter agreed rate"
                name="amount"
                className="!h-[50px]"
                value={agreedRate}
                type="number"
                required
                onChange={(e) => setAgreedRate(e.target.value)}
              />
            </div>
          </div>
        </div>
        {!isEditing && (
          <>
            <div className="mb-3">
              <p className="text-[12px] text-fiord mb-2">Select fleet type</p>
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onSelect}
                options={fleetTypeList}
                value={selectedFleetType}
                placeholder="Select fleet type"
                className="h-[50px] rounded-lg px-2 bg-catSkillWhite text-[12px]"
              />
            </div>
            <div className="pb-4">
              <InputField
                placeholder="Enter location"
                label="Add Locations*"
                name="address"
                id="address"
                className="!h-[50px]"
                onChange={(e) => {
                  setShowPredictions(true);
                  setInput(e.target.value);
                  const newInputAddresses = inputAddresses;
                  newInputAddresses[inputIndex] = e.target.value;
                  setInputAddresses(newInputAddresses);
                  getPlacePredictions({ input: e.target.value });
                }}
                loading={isPlacePredictionsLoading.toString()}
                value={input}
              />
            </div>
            <div className="">
              {showPredictions &&
                !isPlacePredictionsLoading &&
                placePredictions.map((item, idx) => (
                  <p
                    className="text-[12px] hover:bg-slate-100 p-1 rounded-lg text-gullGray cursor-pointer"
                    key={idx}
                    onClick={() => {
                      setShowPredictions(false);
                      setInput("");
                      setGooglePlaceObj(item);
                      const newLocations = locations;
                      newLocations[inputIndex] = {
                        ...newLocations[inputIndex],
                        address: item.description,
                      };
                      setLocations(newLocations);
                      setInputIndex((prev) => prev + 1);
                    }}
                  >
                    {item.description}
                  </p>
                ))}
            </div>
            {locations.length > 0 && (
              <div className="mb-4">
                <div className="border-b py-4">
                  <p className="text-[12px] my-2">Locations</p>
                  {locations?.map((address, idx) => (
                    <div key={idx} className="flex justify-between">
                      <div className="flex justify-start gap-2">
                        <LHIconSingleDistance />
                        <p className="text-[12px]">{address?.address}</p>
                      </div>
                      <LHIconClose
                        className="cursor-pointer"
                        onClick={() => {
                          setLocations(
                            locations.filter(
                              (selected) => selected.address !== address.address
                            )
                          );
                          setInputIndex((prev) => prev - 1);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="Add Rate" className="!h-[45px]" blue>
            {isLoading ? (
              <ButtonAnimation />
            ) : isEditing ? (
              "Update Rate"
            ) : (
              "Add Rate"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewRate;
