import DashboardLayout from "layout/dashboardlayout";
import StatsHeader from "./StatsHeader";
import DeliveryChart from "./DeliveryChart";
import RiderChart from "./RiderChart";
import ClientChart from "./ClientChart";

const StatsContents = () => {
  return (
    <DashboardLayout>
      <StatsHeader />
      <DeliveryChart />
      <ClientChart />
      <RiderChart />
    </DashboardLayout>
  );
};

export default StatsContents;
