import Card from "./Card";

const TableControl = ({ controls }) => {
  return (
    <Card className="!shadow-lg !gap-0 !p-0">
      {controls?.map((control, idx) => (
        <div
          key={idx}
          onClick={control.action}
          className="cursor-pointer px-6 py-3 hover:bg-catSkillWhite"
        >
          <p className="text-[12px] whitespace-nowrap">{control.text}</p>
        </div>
      ))}
    </Card>
  );
};

export default TableControl;
