import { getAdmins } from "appstate/features/admin/adminActions";
import { LHIconTrash } from "assets/svgs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import Card from "shared/Card";
import Table from "shared/Table";
import AddAdminToTeamModal from "../modal/addAdminToTeam";
import {
  getTeamByID,
  removeAdminUserFromTeam,
} from "appstate/features/teams/teamActions";
import { toast } from "react-toastify";
import Loader from "shared/Loader";

const AssignedMembers = ({ currentTeam }) => {
  const { isLoading } = useSelector((state) => state.team);

  const [admins, setAdmins] = useState([]);
  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdmins()).then((data) => {
      if (data?.payload?.status === 200) {
        setAdmins(data?.payload?.data);
      }
    });
  }, [dispatch]);

  const assignedMembersColumns = [
    {
      Header: "Name",
      accessor: (d) => (
        <p className="flex justify-start ml-4">
          {d.firstName ? `${d.firstName} ${d.lastName}` : "N/A"}
        </p>
      ),
    },
    {
      Header: "Email",
      accessor: (d) => <p className="flex justify-start ml-4">{d.email}</p>,
    },
    {
      Header: "Mobile",
      accessor: (d) => <p className="flex justify-start ml-4">+{d.mobile}</p>,
    },
    {
      Header: "Role",
      accessor: () => <p className="flex justify-start ml-4">Admin</p>,
    },
    {
      Header: "  ",
      accessor: (d) => (
        <LHIconTrash
          onClick={() => {
            dispatch(
              removeAdminUserFromTeam({
                teamId: currentTeam?._id,
                adminId: d?.authId,
              })
            ).then((data) => {
              if (data?.payload?.status === 200) {
                dispatch(getTeamByID({ id: currentTeam?._id }));
                toast.success(
                  `${d.firstName} ${d.lastName} removed successfully!`
                );
              }
            });
          }}
          className="cursor-pointer w-5 h-5"
        />
      ),
    },
  ];

  return (
    <Card>
      <AddAdminToTeamModal
        isShown={isShown}
        currentTeam={currentTeam}
        admins={admins}
        hide={() => setIsShown(false)}
      />
      <div className="flex justify-between gap-3 items-center">
        <p className="text-[12px]">Assigned Member(s)</p>
        <Button
          onClick={() => setIsShown(true)}
          className="!h-[40px]"
          type="button"
          blue
        >
          Add New Members
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={assignedMembersColumns}
          data={currentTeam?.users}
          type="You don’t have any assigned members. Add a new member to get started"
        />
      )}
    </Card>
  );
};

export default AssignedMembers;
