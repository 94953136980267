import React from "react";
import DashboardHeader from "./header/DashboardHeader";
import DashboardSidebar from "./sidebar/DashboardSidebar";

const DashboardLayout = ({ children, className }) => {
  return (
    <div className="flex w-full h-screen">
      <DashboardSidebar />
      <div className="h-full w-full flex flex-col gap-6 overflow-hidden">
        <DashboardHeader />
        <div
          className={`overflow-y-auto bg-wildSand h-full flex gap-6 mx-4 pb-6 ${className} flex-col flex-1`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
