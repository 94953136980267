import { getUserById } from "appstate/features/auth/authActions";
import { assignRiderToClient } from "appstate/features/fleet/fleetActions";
import {
  addMerchantsToTeam,
  getTeamByID,
} from "appstate/features/teams/teamActions";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";

const AssignClientContent = ({
  hide,
  teamId,
  currentRider,
  setCurrentRider,
}) => {
  const { isLoading } = useSelector((state) => state.team);
  const { clients } = useSelector((state) => state.client);
  const [selectedMerchants, setSelectedMerchants] = useState([]);

  const merchantList = teamId
    ? clients.map((merchant) => ({
        id: merchant?._id,
        name: merchant?.name,
        value: {
          merchantName: merchant?.name,
          merchantId: merchant?._id,
        },
      }))
    : currentRider
    ? clients.map((merchant) => ({
        id: merchant?._id,
        name: merchant?.name,
        value: {
          merchantId: merchant?._id,
        },
      }))
    : null;

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedMerchants?.length === 0) {
      toast.error("Please select clients to assign!");
      return;
    }
    teamId
      ? dispatch(
          addMerchantsToTeam({
            id: teamId,
            merchant: { merchant: selectedMerchants },
          })
        ).then((data) => {
          if (data?.payload?.status === 201) {
            dispatch(getTeamByID({ id: teamId }));
            toast.success(`Clients assigned successfully!`);
            hide();
          }
        })
      : dispatch(
          assignRiderToClient({
            riderId: currentRider?._id,
            clientId: selectedMerchants[0].merchantId,
          })
        ).then((data) => {
          if (data?.payload?.status === 200) {
            dispatch(getUserById({ id: currentRider?._id })).then((data) => {
              if (data?.payload?.status === 200) {
                setCurrentRider(data?.payload?.data?.data);
              }
            });
            toast.success(`Client assigned successfully!`);
            hide();
          }
        });
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedMerchants([...selectedMerchants, selectedItem.value]);
  };

  const onRemove = (selectedList, selectedItem) => {
    setSelectedMerchants(
      selectedList.filter((items) => items !== selectedItem)
    );
  };

  return (
    <div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <p className="text-[14px] text-fiord">Search for clients</p>
        <div className="pb-6">
          <Multiselect
            showArrow={true}
            customArrow={
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
              </svg>
            }
            customCloseIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                  fill="#33383F"
                />
              </svg>
            }
            selectionLimit={currentRider && 1}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            options={merchantList}
            value={selectedMerchants}
            placeholder="Search for client by name"
            className="h-[50px] pl-2 rounded-lg bg-catSkillWhite text-[12px]"
          />
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Assign"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AssignClientContent;
