import Modal from "layout/modal/Modal";
import React from "react";
import AddNewFleet from "./AddNewFleet";

const AddNewFleetModal = ({ isShown, hide }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Fleet">
      <AddNewFleet hide={hide} />
    </Modal>
  );
};

export default AddNewFleetModal;
