import {
  LHIconEmail,
  LHIconPasswordNotVisible,
  LHIconPasswordVisible,
} from "assets/svgs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import ButtonAnimation from "shared/buttonAnimation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  createMerchantUsers,
  getMerchantUsers,
} from "appstate/features/client/clientActions";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { addMerchantUserValidationSchema } from "validation/client/addMerchantUserSchema";

const AddNewUserContents = ({ hide, currentClient, setMerchantUsers }) => {
  const { isLoading } = useSelector((state) => state.client);

  const [viewPassword, setViewPassword] = useState(true);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(true);
  const [recipientMobile, setRecipientMobile] = useState("");
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [locObject, setLocObject] = useState("");
  const [address, setAddress] = useState({});
  const [input, setInput] = useState("");
  const [showPredictions, setShowPredictions] = useState(false);

  const dispatch = useDispatch();

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: ["address"],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: address.place_id,
        },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, address, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      setLng(
        locObject?.geometry?.location?.lng((e) => {
          return e;
        })
      );
      setLat(
        locObject?.geometry?.location?.lat((e) => {
          return e;
        })
      );
    };
    getLngLat();
  }, [locObject]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      country: "",
    },
    validationSchema: addMerchantUserValidationSchema,
    onSubmit: (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: values.role,
        country: values.country,
        mobile: recipientMobile,
        address: input,
        locLongitude: lng,
        locLatitude: lat,
      };
      dispatch(createMerchantUsers({ id: currentClient?._id, body })).then(
        (data) => {
          if (data?.payload?.status === 200) {
            toast.success(`User ${body.firstName} added successfully`);
            hide();
            dispatch(getMerchantUsers({ id: currentClient?._id })).then(
              (data) => {
                if (data?.payload?.status === 200) {
                  setMerchantUsers(data?.payload?.data?.data);
                }
              }
            );
          }
        }
      );
    },
  });

  return (
    <div>
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <div className="flex gap-4 justify-between">
          <div className="w-1/2">
            <InputField
              placeholder="First Name"
              name="firstName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.firstName}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <InputField
              placeholder="Last Name"
              name="lastName"
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.lastName}
              </p>
            ) : null}
          </div>
        </div>
        <div className="relative">
          <InputField
            placeholder="Email address"
            type="email"
            name="email"
            className="!pl-10 !pr-36 !h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <LHIconEmail className="absolute bottom-3 left-2" />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.email}
            </p>
          ) : null}
        </div>
        <div className="flex justify-between gap-4">
          <div className="w-1/2">
            <div className="relative">
              <InputField
                placeholder="Password"
                type={`${viewPassword ? "password" : "text"}`}
                name="password"
                required
                className="!h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {!viewPassword ? (
                <LHIconPasswordVisible
                  onClick={() => setViewPassword(true)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              ) : (
                <LHIconPasswordNotVisible
                  onClick={() => setViewPassword(false)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.password}
              </p>
            ) : null}
          </div>
          <div className="w-1/2">
            <div className="relative">
              <InputField
                placeholder="Confirm Password"
                type={`${viewConfirmPassword ? "password" : "text"}`}
                name="confirmPassword"
                required
                className="!h-[50px]"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {!viewConfirmPassword ? (
                <LHIconPasswordVisible
                  onClick={() => setViewConfirmPassword(true)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              ) : (
                <LHIconPasswordNotVisible
                  onClick={() => setViewConfirmPassword(false)}
                  className="absolute bottom-3 right-4 cursor-pointer"
                />
              )}
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.confirmPassword}
              </p>
            ) : null}
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div className="relative w-1/2">
            <InputField
              placeholder="Country"
              name="country"
              required
              className="!h-[50px]"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
            />
          </div>
          <div className="w-1/2">
            <PhoneInput
              placeholder="Mobile Number"
              country={"ng"}
              onlyCountries={["ng", "gh", "ke"]}
              value={recipientMobile}
              onChange={setRecipientMobile}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            />
          </div>
        </div>
        <div className="">
          <InputSelect
            admin
            datas={[
              {
                name: "Admin",
                value: "admin",
              },
              {
                name: "Manager",
                value: "Manager",
              },
              {
                name: "User",
                value: "User",
              },
            ]}
            placeholder="Role"
            name="role"
            className="!h-[50px]"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
          />
          {formik.touched.role && formik.errors.role ? (
            <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
              {formik.errors.role}
            </p>
          ) : null}
        </div>
        <div className="pb-6">
          <div className="">
            <InputField
              placeholder="Address"
              name="address"
              className="!h-[50px]"
              onChange={(e) => {
                setShowPredictions(true);
                setInput(e.target.value);
                getPlacePredictions({ input: e.target.value });
              }}
              loading={isPlacePredictionsLoading.toString()}
              value={input}
            />
            {showPredictions &&
              !isPlacePredictionsLoading &&
              placePredictions.map((item, idx) => (
                <p
                  className="text-[12px] mb-2 hover:bg-slate-100 p-1 rounded-lg text-gullGray cursor-pointer"
                  key={idx}
                  onClick={() => {
                    setInput(item?.description);
                    setShowPredictions(false);
                    setAddress(item);
                  }}
                >
                  {item.description}
                </p>
              ))}
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px]" blue>
            {isLoading ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewUserContents;
