import Modal from "layout/modal/Modal";
import UpdateRiderTeamContents from "./UpdateRiderTeam";

const UpdateRiderTeamModal = ({
  isShown,
  hide,
  currentRider,
  setCurrentRider,
}) => {
  return (
    <Modal isShown={isShown} hide={hide} title="Edit rider team">
      <UpdateRiderTeamContents
        hide={hide}
        currentRider={currentRider}
        setCurrentRider={setCurrentRider}
      />
    </Modal>
  );
};

export default UpdateRiderTeamModal;
