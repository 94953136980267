import { createSlice } from "@reduxjs/toolkit";

import {
  createInvoice,
  getInvoices,
  getOneInvoice,
  changeInvoiceStatus,
  addOneOrderToInvoice,
  addMultipleOrdersToInvoice,
  removeOrderFromInvoice,
} from "./invoiceActions";

const initialState = {
  isLoading: false,
  invoices: [],
  singleInvoice: null,
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoices = action?.payload?.data?.data?.invoice;
    });
    builder.addCase(getInvoices.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOneInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOneInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singleInvoice = action?.payload?.data?.data;
    });
    builder.addCase(getOneInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeInvoiceStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeInvoiceStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeInvoiceStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addOneOrderToInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addOneOrderToInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addOneOrderToInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addMultipleOrdersToInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addMultipleOrdersToInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addMultipleOrdersToInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeOrderFromInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeOrderFromInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeOrderFromInvoice.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export default invoiceSlice.reducer;
