import { LHNoData } from "assets/images";
import React from "react";

const NoData = ({ type }) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-[350px] h-[350px] max-md:w-[200px] max-md:h-[200px]">
        <img
          className="w-full h-full object-cover"
          src={LHNoData}
          alt={`No ${type} found`}
        />
      </div>
      <p className="text-[14px]">{type}</p>
    </div>
  );
};

export default NoData;
