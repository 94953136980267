const OverviewCard = ({
  title,
  text,
  bgColor,
  icon,
  isStatus,
  isSub,
  isTotal,
  isActive,
}) => {
  return (
    <div
      className={`flex gap-2 items-center justify-between bg-white p-3 rounded-lg ${
        isTotal ? "w-[90%]" : "w-[20rem]"
      }`}
    >
      <div className="flex items-center gap-2">
        {" "}
        <div className={`${bgColor} rounded-full text-white p-2`}>{icon}</div>
        <h3 className="font-semibold text-fiord text-xs md:text-sm">{title}</h3>
      </div>
      {!isStatus && (
        <p
          className={`${
            isSub ? "text-lg font-semibold " : "text-[10px] md:text-xs"
          }  text-fiord`}
        >
          {text}
        </p>
      )}
      {isStatus && (
        <p
          className={`${
            isActive ? "text-greenCol bg-[#11E16417] " : "text-red bg-lightRed "
          } rounded-lg p-1 px-2`}
        >
          {isActive ? "Active" : "Inactive"}
        </p>
      )}
    </div>
  );
};

export default OverviewCard;
