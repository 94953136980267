import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import InvoiceOverview from "./InvoiceOverview";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneInvoice } from "appstate/features/invoice/invoiceActions";
import { toast } from "react-toastify";

const SingleInvoiceContents = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { singleInvoice } = useSelector((state) => state.invoice);

  useEffect(() => {
    dispatch(getOneInvoice({ id })).then((data) => {
      if (data?.payload?.status !== 201) {
        toast.error(`Unable to get Invoice with id ${id}`);
        navigate("/admin/invoice");
        return;
      }
    });
  }, [dispatch, id, navigate]);

  const generatePDF = () => {
    const input = document.getElementById("invoice");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [550, 620],
        compress: true,
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`Invoice ${singleInvoice.invoiceId}.pdf`);
    });
  };

  return (
    <DashboardLayout>
      <div id="invoice">
        <Header singleInvoice={singleInvoice} />
        <InvoiceOverview
          singleInvoice={singleInvoice}
          generatePDF={generatePDF}
        />
      </div>
    </DashboardLayout>
  );
};

export default SingleInvoiceContents;
