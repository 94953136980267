import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import InputField from "shared/InputField";
import InputSelect from "shared/InputSelect";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { LHIconCalendar, LHIconEmail, LHIconSingleDistance } from "assets/svgs";
import TextArea from "shared/TextArea";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import TimePicker from "react-time-picker";
import Button from "shared/Button";
import ButtonAnimation from "shared/buttonAnimation";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import { getMerchantStores } from "appstate/features/client/clientActions";
import {
  getAvailableRidersForDelivery,
  getRidersByTeamId,
} from "appstate/features/fleet/fleetActions";
import ConfirmOrder from "./ConfirmOrder";

const PickupDeliveryContent = ({ hide, setCurrentOrders }) => {
  const { clients } = useSelector((state) => state.client);
  const { fleetTypes } = useSelector((state) => state.fleet);

  const dispatch = useDispatch();

  const [pickupLat, setPickupLat] = useState(null);
  const [pickupLng, setPickupLng] = useState(null);
  const [destinationLat, setDestinationLat] = useState(null);
  const [destinationLng, setDestinationLng] = useState(null);
  const [locObject, setLocObject] = useState("");
  const [address, setAddress] = useState({});
  const [pickupAddressInput, setPickUpAddressInput] = useState("");
  const [destinationAddressInput, setDestinationAddressInput] = useState("");
  const [showPickupPredictions, setShowPickupPredictions] = useState(false);
  const [showDestinationPredictions, setShowDestinationPredictions] =
    useState(false);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientMobile, setRecipientMobile] = useState("");
  const [recipientInstruction, setRecipientInstruction] = useState("");
  const [pickupName, setPickupName] = useState("");
  const [pickupEmail, setPickuptEmail] = useState("");
  const [pickupMobile, setPickuptMobile] = useState("");
  const [pickupInstruction, setPickupInstruction] = useState("");
  const [instantDelivery, setInstantDelivery] = useState(true);
  const [recipientPays, setRecipientPays] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedRider, setSelectedRider] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [currency, setCurrency] = useState("");
  const [index, setIndex] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientStore, setSelectedClientStore] = useState(null);
  const [merchantStores, setMerchantStores] = useState(null);
  const [selectedClientTeamId, setSelectedClientTeamId] = useState(null);
  const [selectedClientTeamRiders, setSelectedClientTeamRiders] =
    useState(null);
  const [ridersToUse, setRidersToUse] = useState([]);
  const [riderOption, setRiderOption] = useState("Available Riders");
  const [selectedFleetType, setSelectedFleetType] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [merchantLocation, setMerchantLocation] = useState(null);
  const [merchantOutletLocation, setMerchantOutletLocation] = useState(null);
  const [outletTeam, setOutletTeam] = useState(null);

  let longitude;
  let latitude;
  if (merchantOutletLocation) {
    longitude = merchantOutletLocation ? merchantOutletLocation[0] : "";
    latitude = merchantOutletLocation ? merchantOutletLocation[1] : "";
  } else {
    longitude = merchantLocation ? merchantLocation[0] : "";
    latitude = merchantLocation ? merchantLocation[1] : "";
  }

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(
        getAvailableRidersForDelivery({
          merchantId: selectedClient?.merchantId,
          locLatitude: latitude,
          locLongitude: longitude,
        })
      ).then((data) => {
        setRiderOption("Available Riders");
        setRidersToUse(data?.payload?.data);
      });
    }
  }, [dispatch, selectedClient?.merchantId, latitude, longitude]);

  const ridersList = ridersToUse?.map((rider) => ({
    id: rider?._id,
    name: `${rider?.user?.firstName} ${rider?.user?.lastName}`,
    value: {
      firstName: rider?.user?.firstName,
      lastName: rider?.user?.lastName,
      authId: rider?.user?.authId,
      mobile: rider?.user?.mobile,
      email: rider?.user?.email,
      country: "Nigeria",
    },
  }));

  const clientList = clients
    ?.filter((client) => client.approved)
    ?.map((client) => ({
      id: client?._id,
      name: client?.name,
      location: client?.location?.coordinates,
      value: {
        merchantId: client?._id,
        merchantName: client?.name,
      },
    }));

  const clientStoreList = merchantStores?.map((store) => ({
    id: store?._id,
    name: store?.name,
    location: store?.location?.coordinates,
    team: store?.team,
    value: {
      name: store?.name,
      outletId: store?._id,
    },
  }));

  const fleetTypeList = fleetTypes?.map((fleetType) => ({
    id: fleetType?._id,
    name: fleetType?.name,
    value: {
      fleetType: fleetType?.name,
      fleetTypeId: fleetType?._id,
    },
  }));

  const onFleetTypeSelect = (selectedList, selectedItem) => {
    setSelectedFleetType(selectedItem.value);
  };

  const onFleetTypeRemove = (selectedList, selectedItem) => {
    setSelectedFleetType(null);
  };

  const options = {
    componentRestrictions: { country: ["ng"] },
    types: ["address"],
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    options,
  });

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: address.place_id,
        },
        (placeDetails) => setLocObject(placeDetails)
      );
  }, [placePredictions, address, placesService]);

  useEffect(() => {
    const getLngLat = () => {
      if (index === 0) {
        setPickupLng(
          locObject?.geometry?.location?.lng((e) => {
            return e;
          })
        );
        setPickupLat(
          locObject?.geometry?.location?.lat((e) => {
            return e;
          })
        );
      } else if (index === 1) {
        setDestinationLng(
          locObject?.geometry?.location?.lng((e) => {
            return e;
          })
        );
        setDestinationLat(
          locObject?.geometry?.location?.lat((e) => {
            return e;
          })
        );
      }
    };
    getLngLat();
  }, [locObject, index]);

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        getMerchantStores({ id: selectedClient?.merchantId, page: 0 })
      ).then((data) => {
        setMerchantStores(data?.payload?.data?.data?.data);
      });
    }
  }, [dispatch, selectedClient?.merchantId, selectedClient]);

  useEffect(() => {
    if (selectedClient && selectedClientTeamId) {
      dispatch(
        getRidersByTeamId({ teamId: selectedClientTeamId, page: 1 })
      ).then((data) => {
        setSelectedClientTeamRiders(data?.payload?.data?.riders);
      });
    }
  }, [dispatch, selectedClientTeamId, selectedClient]);

  const onRiderSelect = (selectedList, selectedItem) => {
    setSelectedRider(selectedItem.value);
  };

  const onClientSelect = (selectedList, selectedItem) => {
    setSelectedClient(selectedItem.value);
    setSelectedClientTeamId(selectedItem.teamId);
    setMerchantLocation(selectedItem.location);
  };

  const onClientStoreSelect = (selectedList, selectedItem) => {
    setSelectedClientStore(selectedItem.value);
    setMerchantOutletLocation(selectedItem.location);
    setOutletTeam(selectedItem?.team);
  };

  const body = {
    merchant: selectedClient,
    merchantOutlet: selectedClientStore,
    recipient: {
      name: recipientName,
      mobile: recipientMobile,
      email: recipientEmail,
      instructions: recipientInstruction,
    },
    destinationInformation: {
      address: destinationAddressInput,
      locLatitude: destinationLat,
      locLongitude: destinationLng,
    },
    deliveryDate: deliveryDate.toLocaleDateString("en-CA"),
    deliveryTime,
    instantDelivery,
    recipientPays,
    currency: currency ? currency : "NGN",
    customerToPay: {
      orderCost: parseInt(amount),
      deliveryCost: parseInt(deliveryFee),
    },
    assignedRider: selectedRider,
    deliveryNote: recipientInstruction,
    twoWays: true,
    pickupAddress: pickupAddressInput,
    pickupLocLatitude: pickupLat,
    pickupLocLongitude: pickupLng,
    pickupInformation: {
      firstName: pickupName?.split(" ")[1],
      lastName: pickupName?.split(" ")[0],
      mobile: pickupMobile,
      country: "Nigeria",
      email: pickupEmail,
    },
    availableMerchantRider:
      riderOption === "Available Riders" && selectedRider === null ? true : "",
    dedicated: riderOption === "LH Dedicated Riders" ? true : "",
    outletTeamRider: riderOption === "Team Riders" ? true : "",
    marketplace: riderOption === "Marketplace" ? true : "",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (instantDelivery) {
      setIsConfirmModalOpen(true);
    } else {
      if (new Date().toLocaleDateString("en-CA") > deliveryDate) {
        toast.error("Delivery date must be a future value!");
        return;
      } else if (new Date() < deliveryDate) {
        setIsConfirmModalOpen(true);
      } else {
        if (deliveryTime < new Date().toLocaleTimeString()) {
          toast.error("Delivery time must be a future value!");
          return;
        } else {
          setIsConfirmModalOpen(true);
        }
      }
    }
  };

  return (
    <div>
      <ConfirmOrder
        body={body}
        isShown={isConfirmModalOpen}
        hide={() => {
          hide();
          setIsConfirmModalOpen(false);
        }}
        setCurrentOrders={setCurrentOrders}
      />
      <form onSubmit={handleSubmit}>
        <div className="flex gap-3 flex-col">
          <p className="font-normal mt-2 text-xs text-midGray">
            Client Information
          </p>
          <div className="">
            <Multiselect
              showArrow={true}
              customArrow={
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                </svg>
              }
              customCloseIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                    fill="#33383F"
                  />
                </svg>
              }
              selectionLimit={1}
              displayValue="name"
              onSelect={onClientSelect}
              options={clientList}
              value={selectedClient}
              placeholder="Select client"
              className="h-[50px] mb-2 rounded-lg bg-catSkillWhite text-[12px]"
            />
          </div>
        </div>
        {merchantStores?.length > 0 && (
          <div className="flex gap-1 flex-col">
            <p className="font-normal text-xs text-midGray">
              Client Outlet Information
            </p>
            <div className="">
              <Multiselect
                showArrow={true}
                customArrow={
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                }
                customCloseIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                      fill="#33383F"
                    />
                  </svg>
                }
                selectionLimit={1}
                displayValue="name"
                onSelect={onClientStoreSelect}
                options={clientStoreList}
                value={selectedClientStore}
                placeholder="Select client outlet"
                className="h-[50px] mb-2 rounded-lg bg-catSkillWhite text-[12px]"
              />
            </div>
          </div>
        )}
        <div>
          <div className="flex flex-col gap-2 mb-4">
            <p className="text-xs text-midGray font-normal">
              Pickup Information
            </p>
            <div className="flex max-md:flex-wrap items-center gap-2 justify-between">
              <div className="w-1/2 max-md:w-full">
                <InputField
                  required
                  placeholder="Customer Name"
                  name="pickupName"
                  className="!h-[50px]"
                  value={pickupName}
                  onChange={(e) => setPickupName(e.target.value)}
                />
              </div>
              <div className="w-1/2 max-md:w-full">
                <div className="flex flex-col gap-2">
                  <PhoneInput
                    placeholder="Mobile Number"
                    country={"ng"}
                    onlyCountries={["ng", "gh", "ke"]}
                    value={pickupMobile}
                    onChange={setPickuptMobile}
                    inputProps={{
                      name: "pickupPhone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="relative">
              <InputField
                placeholder="Customer Address"
                name="pickupAddress"
                className="!h-[50px]"
                onChange={(e) => {
                  setIndex(0);
                  setShowPickupPredictions(true);
                  setPickUpAddressInput(e.target.value);
                  getPlacePredictions({ input: e.target.value });
                }}
                loading={isPlacePredictionsLoading.toString()}
                value={pickupAddressInput}
              />
              <LHIconSingleDistance className="absolute right-3 bottom-4" />
              {showPickupPredictions &&
                !isPlacePredictionsLoading &&
                placePredictions?.map((item, idx) => (
                  <p
                    className="text-[12px] hover:bg-slate-100 p-1 rounded-lg mb-2 text-gullGray cursor-pointer"
                    key={idx}
                    onClick={() => {
                      setPickUpAddressInput(item.description);
                      setShowPickupPredictions(false);
                      setAddress(item);
                    }}
                  >
                    {item.description}
                  </p>
                ))}
            </div>
            <div className="relative">
              <InputField
                placeholder="Email address"
                type="email"
                name="pickupEmail"
                className="!pl-10 !pr-36 !h-[50px]"
                value={pickupEmail}
                onChange={(e) => setPickuptEmail(e.target.value)}
              />
              {/* <p className="absolute bottom-3 right-4 text-gullGray text-[14px]">
                @gmail.com
              </p> */}
              <LHIconEmail className="absolute bottom-3 left-2" />
            </div>
            <div className="">
              <TextArea
                autoComplete="true"
                placeholder="Additional Instructions"
                name="pcikupDescription"
                value={pickupInstruction}
                onChange={(e) => setPickupInstruction(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <p className="text-xs text-midGray font-normal">
              Delivery Information
            </p>
            <div className="flex max-md:flex-wrap items-center gap-2 justify-between">
              <div className="w-1/2 max-md:w-full">
                <InputField
                  required
                  placeholder="Customer Name"
                  name="recipientName"
                  className="!h-[50px]"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                />
              </div>
              <div className="w-1/2 max-md:w-full">
                <div className="flex flex-col gap-2">
                  <PhoneInput
                    placeholder="Mobile Number"
                    country={"ng"}
                    onlyCountries={["ng", "gh", "ke"]}
                    value={recipientMobile}
                    onChange={setRecipientMobile}
                    inputProps={{
                      name: "recipientPhone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="relative">
              <InputField
                placeholder="Customer Address"
                name="recipientAddress"
                className="!h-[50px]"
                onChange={(e) => {
                  setIndex(1);
                  setShowDestinationPredictions(true);
                  setDestinationAddressInput(e.target.value);
                  getPlacePredictions({ input: e.target.value });
                }}
                loading={isPlacePredictionsLoading.toString()}
                value={destinationAddressInput}
              />
              <LHIconSingleDistance className="absolute right-3 bottom-4" />
              {showDestinationPredictions &&
                !isPlacePredictionsLoading &&
                placePredictions.map((item, idx) => (
                  <p
                    className="text-[12px] hover:bg-slate-100 p-1 rounded-lg mb-2 text-gullGray cursor-pointer"
                    key={idx}
                    onClick={() => {
                      setDestinationAddressInput(item?.description);
                      setShowDestinationPredictions(false);
                      setAddress(item);
                    }}
                  >
                    {item?.description}
                  </p>
                ))}
            </div>
            <div className="relative">
              <InputField
                placeholder="Email address"
                type="email"
                name="recipientEmail"
                className="!pl-10 !pr-36 !h-[50px]"
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
              />
              <LHIconEmail className="absolute bottom-3 left-2" />
            </div>
            <div className="">
              <TextArea
                autoComplete="true"
                placeholder="Additional Instructions"
                name="recipientDescription"
                value={recipientInstruction}
                onChange={(e) => setRecipientInstruction(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <div className="flex gap-1 items-center mb-2">
                <p className="text-[14px] text-midGray">Instant Delivery</p>
                <Toggle
                  icons={false}
                  checked={instantDelivery}
                  onChange={(e) => setInstantDelivery(e.target.checked)}
                />
              </div>
              {!instantDelivery && (
                <div className="flex max-sm:flex-col gap-2 justify-between">
                  <div className="w-1/2 max-sm:w-full relative">
                    <DatePicker
                      className="h-[50px] pl-12 text-xs bg-catSkillWhite w-full outline-none rounded-lg"
                      selected={deliveryDate}
                      placeholderText="Delivery Date"
                      onChange={(date) => setDeliveryDate(date)}
                      required={instantDelivery ? false : true}
                      name="deliveryDate"
                    />
                    <LHIconCalendar className="absolute left-3 bottom-3" />
                  </div>
                  <div className="w-1/2 max-sm:w-full">
                    <div className="relative">
                      <TimePicker
                        name="deliveryTime"
                        className="bg-catSkillWhite w-full rounded-lg px-2 h-[50px] !border-0"
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        value={deliveryTime}
                        required={instantDelivery ? false : true}
                        onChange={setDeliveryTime}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="my-3">
              <div className="flex gap-1 items-center mb-2">
                <p className="text-midGray text-[14px]">Payment on delivery</p>
                <Toggle
                  icons={false}
                  checked={recipientPays}
                  onChange={(e) => setRecipientPays(e.target.checked)}
                />
              </div>
              {recipientPays && (
                <>
                  <div className="flex gap-2 max-md:flex-wrap justify-between items-center">
                    <div className="w-1/2 max-md:w-full relative">
                      <div className="flex gap-1">
                        <div className="w-1/4">
                          <InputSelect
                            // datas={[{ name: "NGN", value: "NGN" }]}
                            placeholder="NGN"
                            name="currency"
                            className="!h-[50px]"
                            divClass="!min-w-full"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          />
                        </div>
                        <div className="w-3/4">
                          <InputField
                            placeholder="Amount"
                            name="amount"
                            className="!h-[50px]"
                            value={amount}
                            type="number"
                            required={recipientPays ? true : false}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 max-md:w-full relative">
                      <div className="flex max-md:w-full gap-1">
                        <div className="w-1/4">
                          <InputSelect
                            // datas={[{ name: "NGN", value: "NGN" }]}
                            placeholder="NGN"
                            name="currency"
                            className="!h-[50px]"
                            divClass="!min-w-full"
                          />
                        </div>
                        <div className="w-3/4">
                          <InputField
                            placeholder="Delivery fee"
                            name="deliveryFee"
                            className="!h-[50px]"
                            type="number"
                            value={deliveryFee}
                            onChange={(e) => setDeliveryFee(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <p className="text-[12px] text-gullGray">
                    Collect payment on delivery, this fee will be added to
                    additional cost of delivery.
                  </p> */}
                    </div>
                  </div>
                  <p className="text-[12px] text-gullGray">
                    Apply a fixed delivery fee to this order, this fee is not
                    Logistic Hub’s rate and will be added to additional cost of
                    delivery
                  </p>
                </>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="">
                <Multiselect
                  showArrow={true}
                  customArrow={
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                      <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                    </svg>
                  }
                  customCloseIcon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                        fill="#33383F"
                      />
                    </svg>
                  }
                  selectionLimit={1}
                  displayValue="name"
                  onSelect={onFleetTypeSelect}
                  onRemove={onFleetTypeRemove}
                  options={fleetTypeList}
                  value={selectedFleetType}
                  placeholder="Fleet Type"
                  className="h-[50px] mb-2 rounded-lg bg-catSkillWhite text-[12px]"
                />
              </div>
              <div>
                <p className="text-midGray my-3 text-[14px]">Assign Rider*</p>
                <div className="flex gap-5 mb-2">
                  <div className="flex flex-wrap md:flex-row gap-3 mt-1">
                    {[
                      "Available Riders",
                      "LH Dedicated Riders",
                      "Team Riders",
                      "Marketplace",
                    ].map((item, idx) => (
                      <button
                        type="button"
                        key={idx}
                        onClick={() => {
                          if (item === "Available Riders") {
                            dispatch(
                              getAvailableRidersForDelivery({
                                merchantId: selectedClient?.merchantId,
                                locLatitude: latitude,
                                locLongitude: longitude,
                              })
                            ).then((data) => {
                              setRiderOption("Available Riders");
                              setRidersToUse(data?.payload?.data);
                            });
                          } else if (item === "LH Dedicated Riders") {
                            setRidersToUse(selectedClientTeamRiders);
                            setRiderOption("LH Dedicated Riders");
                          } else if (item === "Team Riders") {
                            dispatch(
                              getRidersByTeamId({
                                teamId: outletTeam?.id,
                                page: 1,
                              })
                            ).then((data) => {
                              setRiderOption("Team Riders");
                              setSelectedClientTeamRiders(
                                data?.payload?.data?.riders
                              );
                            });
                          } else {
                            setRiderOption("Marketplace");
                            setSelectedRider({});
                            setRidersToUse([]);
                          }
                        }}
                        className={`text-sm ${
                          riderOption === item
                            ? "text-white bg-congressBlue"
                            : "text-congressBlue bg-white border"
                        } p-2 md:px-4 rounded-lg text-xs outline-none`}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
                <Multiselect
                  showArrow={true}
                  customArrow={
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                      <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                    </svg>
                  }
                  customCloseIcon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5886 4.41009C5.26317 4.08466 4.73553 4.08466 4.41009 4.41009C4.08466 4.73553 4.08466 5.26317 4.41009 5.5886L8.82085 9.99936L4.41012 14.4101C4.08468 14.7355 4.08468 15.2632 4.41012 15.5886C4.73555 15.914 5.26319 15.914 5.58863 15.5886L9.99936 11.1779L14.4101 15.5886C14.7355 15.9141 15.2632 15.9141 15.5886 15.5886C15.914 15.2632 15.914 14.7355 15.5886 14.4101L11.1779 9.99936L15.5886 5.58861C15.9141 5.26317 15.9141 4.73553 15.5886 4.41009C15.2632 4.08466 14.7356 4.08466 14.4101 4.41009L9.99936 8.82085L5.5886 4.41009Z"
                        fill="#33383F"
                      />
                    </svg>
                  }
                  selectionLimit={1}
                  displayValue="name"
                  onSelect={onRiderSelect}
                  options={ridersList}
                  value={selectedRider}
                  placeholder="Assign rider"
                  className="h-[50px] mb-7 rounded-lg bg-catSkillWhite text-[12px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-4">
          <Button type="button" onClick={hide} className="!h-[45px]">
            Cancel
          </Button>
          <Button type="submit" className="!h-[45px] !px-8" blue>
            {false ? <ButtonAnimation /> : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PickupDeliveryContent;
