import Button from "shared/Button";
import Card from "shared/Card";
import PageTitle from "shared/PageTitle";
import PricingRuleDetails from "./PricingRuleDetails";
import LocationsDetails from "./LocationsDetails";
import ClustersDetails from "./ClustersDetails";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PricingHeader = ({
  title,
  onNewPricingClick,
  onNewLocationClick,
  onNewClusterClick,
  isLocationModalShown,
  setIsLocationModalShown,
  isClusterModalShown,
  setIsClusterModalShown,
  isPricingModalShown,
  setIsPricingModalShown,
}) => {
  const getRoute = localStorage.getItem("route");
  const [activeTab, setActiveTab] = useState(0);
  const { pricing, locationsClusters } = useSelector((state) => state.pricing);

  useEffect(() => {
    getRoute === "0"
      ? setActiveTab(0)
      : getRoute === "1"
      ? setActiveTab(1)
      : getRoute === "2"
      ? setActiveTab(2)
      : setActiveTab(0);
  }, [getRoute]);

  const pricingTabs = [
    {
      title: `Pricing Rule (${pricing ? pricing?.length : 0})`,
      component: <PricingRuleDetails pricing={pricing} />,
    },
    {
      title: `Locations (${
        locationsClusters
          ? locationsClusters?.filter((location) => !location?.isCluster)
              ?.length
          : 0
      })`,
      component: (
        <LocationsDetails
          isLocationModalShown={isLocationModalShown}
          setIsLocationModalShown={setIsLocationModalShown}
        />
      ),
    },
    {
      title: `Clusters (${
        locationsClusters
          ? locationsClusters?.filter((location) => location?.isCluster)?.length
          : 0
      })`,
      component: (
        <ClustersDetails
          isClusterModalShown={isClusterModalShown}
          setIsClusterModalShown={setIsClusterModalShown}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      <Card>
        <div className="flex flex-wrap gap-3 mb-4 justify-between">
          <PageTitle title={title} />
          <div className="flex justify-between">
            <div>
              {activeTab === 0 ? (
                <Button
                  onClick={onNewPricingClick}
                  className="!bg-pickledBlueWood !h-[45px] !text-white"
                >
                  New Pricing
                </Button>
              ) : activeTab === 1 ? (
                <Button
                  onClick={onNewLocationClick}
                  className="!bg-pickledBlueWood !h-[45px] !text-white"
                >
                  New Location
                </Button>
              ) : (
                <Button
                  onClick={onNewClusterClick}
                  className="!bg-pickledBlueWood !h-[45px] !text-white"
                >
                  New Cluster
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-3">
          {pricingTabs.map((pricing, idx) => (
            <p
              key={idx}
              className={`${
                activeTab === idx &&
                "border-b-2 border-b-congressBlue text-congressBlue rounded-none"
              } py-2 px-3 rounded-lg cursor-pointer max-[450px]:w-full`}
              onClick={() => setActiveTab(idx)}
            >
              {" "}
              {pricing.title}
            </p>
          ))}
        </div>
      </Card>
      <Card>{pricingTabs[activeTab].component}</Card>
    </div>
  );
};

export default PricingHeader;
