import Modal from "layout/modal/Modal";
import React from "react";
import AddNewRider from "./AddNewRider";

const AddNewRiderModal = ({ isShown, hide, setCurrentRiders }) => {
  return (
    <Modal isShown={isShown} hide={hide} title="New Rider">
      <AddNewRider hide={hide} setCurrentRiders={setCurrentRiders} />
    </Modal>
  );
};

export default AddNewRiderModal;
