import { cancelOrder, getOneOrder } from "appstate/features/order/orderActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ButtonAnimation from "shared/buttonAnimation";

const CancelOrderContent = ({ hide, id, currentOrder, setCurrentOrder }) => {
  const [reason, setReason] = useState("");
  const { isLoading } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!reason) {
      toast.error("Please state your reason for canceling the order!");
      return;
    } else {
      if (currentOrder?.status?.status === "Cancelled") {
        toast.error("Order already cancelled!");
        return;
      }
      dispatch(cancelOrder({ id, reason })).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success("Order cancelled!");
          dispatch(getOneOrder({ id })).then((data) =>
            setCurrentOrder(data?.payload?.data?.data)
          );
          hide();
        }
      });
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="min-h-[120px]">
            <InputField
              placeholder="Select reason"
              label="Why do you want to cancel order?"
              name="reason"
              className="!h-[50px]"
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={hide} className="!h-[45px]">
              Cancel
            </Button>
            <Button type="submit" className="!h-[45px] !px-8" blue>
              {isLoading ? <ButtonAnimation /> : "Cancel Order"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CancelOrderContent;
