import React from "react";
import Chart from "./Chart";
import TeamInfo from "./TeamInfo";
import AssignedMembers from "./AssignedMembers";

const SingleTeamDetails = ({ currentTeam }) => {
  return (
    <div>
      <div className="flex max-[1200px]:flex-wrap gap-4">
        <div className="w-full min-[1200px]:w-[70%] flex flex-col gap-4">
          <Chart currentTeam={currentTeam} />
          <AssignedMembers currentTeam={currentTeam} />
        </div>
        <div className="w-full min-[1200px]:w-[30%]">
          <TeamInfo currentTeam={currentTeam} />
        </div>
      </div>
    </div>
  );
};

export default SingleTeamDetails;
