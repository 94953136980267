import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Header from "shared/Header";
import FleetDetails from "./FleetDetails";
import AddNewFleetModal from "./modal/addNewFleet";
import { useDispatch, useSelector } from "react-redux";
import { getFleetType, getFleets } from "appstate/features/fleet/fleetActions";
import { getClients } from "appstate/features/client/clientActions";

const FleetContents = () => {
  const { fleets } = useSelector((state) => state.fleet);
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFleetType());
    dispatch(getFleets());
    dispatch(getClients());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <AddNewFleetModal isShown={isShown} hide={() => setIsShown(false)} />
      <Header
        info1="All"
        info2="Active"
        info3="Inactive"
        info4="Available"
        amount={`${fleets?.length}`}
        activeAmount={`${fleets?.length}`}
        inactiveAmount={"0"}
        deliveredAmount={"0"}
        title="Fleet"
        btnText="New Fleet"
        onClick={() => setIsShown(true)}
      />
      <FleetDetails fleets={fleets} />
    </DashboardLayout>
  );
};

export default FleetContents;
